.archivosPadres{
    padding: 20px;
    background: #f1f1f1;
    border-radius: 5px;
    margin-bottom: 20px;
    cursor: pointer;
    height: 100px;
}
.archivosPadres .padresTitle{
    vertical-align: middle;
    font-size: 14px;
    color: black;
}