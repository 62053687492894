@import url(https://fonts.googleapis.com/css?family=Kanit:800&display=swap);
.iframePredelta{
    width: 100%;
    height: 101vh;
}
.archivosPadres{
    padding: 20px;
    background: #f1f1f1;
    border-radius: 5px;
    margin-bottom: 20px;
    cursor: pointer;
    height: 100px;
}
.archivosPadres .padresTitle{
    vertical-align: middle;
    font-size: 14px;
    color: black;
}
@-webkit-keyframes closeWindow {
0% {
opacity: 1;
}
100% {
opacity: 0;
}
}

@keyframes closeWindow {
0% {
opacity: 1;
}
100% {
opacity: 0;
}
}

.ril__outer {
background-color: rgba(0, 0, 0, 0.85);
outline: none;
top: 0;
left: 0;
right: 0;
bottom: 0;
z-index: 1000;
width: 100%;
height: 100%;
-ms-content-zooming: none;
-ms-user-select: none;
-ms-touch-select: none;
touch-action: none;
}

.ril__outerClosing {
opacity: 0;
}

.ril__inner {
position: absolute;
top: 0;
left: 0;
right: 0;
bottom: 0;
}

.ril__image,
.ril__imagePrev,
.ril__imageNext {
position: absolute;
top: 0;
right: 0;
bottom: 0;
left: 0;
margin: auto;
max-width: none;
-ms-content-zooming: none;
-ms-user-select: none;
-ms-touch-select: none;
touch-action: none;
}

.ril__imageDiscourager {
background-repeat: no-repeat;
background-position: center;
background-size: contain;
}

.ril__navButtons {
border: none;
position: absolute;
top: 0;
bottom: 0;
width: 20px;
height: 34px;
padding: 40px 30px;
margin: auto;
cursor: pointer;
opacity: 0.7;
}
.ril__navButtons:hover {
opacity: 1;
}
.ril__navButtons:active {
opacity: 0.7;
}

.ril__navButtonPrev {
left: 0;
background: rgba(0, 0, 0, 0.2)
url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZlcnNpb249IjEuMSIgd2lkdGg9IjIwIiBoZWlnaHQ9IjM0Ij48cGF0aCBkPSJtIDE5LDMgLTIsLTIgLTE2LDE2IDE2LDE2IDEsLTEgLTE1LC0xNSAxNSwtMTUgeiIgZmlsbD0iI0ZGRiIvPjwvc3ZnPg==')
no-repeat center;
}

.ril__navButtonNext {
right: 0;
background: rgba(0, 0, 0, 0.2)
url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZlcnNpb249IjEuMSIgd2lkdGg9IjIwIiBoZWlnaHQ9IjM0Ij48cGF0aCBkPSJtIDEsMyAyLC0yIDE2LDE2IC0xNiwxNiAtMSwtMSAxNSwtMTUgLTE1LC0xNSB6IiBmaWxsPSIjRkZGIi8+PC9zdmc+')
no-repeat center;
}

.ril__downloadBlocker {
position: absolute;
top: 0;
left: 0;
right: 0;
bottom: 0;
background-image: url('data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7');
background-size: cover;
}

.ril__caption,
.ril__toolbar {
background-color: rgba(0, 0, 0, 0.5);
position: absolute;
left: 0;
right: 0;
display: flex;
justify-content: space-between;
}

.ril__caption {
bottom: 0;
max-height: 150px;
overflow: auto;
}

.ril__captionContent {
padding: 10px 20px;
color: #fff;
}

.ril__toolbar {
top: 0;
height: 50px;
}

.ril__toolbarSide {
height: 50px;
margin: 0;
}

.ril__toolbarLeftSide {
padding-left: 20px;
padding-right: 0;
flex: 0 1 auto;
overflow: hidden;
text-overflow: ellipsis;
}

.ril__toolbarRightSide {
padding-left: 0;
padding-right: 20px;
flex: 0 0 auto;
}

.ril__toolbarItem {
display: inline-block;
line-height: 50px;
padding: 0;
color: #fff;
font-size: 120%;
max-width: 100%;
overflow: hidden;
text-overflow: ellipsis;
white-space: nowrap;
}

.ril__toolbarItemChild {
vertical-align: middle;
}

.ril__builtinButton {
width: 40px;
height: 35px;
cursor: pointer;
border: none;
opacity: 0.7;
}
.ril__builtinButton:hover {
opacity: 1;
}
.ril__builtinButton:active {
outline: none;
}

.ril__builtinButtonDisabled {
cursor: default;
opacity: 0.5;
}
.ril__builtinButtonDisabled:hover {
opacity: 0.5;
}

.ril__closeButton {
background:
url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZlcnNpb249IjEuMSIgd2lkdGg9IjIwIiBoZWlnaHQ9IjIwIj48cGF0aCBkPSJtIDEsMyAxLjI1LC0xLjI1IDcuNSw3LjUgNy41LC03LjUgMS4yNSwxLjI1IC03LjUsNy41IDcuNSw3LjUgLTEuMjUsMS4yNSAtNy41LC03LjUgLTcuNSw3LjUgLTEuMjUsLTEuMjUgNy41LC03LjUgLTcuNSwtNy41IHoiIGZpbGw9IiNGRkYiLz48L3N2Zz4=')
no-repeat center;
}

.ril__zoomInButton {
background:
url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyMCIgaGVpZ2h0PSIyMCI+PGcgc3Ryb2tlPSIjZmZmIiBzdHJva2Utd2lkdGg9IjIiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCI+PHBhdGggZD0iTTEgMTlsNi02Ii8+PHBhdGggZD0iTTkgOGg2Ii8+PHBhdGggZD0iTTEyIDV2NiIvPjwvZz48Y2lyY2xlIGN4PSIxMiIgY3k9IjgiIHI9IjciIGZpbGw9Im5vbmUiIHN0cm9rZT0iI2ZmZiIgc3Ryb2tlLXdpZHRoPSIyIi8+PC9zdmc+')
no-repeat center;
}

.ril__zoomOutButton {
background:
url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyMCIgaGVpZ2h0PSIyMCI+PGcgc3Ryb2tlPSIjZmZmIiBzdHJva2Utd2lkdGg9IjIiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCI+PHBhdGggZD0iTTEgMTlsNi02Ii8+PHBhdGggZD0iTTkgOGg2Ii8+PC9nPjxjaXJjbGUgY3g9IjEyIiBjeT0iOCIgcj0iNyIgZmlsbD0ibm9uZSIgc3Ryb2tlPSIjZmZmIiBzdHJva2Utd2lkdGg9IjIiLz48L3N2Zz4=')
no-repeat center;
}

.ril__outerAnimating {
-webkit-animation-name: closeWindow;
animation-name: closeWindow;
}

@-webkit-keyframes pointFade {
0%,
19.999%,
100% {
opacity: 0;
}
20% {
opacity: 1;
}
}

@keyframes pointFade {
0%,
19.999%,
100% {
opacity: 0;
}
20% {
opacity: 1;
}
}

.ril__loadingCircle {
width: 60px;
height: 60px;
position: relative;
}

.ril__loadingCirclePoint {
width: 100%;
height: 100%;
position: absolute;
left: 0;
top: 0;
}
.ril__loadingCirclePoint::before {
content: '';
display: block;
margin: 0 auto;
width: 11%;
height: 30%;
background-color: #fff;
border-radius: 30%;
-webkit-animation: pointFade 800ms infinite ease-in-out both;
animation: pointFade 800ms infinite ease-in-out both;
}
.ril__loadingCirclePoint:nth-of-type(1) {
-webkit-transform: rotate(0deg);
transform: rotate(0deg);
}
.ril__loadingCirclePoint:nth-of-type(7) {
-webkit-transform: rotate(180deg);
transform: rotate(180deg);
}
.ril__loadingCirclePoint:nth-of-type(1)::before,
.ril__loadingCirclePoint:nth-of-type(7)::before {
-webkit-animation-delay: -800ms;
animation-delay: -800ms;
}
.ril__loadingCirclePoint:nth-of-type(2) {
-webkit-transform: rotate(30deg);
transform: rotate(30deg);
}
.ril__loadingCirclePoint:nth-of-type(8) {
-webkit-transform: rotate(210deg);
transform: rotate(210deg);
}
.ril__loadingCirclePoint:nth-of-type(2)::before,
.ril__loadingCirclePoint:nth-of-type(8)::before {
-webkit-animation-delay: -666ms;
animation-delay: -666ms;
}
.ril__loadingCirclePoint:nth-of-type(3) {
-webkit-transform: rotate(60deg);
transform: rotate(60deg);
}
.ril__loadingCirclePoint:nth-of-type(9) {
-webkit-transform: rotate(240deg);
transform: rotate(240deg);
}
.ril__loadingCirclePoint:nth-of-type(3)::before,
.ril__loadingCirclePoint:nth-of-type(9)::before {
-webkit-animation-delay: -533ms;
animation-delay: -533ms;
}
.ril__loadingCirclePoint:nth-of-type(4) {
-webkit-transform: rotate(90deg);
transform: rotate(90deg);
}
.ril__loadingCirclePoint:nth-of-type(10) {
-webkit-transform: rotate(270deg);
transform: rotate(270deg);
}
.ril__loadingCirclePoint:nth-of-type(4)::before,
.ril__loadingCirclePoint:nth-of-type(10)::before {
-webkit-animation-delay: -400ms;
animation-delay: -400ms;
}
.ril__loadingCirclePoint:nth-of-type(5) {
-webkit-transform: rotate(120deg);
transform: rotate(120deg);
}
.ril__loadingCirclePoint:nth-of-type(11) {
-webkit-transform: rotate(300deg);
transform: rotate(300deg);
}
.ril__loadingCirclePoint:nth-of-type(5)::before,
.ril__loadingCirclePoint:nth-of-type(11)::before {
-webkit-animation-delay: -266ms;
animation-delay: -266ms;
}
.ril__loadingCirclePoint:nth-of-type(6) {
-webkit-transform: rotate(150deg);
transform: rotate(150deg);
}
.ril__loadingCirclePoint:nth-of-type(12) {
-webkit-transform: rotate(330deg);
transform: rotate(330deg);
}
.ril__loadingCirclePoint:nth-of-type(6)::before,
.ril__loadingCirclePoint:nth-of-type(12)::before {
-webkit-animation-delay: -133ms;
animation-delay: -133ms;
}
.ril__loadingCirclePoint:nth-of-type(7) {
-webkit-transform: rotate(180deg);
transform: rotate(180deg);
}
.ril__loadingCirclePoint:nth-of-type(13) {
-webkit-transform: rotate(360deg);
transform: rotate(360deg);
}
.ril__loadingCirclePoint:nth-of-type(7)::before,
.ril__loadingCirclePoint:nth-of-type(13)::before {
-webkit-animation-delay: 0ms;
animation-delay: 0ms;
}

.ril__loadingContainer {
position: absolute;
top: 0;
right: 0;
bottom: 0;
left: 0;
}
.ril__imagePrev .ril__loadingContainer,
.ril__imageNext .ril__loadingContainer {
display: none;
}

.ril__errorContainer {
position: absolute;
top: 0;
right: 0;
bottom: 0;
left: 0;
display: flex;
align-items: center;
justify-content: center;
color: #fff;
}
.ril__imagePrev .ril__errorContainer,
.ril__imageNext .ril__errorContainer {
display: none;
}

.ril__loadingContainer__icon {
  color: #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
}

@-webkit-keyframes closeWindow {
0% {
opacity: 1;
}
100% {
opacity: 0;
}
}

@keyframes closeWindow {
0% {
opacity: 1;
}
100% {
opacity: 0;
}
}

.ril__outer {
background-color: rgba(0, 0, 0, 0.85);
outline: none;
top: 0;
left: 0;
right: 0;
bottom: 0;
z-index: 1000;
width: 100%;
height: 100%;
-ms-content-zooming: none;
-ms-user-select: none;
-ms-touch-select: none;
touch-action: none;
}

.ril__outerClosing {
opacity: 0;
}

.ril__inner {
position: absolute;
top: 0;
left: 0;
right: 0;
bottom: 0;
}

.ril__image,
.ril__imagePrev,
.ril__imageNext {
position: absolute;
top: 0;
right: 0;
bottom: 0;
left: 0;
margin: auto;
max-width: none;
-ms-content-zooming: none;
-ms-user-select: none;
-ms-touch-select: none;
touch-action: none;
}

.ril__imageDiscourager {
background-repeat: no-repeat;
background-position: center;
background-size: contain;
}

.ril__navButtons {
border: none;
position: absolute;
top: 0;
bottom: 0;
width: 20px;
height: 34px;
padding: 40px 30px;
margin: auto;
cursor: pointer;
opacity: 0.7;
}
.ril__navButtons:hover {
opacity: 1;
}
.ril__navButtons:active {
opacity: 0.7;
}

.ril__navButtonPrev {
left: 0;
background: rgba(0, 0, 0, 0.2)
url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZlcnNpb249IjEuMSIgd2lkdGg9IjIwIiBoZWlnaHQ9IjM0Ij48cGF0aCBkPSJtIDE5LDMgLTIsLTIgLTE2LDE2IDE2LDE2IDEsLTEgLTE1LC0xNSAxNSwtMTUgeiIgZmlsbD0iI0ZGRiIvPjwvc3ZnPg==')
no-repeat center;
}

.ril__navButtonNext {
right: 0;
background: rgba(0, 0, 0, 0.2)
url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZlcnNpb249IjEuMSIgd2lkdGg9IjIwIiBoZWlnaHQ9IjM0Ij48cGF0aCBkPSJtIDEsMyAyLC0yIDE2LDE2IC0xNiwxNiAtMSwtMSAxNSwtMTUgLTE1LC0xNSB6IiBmaWxsPSIjRkZGIi8+PC9zdmc+')
no-repeat center;
}

.ril__downloadBlocker {
position: absolute;
top: 0;
left: 0;
right: 0;
bottom: 0;
background-image: url('data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7');
background-size: cover;
}

.ril__caption,
.ril__toolbar {
background-color: rgba(0, 0, 0, 0.5);
position: absolute;
left: 0;
right: 0;
display: flex;
justify-content: space-between;
}

.ril__caption {
bottom: 0;
max-height: 150px;
overflow: auto;
}

.ril__captionContent {
padding: 10px 20px;
color: #fff;
}

.ril__toolbar {
top: 0;
height: 50px;
}

.ril__toolbarSide {
height: 50px;
margin: 0;
}

.ril__toolbarLeftSide {
padding-left: 20px;
padding-right: 0;
flex: 0 1 auto;
overflow: hidden;
text-overflow: ellipsis;
}

.ril__toolbarRightSide {
padding-left: 0;
padding-right: 20px;
flex: 0 0 auto;
}

.ril__toolbarItem {
display: inline-block;
line-height: 50px;
padding: 0;
color: #fff;
font-size: 120%;
max-width: 100%;
overflow: hidden;
text-overflow: ellipsis;
white-space: nowrap;
}

.ril__toolbarItemChild {
vertical-align: middle;
}

.ril__builtinButton {
width: 40px;
height: 35px;
cursor: pointer;
border: none;
opacity: 0.7;
}
.ril__builtinButton:hover {
opacity: 1;
}
.ril__builtinButton:active {
outline: none;
}

.ril__builtinButtonDisabled {
cursor: default;
opacity: 0.5;
}
.ril__builtinButtonDisabled:hover {
opacity: 0.5;
}

.ril__closeButton {
background:
url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZlcnNpb249IjEuMSIgd2lkdGg9IjIwIiBoZWlnaHQ9IjIwIj48cGF0aCBkPSJtIDEsMyAxLjI1LC0xLjI1IDcuNSw3LjUgNy41LC03LjUgMS4yNSwxLjI1IC03LjUsNy41IDcuNSw3LjUgLTEuMjUsMS4yNSAtNy41LC03LjUgLTcuNSw3LjUgLTEuMjUsLTEuMjUgNy41LC03LjUgLTcuNSwtNy41IHoiIGZpbGw9IiNGRkYiLz48L3N2Zz4=')
no-repeat center;
}

.ril__zoomInButton {
background:
url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyMCIgaGVpZ2h0PSIyMCI+PGcgc3Ryb2tlPSIjZmZmIiBzdHJva2Utd2lkdGg9IjIiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCI+PHBhdGggZD0iTTEgMTlsNi02Ii8+PHBhdGggZD0iTTkgOGg2Ii8+PHBhdGggZD0iTTEyIDV2NiIvPjwvZz48Y2lyY2xlIGN4PSIxMiIgY3k9IjgiIHI9IjciIGZpbGw9Im5vbmUiIHN0cm9rZT0iI2ZmZiIgc3Ryb2tlLXdpZHRoPSIyIi8+PC9zdmc+')
no-repeat center;
}

.ril__zoomOutButton {
background:
url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyMCIgaGVpZ2h0PSIyMCI+PGcgc3Ryb2tlPSIjZmZmIiBzdHJva2Utd2lkdGg9IjIiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCI+PHBhdGggZD0iTTEgMTlsNi02Ii8+PHBhdGggZD0iTTkgOGg2Ii8+PC9nPjxjaXJjbGUgY3g9IjEyIiBjeT0iOCIgcj0iNyIgZmlsbD0ibm9uZSIgc3Ryb2tlPSIjZmZmIiBzdHJva2Utd2lkdGg9IjIiLz48L3N2Zz4=')
no-repeat center;
}

.ril__outerAnimating {
-webkit-animation-name: closeWindow;
animation-name: closeWindow;
}

@-webkit-keyframes pointFade {
0%,
19.999%,
100% {
opacity: 0;
}
20% {
opacity: 1;
}
}

@keyframes pointFade {
0%,
19.999%,
100% {
opacity: 0;
}
20% {
opacity: 1;
}
}

.ril__loadingCircle {
width: 60px;
height: 60px;
position: relative;
}

.ril__loadingCirclePoint {
width: 100%;
height: 100%;
position: absolute;
left: 0;
top: 0;
}
.ril__loadingCirclePoint::before {
content: '';
display: block;
margin: 0 auto;
width: 11%;
height: 30%;
background-color: #fff;
border-radius: 30%;
-webkit-animation: pointFade 800ms infinite ease-in-out both;
animation: pointFade 800ms infinite ease-in-out both;
}
.ril__loadingCirclePoint:nth-of-type(1) {
-webkit-transform: rotate(0deg);
transform: rotate(0deg);
}
.ril__loadingCirclePoint:nth-of-type(7) {
-webkit-transform: rotate(180deg);
transform: rotate(180deg);
}
.ril__loadingCirclePoint:nth-of-type(1)::before,
.ril__loadingCirclePoint:nth-of-type(7)::before {
-webkit-animation-delay: -800ms;
animation-delay: -800ms;
}
.ril__loadingCirclePoint:nth-of-type(2) {
-webkit-transform: rotate(30deg);
transform: rotate(30deg);
}
.ril__loadingCirclePoint:nth-of-type(8) {
-webkit-transform: rotate(210deg);
transform: rotate(210deg);
}
.ril__loadingCirclePoint:nth-of-type(2)::before,
.ril__loadingCirclePoint:nth-of-type(8)::before {
-webkit-animation-delay: -666ms;
animation-delay: -666ms;
}
.ril__loadingCirclePoint:nth-of-type(3) {
-webkit-transform: rotate(60deg);
transform: rotate(60deg);
}
.ril__loadingCirclePoint:nth-of-type(9) {
-webkit-transform: rotate(240deg);
transform: rotate(240deg);
}
.ril__loadingCirclePoint:nth-of-type(3)::before,
.ril__loadingCirclePoint:nth-of-type(9)::before {
-webkit-animation-delay: -533ms;
animation-delay: -533ms;
}
.ril__loadingCirclePoint:nth-of-type(4) {
-webkit-transform: rotate(90deg);
transform: rotate(90deg);
}
.ril__loadingCirclePoint:nth-of-type(10) {
-webkit-transform: rotate(270deg);
transform: rotate(270deg);
}
.ril__loadingCirclePoint:nth-of-type(4)::before,
.ril__loadingCirclePoint:nth-of-type(10)::before {
-webkit-animation-delay: -400ms;
animation-delay: -400ms;
}
.ril__loadingCirclePoint:nth-of-type(5) {
-webkit-transform: rotate(120deg);
transform: rotate(120deg);
}
.ril__loadingCirclePoint:nth-of-type(11) {
-webkit-transform: rotate(300deg);
transform: rotate(300deg);
}
.ril__loadingCirclePoint:nth-of-type(5)::before,
.ril__loadingCirclePoint:nth-of-type(11)::before {
-webkit-animation-delay: -266ms;
animation-delay: -266ms;
}
.ril__loadingCirclePoint:nth-of-type(6) {
-webkit-transform: rotate(150deg);
transform: rotate(150deg);
}
.ril__loadingCirclePoint:nth-of-type(12) {
-webkit-transform: rotate(330deg);
transform: rotate(330deg);
}
.ril__loadingCirclePoint:nth-of-type(6)::before,
.ril__loadingCirclePoint:nth-of-type(12)::before {
-webkit-animation-delay: -133ms;
animation-delay: -133ms;
}
.ril__loadingCirclePoint:nth-of-type(7) {
-webkit-transform: rotate(180deg);
transform: rotate(180deg);
}
.ril__loadingCirclePoint:nth-of-type(13) {
-webkit-transform: rotate(360deg);
transform: rotate(360deg);
}
.ril__loadingCirclePoint:nth-of-type(7)::before,
.ril__loadingCirclePoint:nth-of-type(13)::before {
-webkit-animation-delay: 0ms;
animation-delay: 0ms;
}

.ril__loadingContainer {
position: absolute;
top: 0;
right: 0;
bottom: 0;
left: 0;
}
.ril__imagePrev .ril__loadingContainer,
.ril__imageNext .ril__loadingContainer {
display: none;
}

.ril__errorContainer {
position: absolute;
top: 0;
right: 0;
bottom: 0;
left: 0;
display: flex;
align-items: center;
justify-content: center;
color: #fff;
}
.ril__imagePrev .ril__errorContainer,
.ril__imageNext .ril__errorContainer {
display: none;
}

.ril__loadingContainer__icon {
  color: #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
}

/* Position and sizing of burger button */
.bm-burger-button {
  width: 36px;
  height: 30px;
  left: 100px;
  top: 65px;
  position: absolute;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
background: #670713;
height: 13% !important;
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
background: #a90000;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
height: 24px;
width: 24px;
}

/* Color/shape of close button cross */
.bm-cross {
background: #bdc3c7;
}

/*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
.bm-menu-wrap {
position: fixed;
height: 100%;
}

/* General sidebar styles */
.bm-menu {
background: #670713;
padding: 2.5em 1.5em 0;
font-size: 1.15em;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
color: #b8b7ad;
padding: 0.8em;
}

/* Individual item */
.bm-item {
display: inline-block;
}

/* Styling of overlay */
.bm-overlay {
background: rgba(0, 0, 0, 0.3);
}
.predeltaLink a{
font-family: 'Kanit', sans-serif;
  font-size: 18px;
  color: #cb9c2b;
  font-weight: 600;
  padding: 12px;
}

@font-face {
    font-family: heavitas;
    src: url(/delta/static/media/Heavitas.fcf5d376.eot);
    src: url(/delta/static/media/Heavitas.fcf5d376.eot?#iefix) format('embedded-opentype'),
        url(/delta/static/media/Heavitas.4853b2e1.woff) format('woff'),
        url(/delta/static/media/Heavitas.efe36781.ttf) format('truetype');
}

@font-face {
    font-family: antipasto;
    src: url(/delta/static/media/AntipastoPro.5e9f849e.eot);
    src: url(/delta/static/media/AntipastoPro.5e9f849e.eot?#iefix) format('embedded-opentype'),
        url(/delta/static/media/AntipastoPro.59236940.woff) format('woff'),
        url(/delta/static/media/AntipastoPro.67c64498.ttf) format('truetype');
}

@font-face {
    font-family: comforta;
    src: url(/delta/static/media/Comfortaa-Regular.268f65c2.eot);
    src: url(/delta/static/media/Comfortaa-Regular.268f65c2.eot?#iefix) format('embedded-opentype'),
        url(/delta/static/media/Comfortaa-Regular.29a8cf45.woff) format('woff'),
        url(/delta/static/media/Comfortaa-Regular.571d8342.ttf) format('truetype');
}

/*------------------------------------------*/

body {
    overflow-x: hidden;
    font-size: 18px;
}

/*
.content {
    
    color: black !important;
}*/

.boldTit {
    color: #8E171B;
    font-size: 30px;
    padding-top: 8px;
    font-family: heavitas;
}

.instaBoxCol {}

.thinTit {
    font-family: antipasto;
    color: #C2C3C9;
    font-size: 26px;
    line-height: 26px;
    text-transform: uppercase;
}

.thinTit.slideLight {
    font-family: comforta;
    font-size: 18px;
}


.ant-row-flex.ant-row-flex-center.rowMenu {
    align-items: center;
    padding-top: 20px;
    padding-bottom: 20px;
    background: white;
}

img.brand-logo {
    max-width: 85px;
}

a.menuBtn {
    left: 100px;
    top: 5%;
    bottom: 0;
    font-size: 35px;
    max-height: 60px;
}

span.miniTit {
    font-size: 14px;
    padding-left: 15px;
    color: #8E171B;
    font-weight: bold;
    position: absolute;
    z-index: 30;
    top: 68px;
    left: 135px;

}

.bigTitHeader.acad.single {
    padding-top: 20px;
}

span.miniTit2 {
    position: absolute;
    right: 110px;
    top: 7%;
    font-size: 10px;
    padding-left: 15px;
    color: #9c0b09;
    font-weight: 500;
    align-items: center;
    display: flex;
}

.menuElements {
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    display: flex;
}

.menuElements {
    color: #690a08;
}

.ant-modal-content {
    border-radius: unset !important;
}

.ant-modal {
    top: 0 !important;
    margin: 1px auto !important;
}

.ant-modal-footer {
    display: none;
}

.fotoBolBox {
    max-width: 180px;
    margin: auto;
}

img.imgBol {
    width: 100%;
}

.ant-menu-inline,
.ant-menu-vertical,
.ant-menu-vertical-left {
    border-right: none;
}

i.ant-menu-submenu-arrow {
    display: none;
}

.ant-modal-body {
    padding: unset !important;
    height: calc(100vh);
}


.bigTitHeader.acad.shortTit.pBottom {
    padding-bottom: 30px;
}

span.ant-modal-close-x {
    padding-top: 30px;
    padding-right: 100px;
    color: #9c0b09;
    font-size: 30px;
}

a.newsList {
    color: black;
}

.ant-modal {
    margin-top: unset;
}

.textBox.light.center.cop.shortHeader.torneo {
    background: #8E171B;
}

.ant-row-flex.rowMenuModal {
    padding: 30px 100px;
}

.torneo .bigTitHeader {
    line-height: 80px;
}

.textBox.light.center.cop.shortHeader.torneolight {
    height: 250px;
}

.textBox.light.center.cop.shortHeader.torneo {
    height: 250px;
}

.ant-modal {
    padding-bottom: unset;
    height: calc(100vh);
}

.ant-row-flex.galleryGridRow.pb.torneo {
    padding-top: 60px;
}

ul.ant-menu.ant-menu-light.ant-menu-root.ant-menu-vertical {
    background: #ececec;
}

.ant-row-flex.menuBody {
    background: #ececec;
}

.ant-row-flex.rowFotosBol {
    padding-top: 20px;
}

.nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
    background-color: unset;
    color: #9c0b09;
    font-size: 30px;
    font-family: heavitas;
}

.parrBox.counse {
    padding: 40px 100px;
}

.medicServiceCard.coun {
    height: 100%;
    display: flex;
}

.parrBox.counse.right {
    padding-left: 30px;
}

.parrBox.counse.left {
    padding-right: 30px;
}

.dirBox {
    max-width: 150px;
    float: right;
    padding-right: 22px;
}

.ant-row-flex.rowSubjects {
    justify-content: center;
}

.ant-col.eqCol.ant-col-xs-24.ant-col-md-12 {
    padding-left: 15px;
    text-align: left;
}

.ant-row-flex.topRowDir {
    display: flex;
    align-items: center;
    padding-top: 40px;
}

.lightTextGray.count {
    font-size: 20px;
    max-width: 250px;
    text-align: center;
    margin: auto;
}

.ant-row-flex.counRow {
    padding-left: 100px;
    padding-right: 100px;
}

.nav.flex-column.submenu {
    padding-left: 80px;
}

a.nav-link {
    color: gray;
    font-size: 15px;
}

.ant-row-flex.menuBody {
    padding: 30px 100px;
    position: relative;
}

a.nav-link {
    font-size: 16px;
    padding: 12px;
    color: gray;
}

i.anticon.anticon-close {
    font-size: 28px;
    padding-left: 15px;
}

button.ant-modal-close {
    display: none;
}

a.submenuItem {
    font-size: 15px;
    padding: 7px;
    color: gray;
}

a.submenuItem:hover {
    color: #676767;
    font-weight: 600;
}

.bottomMenu {
    bottom: 30px;
    position: absolute;
}

.centerMenu {
    top: 0;
    bottom: 0;
    position: absolute;
    justify-content: center;
}


.nav-link.main.active.show:after {
    content: '>';
    margin-top: -1px;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    padding-left: 60px;
}

.slideHome {
    background: url("https://uedelta.k12.ec/delta2019/wp-content/uploads/2016/04/IMG_2228-copy.jpg");
    background-position: top;
    background-size: cover;
    min-height: calc(100vh - 200px);
    color: white;
    display: flex;
    vertical-align: middle;
    align-items: center;
}

.smallBarAcad {
    height: 5px;
    background: #C2C3C9;
    width: 30px;
}

.smallBar {
    height: 5px;
    background: #C2C3C9;
    width: 30px;
}

a.blackEmail {
    color: #6f6f6f;
}

a.blackEmail:hover {
    color: #90690c;
    text-decoration: underline;
}

.bigTitHeader.acad {
    font-size: 45px;
    padding-bottom: 20px;
}

.phrase2Subtit {
    padding-top: 100%;
}


.slideContent {
    padding-left: 100px;
}

.shortPhrase {
    padding-top: 12px;
}

.titPopBox.link a:hover {
    color: white;
}

.titPopBox.link:hover {
    box-shadow: unset;
}

a.greyText {
    color: gray;
}

p.shortPar.colorWhite {
    color: white;
}

.modalVidIframe {
    width: 90% !important;
    max-width: 1039px;
}

.modalVidIframe .ant-modal-body {
    height: auto;
}

.modalVidIframe .ant-modal-content {
    padding-bottom: 0;
}

.vidCenter {
    text-align: center;
}

iframe.videoYoutube {
    justify-content: center;
    width: 980px;
    height: 600px;
    margin: auto;
}

.lightTextGray.med {
    color: #878787;
}

.banner-caption {
    position: absolute;
    bottom: 0;
    background: #000000ad;
    color: white;
    padding: 3px 30px;
    left: 0;
    right: 0;
    text-align: center;
    margin: auto;
}

.headerBackground {
    height: 400px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.ant-row-flex.rowHeader {
    background: #C2C3C9 !important;
    align-items: center;

}




.ant-col.bgColorHeader.ant-col-xs-24.ant-col-md-12 {
    padding: 0 5%;
}

p.shortPar.espaceTop.forma {
    color: white;
}

.ant-col.bgColorHeader.ant-col-xs-24.ant-col-md-12 {
    background: #C2C3C9 !important;
    height: 100%;
}

.titPopBox.link {
    background: #c79213;
    padding: 12px;
    width: 100px;
    text-align: center;
    margin-top: 20px;
    box-shadow: 3px 5px 2px 0px #00000021;
}

.phrase2 {
    font-size: 25px;
    line-height: 25px;
    padding-top: 5px;
    padding-bottom: 12px;
    font-family: heavitas;
}

.btnSlider {
    background: #C2C3C9;
    max-width: 80px;
    text-align: center;
    padding: 5px;
}

.headerCapellania.top.header2 {
    background-position: center;

}

.headerFormativo.header2 {
    background-position: center;
}

.headerFormativo.header2.acad {
    height: calc(100vh - 350px);
}

.ant-col.dias.ant-col-xs-16.ant-col-md-12 {
    padding-right: 20px;
}

.textBox.light.center.cop.acad {
    height: calc(100vh - 350px);
    padding: 30px 100px;
}

.btnLinkSlider {
    color: white;
    font-weight: 600;

}

.btnLinkSlider:hover {
    color: black;
}

.iconContainer {
    padding: 30px 100px 41px;
    display: flex;
    text-align: center;
}


.iconTit {
    padding-top: 15px;
    color: #a0a0a0;
}

.headerText.eequipo {
    padding: 40px 100px;
}


.bgColor {
    background: #F9FBFB;
    height: 50%;
    width: 100%;
    position: absolute;
}

.circle {
    width: 5px;
    height: 5px;
    background: white;
    border-radius: 50px;
}

.headerCapellania.header2.shortHeader.sacer {
    height: 365px;
}

.textBox.light.center.cop.shortHeader.sacer {
    height: 365px;

}

button.ant-btn.saveForm.ant-btn-primary {
    width: 150px;
    background: #C2C3C9;
    border: unset;
}

.ant-form-item-required::before {
    display: none;
}

img.circleImg {
    max-width: 130px;
    display: flex;
    margin: auto;
}

.ant-row-flex.copeceRow.center.people.dece {
    align-items: flex-start;
}

.circleBtnCol {
    background: #8E171B;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    z-index: 5;
    height: 100%;
    width: 100px;
}

.ant-col.horas.ant-col-xs-8.ant-col-md-6 {
    padding-left: 20px;
}

.readMoreBtn.news {
    padding-bottom: 60px;
}

img.coverImg {
    width: 100%;
}


.coverBox {
    padding-top: 50px;
    padding-bottom: 50px;
}



.space {
    height: 5px;
    margin-bottom: 25px;
}

a.nav-link.circle.active.show {
    background: #8E171B;
}

.contentTemplate {
    position: relative;
    z-index: 2;
    padding-left: 90px;
    padding-right: 100px;
}



.square {
    background: #C2C3C9;
    z-index: 0;
    padding-left: 10px;
}

.readMoreBtn {
    display: flex;
    align-items: center;
}

.whiteBtn {
    position: relative;
    z-index: 2;
    color: white;
    padding: 10px 10px;
    box-shadow: 1px 2px 1px 0px #d4d4d4;
}

.whiteBtn:hover {
    box-shadow: none;
}

.readMoreBtn {
    justify-content: flex-end;
    padding-right: unset;
}

.textBox.light.center.cop.shortHeader.equipo {
    padding: 35px 100px;
}

.textBox.light.center.cop.shortHeader.ib {
    color: white;
    padding: 20px 40px;
}



.readMoreBox {
    padding-top: 20px;
}

span.lightBtn {
    padding-left: 3px;
    color: black;
}

a.nav-link.circle.space.active {
    background: #A0A1A5;
}

a.readMoreBtn:focus {
    text-decoration: none;
}

.backBox {
    max-width: 500px;
}

img.imageGalleryBack {
    width: 700px;
    height: 400px;
    object-fit: cover;
    object-position: top;
}

img.imageGalleryFront {
    width: 700px;
    height: 400px;
    object-fit: cover;
    object-position: top;
    padding-bottom: 70px;
}

.frontBox {
    max-width: 600px;
    margin-top: -80px;
    padding-left: 6%;
}


.carousel-control-next-icon {
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGUAAACFCAYAAABCODZNAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAABSJJREFUeNrsnVFu00AQhncrv7c3IAdAah54rzlBcwNccQDCCQgnoD0ASjhB0xPUfUdqkHhvuQE5QdmFKYQ0qe147MzO/CtZkUrqlP0y8+/uvzv2Dw8PDk1WO0AXAApaX1C+fn55FK4RulNWpMzDdRnAnKNL2zffVugJxLuVH92Ea/Tq7fef6N49REoAUqwBie0kXGX4tyG6t+dIoU4vw3W45S3LcBUhYubo5h4iJQo76cjhM287JJ2ZoJt7iJTQ0YvwctzgV64oaqAzXURKADJrCCS2U9KZAbqcGQoJ+5sdPyuCXIR75Oh2pvRF3/I7ps99H1IZ5jQcmrJhTtKmfQnXGDrDIPSUws4rRl5127dw5QDDM/qqmqM0aUsCswCOFvMU6sABfdPbtgj2liIQzfGsfc1ajMie6EwAXgAKg/NI3/Ip099kXmc8lx3MrDM/3J+VZpM6w+Y8UgcOmXTmBa0AFIDSHsx9TD00B+EYAEwtGme+q90soTPH4eUT0+1MGWe+yy1GtM5VtcTfZABQWNCZTnezhA4sKZ1x6Mwx6cwIUHgGABHMFZPOqDfOfJ87JKkzPzDdTq1x5vvetkrpZ8aoMyMa9QEKw0RzTvORtm1JYEpoCs9E84ZJZ65pCI5IYYoaGGfSoBCYwsE4kwVlRWdKB+NMzlEIGGcCI2UtauKQ2axx5qUer7NsnHnJZx6tGmeij9dZNc7En3m0aJz5lI5sWzHOfGrn6C0YZ8kd2bZgnCV5jl67ceZTLwOi0TjzGmqzaDPOvJaCOZqMMzW1WTQZZ15jaanUjTOvtd5XysaZ11yELVXjTHW9r1SNM2+lXGFKxpm3VEMyFePMWyvsmYLOmKsh2YHOsBtnJgt7UsrJnVDjzFuvSyzROPMoFs1unLXeoIG6xO6vcca9QWMEKO3B3DshxhnS1+Z0Fjtzb8YZoGwHw22c1d6gASjVE80I5pjhdrWNM2hK9UQzdz0bZ4iU+lHTm3EGKM3AFI7XONu4QQPpq1k6i/rC5dv/3ggITeGLFMcUKTmgtNeUKVPqipqSb9tblqG7K2E8PmzhhOmWlYWyAQXzlORm9CUTkEfruKzzZkDZDGQSXi6Z9OPKNbSMkb6e6kdMV6dMt/wYYEya/hKg/AMyIEHn0o+dH30FKA7Oo0QgcYZ+zQQkLlwO2245ygzDOKLZOdeuyYsAg2UJJjMMhGu4G/VjTOtiDlB2nxCWDjskxQApwsut41t6H3RxNCIzBGTmEtl1nxmAwakfsZ1x6oc5KDjJJVM/uIB0ph9moHRkSPV2OjhTBqN3QwpQqvUDFScEAXk0pDiARP0Y7rN2/oECIBPHb0jd7/P/lCUMQ4QhBSj/68fMCTCkAMWhhqREINyGlMiHFGSJwBBrSJmE0sGGhnHXC4qqoaDWvTwgheM1pIapPOQmEwpk5gw/PyUTBiM5Q0o1FDyTS5impGxIqYSSuiGlKn1pMaTUQMGzg4WlL22GVPJQNBpSyaYvzYZUklC0G1LJQbFgSCWlKVYMqSQixZohJR6KRUNKNBSrhpRYTbFsSImMFOuGlCgoMKSEQYEhJUxTYEgJgwJDqp9WuwQuzUHumD5XjSG110ihJfIzBv14DSCM6YtGR7s+MkmlIbXX9LWWyhYNh8IinvOufZ6ShyuCqWPpqjakxERKzbmKCUNKxDxlRV9ipIyf0Y8cQHqGsiL8F2s/NmVIiUpfa6ksprG4qc6cISVJ6NfbCOlKWKSgCdIUNEAx034JMADZ/Fm0ubFKhgAAAABJRU5ErkJggg==) !important;
}

.carousel-control-next,
.carousel-control-prev {
    width: 80px !important;
}


.headerBackground.top {
    background-position: top;
}

.headerBackground.big {
    height: 500px;
}

.ant-row-flex.rowCalidad {
    align-items: center;
}

img.efqmImg {
    max-width: 200px;
}

div#galleryPhotos {
    padding-top: 50px;
    height: calc(100vh);
}

img.homeIcon {
    max-width: 150px;
}

.rowGalContent {
    justify-content: center;
    align-items: center;
}


p.galleryText {
    padding-top: 20px;
}

.carousel-control-next-icon,
.carousel-control-prev-icon {
    width: 35px !important;
    height: 35px !important;
}

.iconCol.ant-col-md-6 {
    display: flex;
    justify-content: flex-end;
    padding-right: 100px;
}

.ant-row-flex.rowGalContent {
    padding-right: 100px;
    padding-left: 100px;
}

.readMoreBtn.gal {
    justify-content: flex-start;
}

.photoWorkWithUs {
    width: 100%;
}

.workWithUs {
    padding: 40px 0px;
    text-align: center;
    max-width: 350px;
    margin: auto;
}

.workWithUs .itemLinkUnirme {
    margin-top: 20px;
}

.workWithUs>div>div {
    margin: auto;
}

.workWithUs a {
    color: black;
    font-size: 18px;
}

.workWithUs a>div {
    display: inline-block;
    width: auto;
    margin-right: 20px;
}

.workTextCol.ant-col-xs-24.ant-col-md-12 {
    display: flex;
    align-items: center;
    padding-left: 100px;
    background: white;
    padding: 40px;
}

.galleryNameLight {
    font-size: 18px;
    padding-top: 10px;
}

div#v-news-tab {
    justify-content: center;
    flex-direction: column;
}


.darkCol {
    background: #8E171B;
}


.deltaFoot.ant-col-xs-24.ant-col-md-12 {
    margin: auto;
    align-items: center;
    padding-left: 100px;
}

span.colRight {
    padding-left: 20px;
    text-align: left;
    margin: auto;
    display: inline-flex;
    align-items: flex-start;
}

.footerTxtRow {
    padding-top: 60px;
}



.ant-row.footerTextRow {
    padding-top: 30px;
}

.ant-row.instaRow {
    justify-content: center;
}



.header2 {
    background-size: cover;
    height: calc(100vh - 200px);
    display: flex;
}

.textBox.light.center.cop {
    height: calc(100vh - 200px);
    display: inline-grid;
    align-items: center;
}

p.shortPar {
    text-align: justify;
    font-size: 20px;
}

.alignRight {
    text-align: right;
}


.alignLeft {
    text-align: left;
}

.headerDpto {
    background-image: url(/delta/static/media/Delta-08.a636261c.jpg);
}

.textBox.light.center.cop.extrac {
    height: 400px;
}

.headerFormativo.header2.extrac {
    height: 400px;
}


.headerFormativo {
    background-image: url(https://uedelta.k12.ec/delta2019/wp-content/plugins/autoAlbums/Gallery/Despedida_de_la_XIX/DSC_2161.JPG);
}

.headerServicios.header3 {
    background-image: url(/delta/static/media/colegio-23.5d989f9d.jpg);
    background-size: cover;
    background-position: center;
    height: 300px;
}

.headerCapellania {
    background-image: url(/delta/static/media/capellania.6aec2028.jpg);
    background-size: cover;
    background-position: center;

}



.headerMask {
    width: 100%;
    height: 100%;
    display: flex;
    background: #C2C3C9 !important;
}

.bigTitHeader {
    font-family: heavitas;
    font-size: 65px;
    text-align: center;
    color: white;
}

.bigTitHeader.mvv {
    font-size: 25px;
    margin-top: 35px;
}

.mvvContainerHeader p {
    font-size: 19px;
    ;
}

.peopleBox.dece {
    min-height: 330px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: auto;
    justify-content: center;
    vertical-align: middle;
}

.ant-col-xs-24.ant-col-md-8.medCol {
    padding: 40px;
    text-align: center;
}

.medicServiceCard {
    border-style: solid;
    border-width: 1px;
    padding: 30px 10px;
    border-color: #e2e2e2;
}


.thinTit.med {
    padding-top: 5px;
    padding-bottom: 15px;
}

p.shortPar.espaceTop.calidad {
    color: white;
    line-height: 36px;
}

.ant-row-flex.medRow {
    padding-left: 60px;
    padding-right: 60px;
}

.ant-row.drRow {
    text-align: center;
    padding-bottom: 10px;
    padding-top: 45px;
}

.center {
    text-align: center;
    margin: auto;
}

.drCol {
    padding-top: 40px;
}

.boldTit.medTit {
    line-height: 28px;
    color: #4d4d4d;
    padding-top: 12px;
}

.lightTextGray.med {
    font-size: 20px;
}

.ant-row-flex.footer2 {
    background: #8E171B;
    color: white;
    padding-top: 40px;
    padding-bottom: 20px;
}

.ant-row.socialRow2 {
    padding-top: 20px;
}

.white {
    color: white;
}

span.lightBtn.white {
    color: white;
    padding-left: 5px;
}

.searchBox {
    position: absolute;
    right: 100px;
    max-width: 50px;
    top: 115px;
    z-index: 1;
}

img.serchIcon {
    width: 100%;
}

.searchBox {
    position: absolute;
    right: 100px;
    max-width: 60px;
    top: 115px;
    z-index: 1;
}

.ant-row.row-news {
    display: flex;
    justify-content: center;
    align-items: center;
}

.deceBox .bigTitHeader {
    text-align: center;
}

.ant-col.colFormacion.ant-col-xs-24.ant-col-md-8 {
    text-align: center;
}

.titleCircle {
    font-size: 20px;
    font-weight: 700;
}

p.shortPar.espaceTop.forma {
    margin-bottom: unset;
}

.bigTitHeader.forma {
    font-size: 35px;
}

p.shortPar.espaceTop.forma {
    line-height: 26px;
}

.ant-row.contentRow.forma {

    padding-top: 20px
}

.headerCapellania.top.header2.forma {
    height: 300px;
    background-position: center;
}

.textBox.light.center.cop.shortHeader.forma {
    height: 300px;
    padding: 30px 100px;
}

.textCircle {
    padding-left: 20px;
    padding-right: 20px;
}

.boldTit.white.ft2 {
    font-size: 20px;
}

.ant-row-flex.ant-row-flex-center.formRow {
    justify-content: center;
    vertical-align: middle;
    align-items: center;
}

.bigTitHeader.acad.shortTit.extra {
    font-size: 37px;
}

.textCircle {
    font-size: 18px;
    line-height: 24px;
}

.alignLeft {
    padding-left: 20px !important;
}

.ant-row.contentRow {
    margin: auto;
    padding-top: 40px;
    padding-bottom: 40px;
}

.rmFt {
    padding-top: 15px;
}

.smallBar.ft2 {

    background: #b7848a;
}

.whiteBtn.square.ft2 {
    background: white;
    color: #8E171B;
}

.thinTit.footer2 {
    color: #b7848a;
    font-size: 20px;
}

.ant-row.contentRow {
    padding-left: unset;
}


.textBox {
    color: white;
    padding: 50px 100px;
}


.textBox.light {
    background: #C2C3C9;
}

.textBox.dark {
    background: #8E171B;
}

img.subjectImg {
    width: 100%;
}

.subjectImgBox {
    max-width: 250px;
    text-align: center;
    margin: auto;
}

.ant-row-flex.rowSubjects {
    margin: auto;
    align-items: center;
    text-align: center;
    padding-bottom: 40px;
    padding-left: 100px;
    padding-right: 100px;

}

.subjectBox.ant-col-xs-12.ant-col-md-6 {
    padding-top: 15px;
}

.smallBar.dark2 {
    margin: auto;
}

.colTitsubjects {
    padding-top: 30px;
}

.titleBd {
    font-family: heavitas;
    color: #8E171B;
    font-size: 25px;
    padding-top: 5px;
    text-align: center;
}

.titleLt {
    font-family: antipasto;
    font-size: 20px;
    margin-top: -5px;
    text-align: center;
}

.ant-row-flex.rowTextIp {
    padding: 5px 100px 30px 100px;
}

.subjectBox {
    padding-top: 35px;
}

span.lazy-load-image-background.blur.lazy-load-image-loaded {
    cursor: pointer;
}

.titIp.center {
    font-size: 23px;
    font-weight: 600;
}

.ibBoxImg {
    max-width: 100px;
    margin: auto;
    padding-bottom: 10px;
}

img.ibImg {
    width: 100%;
}

.textIp.center {
    max-width: 280px;
}

.boldTit.med {
    color: #bbbbbb;
}

.responsiveMenu {
    padding: 30px 50px;
    position: relative;
    background: #ececec;
}

.rwd.nav-link[data-toggle].collapsed:after {
    content: "▾";
}

.rwd.nav-link[data-toggle]:not(.collapsed):after {
    content: "▴";
}

.peRight.ant-col-md-12 {
    background: #C2C3C9;
    color: white;
    padding: 10px 100px 10px 100px;
    min-height: 250px;
    display: flex;
    align-items: center;
    text-align: right;
}

.peLeft.ant-col-md-12 {
    display: flex;
    align-items: center;
    padding: 10px 100px 10px 100px;
}

.rightText {
    text-align: justify;
}

.normalRow {
    padding-left: 100px;
    padding-right: 100px;
    padding-top: 40px;
    padding-bottom: 40px;

}

.formativoBox {
    border-style: solid;
    color: #eaeaea;
    border-width: 1px;
    text-align: center;
    max-width: 550px;
    padding: 15px 10px 35px 10px;
}

.academicoBox {
    border-style: solid;
    color: #eaeaea;
    border-width: 1px;
    text-align: center;
    max-width: 550px;
    padding: 15px 10px 35px 10px;
}

.academicoCol.ant-col-xs-24.ant-col-md-12 {
    direction: rtl;
}

.peIcon {
    width: 100%;
}

.peIconBox {
    max-width: 300px;
    margin: auto;
}



.readMoreBtn.peBtn {
    padding-top: 20px;
    justify-content: center;
}

.lightTextGray2 {
    color: #8c8b8b;
}

.boldTit.peTit {
    color: black;
}

.whiteBtn.square.left {
    width: 45px;
}

.whiteBtn.square.right {
    width: 45px;
}


a.rmoreLink {
    display: inline-flex;
    justify-content: center;
    align-items: center;
}

a.rmoreLinkRev {
    flex-direction: row-reverse;
    display: inline-flex;
    justify-content: center;
    align-items: center;
}

.historyBox {
    border-style: solid;
    border-width: 1.5px;
    border-color: lightgray;
    padding: 60px;
    margin-top: -60px;
}

.leftText.history {
    text-align: justify;
}

.peRight.history.ant-col-xs-24.ant-col-md-12 {
    background: lightgray;
    color: #656565;
}

.fqmTitle {
    font-size: 90px;
    font-family: heavitas;
    text-align: center;
    line-height: 100px;
}

.fqmBar {
    width: 100%;
    background: #8c8b8b;
    height: 30px;
}

.boldTextFqm {
    font-size: 22px;
    line-height: 35px;
    text-align: justify;
    font-weight: bold;
}

.textFqm {
    padding-top: 5px;
    font-size: 20px;
    font-weight: lighter;
}

.textFqm {
    padding-top: 5px;
    font-size: 20px;
}

.ant-row-flex.rowCalidad {
    padding: 10px 100px 60px 100px;
}

.colCalidad.ant-col-xs-12.ant-col-md-18 {
    padding-left: 80px;
}

.colComponent {
    padding: 1px 40px !important;
}

p.textComponent {
    TEXT-ALIGN: JUSTIFY;
    font-weight: 200;
}

.logosCopeceBox.copece {
    max-width: 450px;
}

.ant-row-flex.normalRow.copece {
    align-items: center;
}


.ant-row-flex.rowSquare {
    justify-content: flex-end;
}

.detailCalidad2Div {
    padding-left: 100px;
}


.detailCalidad2 {
    color: #8C8B8B;
    width: 75%;
    max-height: 450px !important;
    position: relative;
    margin-top: -20px;
    padding: 60px;
    border-style: solid;
    border-color: #D8D6D6;
    border-top-width: 2px;
    border-right-width: 2px;
    border-bottom-width: 2px;
    border-left-width: 2px;
}

.detailCalidad {
    font-size: 115%;
    background-color: #C2C3C9;
    padding: 60px 100px 60px 100px;
    padding-top: 10 !important;
    display: flex;
}


.darkRowComp {
    background: #8E171B;
    color: white;
    padding: 60px 100px;
}

.colLine {
    border-right: solid;
    border-width: 2px;
}

.ant-row-flex.topRowEquipo {
    padding: 20px 100px 40px;
}

.dirImg {
    width: 100%;
}

.dirBoxImg {
    max-width: 160px;
}

.cold {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
}

.ant-row-flex.directoraRow {
    align-items: flex-end;
    padding-top: 30px;
}

.directoraBox {
    padding-right: 30px;
}

.cargo {
    font-size: 25px;
    font-family: heavitas;
    line-height: 25px;
    color: #b1b1b1;
    padding-top: 3px;
}

.boldTextEquipo {
    font-size: 25px;
    font-family: heavitas;
    line-height: 32px;
    color: #8E171B;
    padding-top: 10px;
}

.lightTextEquipo {
    color: #C2C3C9;
    font-size: 20px;
}

.alignRightCol.ant-col-xs-24.ant-col-md-12 {
    text-align: right;
    padding-right: 20px;
}

.alignLeftCol.ant-col-xs-24.ant-col-md-12 {
    padding-left: 20px;
}

.darkLine {
    height: 4px;
    width: 30px;
    background: #8E171B;
    position: relative;
    text-align: center;
    margin: auto;
}

.pbottom {
    padding-bottom: 25px;
}


.lista li {
    list-style: none;
}

li.activity {
    list-style: disc outside none;
    list-style: initial;
    padding-top: 8px;
}

img.activityIcon {
    max-width: 25px;
    padding-right: 5px;
}

.placeItem {
    padding-top: 5px;
    display: flex;
    align-items: center;
}

.peLeft.capellania {
    align-items: flex-end;
}

.peLeft.tit {
    padding-left: 100px;
}

.ant-row-flex.activitiesTypeRow {
    padding-top: 20px;
    padding-bottom: 10px;
}

.ant-row-flex.horariosRow {
    padding-bottom: 30px;
}

.ant-row-flex.capellaniaTab {
    padding-left: 100px;
}

.pName.ant-col-xs-24.ant-col-md-6 {
    background: whitesmoke;
    min-height: 60px;
    display: flex;
    align-items: center;
    padding-left: 20px;
}

.ant-row-flex.capellaniaTab {
    display: flex;
    align-items: center;
}

.ant-row-flex.capellaniaTab {
    display: flex;
    align-items: center;
    padding-bottom: 5px;
}

.dias.ant-col-xs-16.ant-col-md-12 {
    padding-left: 20px;
    border-right-style: solid;
    border-right-color: #d8d8d8;
    min-height: 50px;

}

.horas.ant-col-xs-6.ant-col-md-6 {
    padding-left: 20px;
}

.horariosSection {
    padding-bottom: unset;
    padding-top: 40px;
}

.ant-row.retirosRow {
    padding-bottom: 30px;
}

.actCol.ant-col-xs-24.ant-col-md-12 {
    padding-left: 100px;
    padding-top: 20px;
}

.actCol.tit.ant-col-xs-24.ant-col-md-24 {
    padding-left: 100px;
}

.actCol.capellania.ant-col-xs-24.ant-col-md-12 {
    display: flex;
    align-items: flex-end;
}

.servicesRow {
    padding: 30px 100px;
}

.servicesRow.icons {
    padding-top: 10px;
}

.bigTitHeader.bar {
    margin: auto;
}

div#cursoOpt {
    display: inline-grid;
}

.ant-col.textServiceCol.ant-col-xs-24.ant-col-md-16.ant-col-lg-19 {
    font-size: 20px;
}

.ptop.ant-col-xs-24.ant-col-md-24 {
    padding-top: 50px;
}

.imageBox {
    max-width: 190px;
}

.smallTitulo {
    padding-top: 15px;
}

img.serviceImg {
    width: 100%;
    padding: 15px;
}

.ant-row-flex.servicesRow {
    align-items: center;
}

.bigIcon {
    max-width: 100px;
    margin: auto;
}

.ant-card.cardNew.ant-card-bordered {
    padding-top: unset;
    border: 1px solid #e8e8e8 !important;
}

.cardNew .ant-card-meta-description {
    font-size: 16px;
}

.bigIconImg {
    width: 100%;
}

.ant-card-meta-title {
    font-size: 20px;
}

.textAtencion {
    text-align: center;
    padding-top: 10px;
    font-size: 20px;
}


.ant-row-flex.servicesRow.icons {
    padding-bottom: 40px;
}

p.shortPar {
    text-align: justify;
}

.logosBox {
    margin: auto;
}

.ant-row-flex.copeceRow {
    padding: 10px 100px 30px 100px;
}

.textCopece.ant-col-xs-24.ant-col-md-12 {
    text-align: justify;
}

.leftText.history {
    font-family: heavitas;
    font-size: 50px;
    line-height: 45px;
}


.ant-row-flex.copeceRow {
    align-items: center;
}

.letters {
    letter-spacing: 10px;
}

img.imgColegios {
    width: 100%;
}

.ant-row-flex.normalRow.history {
    padding-bottom: 40px;
}

img.padresImg {
    max-width: 230px;
}

.peopleBox {
    padding: 20px;
    border: #efefef;
    border-style: solid;
    border-width: 1px;
}

video#videoBg {
    margin-top: -60px;
}

.copecePeople.ant-col-xs-24.ant-col-md-8 {
    padding: 10px;
}

.bigTit.people {
    color: #adadad;
    font-size: 30px;
    font-family: heavitas;
    line-height: 60px;
}

.ant-row-flex.copeceRow.center.people {
    padding-bottom: 60px;
}

.justifyText {
    text-align: justify;
}

.heavitas {
    font-family: heavitas;
}

.justifyText.value {
    font-weight: 100;
    font-family: helvetica;
    font-size: 15px;
    color: white;
}

.valueCol.light.ant-col-xs-24.ant-col-md-12 {
    padding: 60px 100px;
}

.valueCol.dark.ant-col-xs-24.ant-col-md-12 {
    padding: 60px 100px;
}


.valueCol.dark {
    background: #8E171B;
}

.valueCol.light {
    background: #C2C3C9;
}

.valueTit.dark {
    font-size: 30px;
    font-family: heavitas;
    color: #8E171B;
    padding-bottom: 20px;
}

.valueTit.light {
    font-size: 30px;
    font-family: heavitas;
    color: #C2C3C9;
    padding-bottom: 20px;
}

.ant-row.coreValuesRow {
    padding: 60px 100px;
}

.big {
    line-height: 60px;
}


.coreValuesBox.heavitas {
    background: #C2C3C9;
    color: white;
    max-width: 200px;
    font-size: 20px;
    line-height: 42px;
    text-align: center;
}

.valuesList {
    padding-top: 15px;
    font-size: 20px;
}

.valueTit.light.big {
    padding-bottom: unset;
}

img.subjectImg.ib {
    padding: 25px;
}

.ant-row-flex.rowSubjects {
    padding-bottom: 60px;
}

.peopleText {
    font-size: 20px;
}

.hpadding {
    padding-top: 30px;
    padding-bottom: 60px;
}

.ant-row-flex.history {
    font-size: 20px;
}

.peopleText.teach {
    padding: 15px 25px;
}

.bigTit.dece {
    font-family: heavitas;
    font-size: 20px;
    padding-top: 15px;
}

.copeceRow.center.people.dece {
    padding-bottom: 20px;
}

.medBox {
    max-width: 300px;
    margin: auto;
}

img.medImg {
    width: 100%;
    margin: auto;
}

.peopleBox.dece {
    background: none;
    border-style: solid;
    border-color: #eaeaea;
    border-width: 1px;
}



#videoBg {
    right: 0;
    bottom: 0;
    min-width: 100%;
    min-height: 100%;
}

.padresImg.dece {
    max-width: 240px;
    padding-bottom: 20px;
}

.leftText.history.cop {
    color: #C2C3C9;
    text-align: center;
    padding-bottom: 15px;
}

.ant-row.footerMenu {
    padding-left: 110px;
    padding-right: 110px;
    padding-top: 30px;
    display: flex;
    align-items: center;
    color: #b7b7b7;
}

img.iconImg.menu {
    padding: 15px;
    max-width: 70px;
}

.iconM {
    max-width: 60px;
}

.alignRight.icons {
    position: absolute;
    right: 100px;
    top: 0;
    bottom: 0;
    margin: auto;
    align-items: center;
    display: inline-table;
    text-align: center;
}

.iconName {
    color: #9e9999;
    text-align: center;
    margin: auto;
    justify-content: center;
    align-items: center;
    font-size: 10px;
    padding-bottom: 15px;
}

img.instaImg {
    width: 90%;
    text-align: center;
    margin: auto;
    padding-bottom: 10px;
    height: 150px;
    object-fit: cover;
}

video.instaImg {
    width: 90%;
    height: 140px;
    object-fit: cover;
    background: black;
    object-position: center;
    padding-bottom: 10px;
}

.ant-row.instaRow {
    padding: 10px 100px;
}

.headerInstaCol {
    color: white
}

.readCard {
    background: gray;
    color: white;
}

.ant-row-flex.feedRow {
    padding: 15px 100px 60px 100px;
}

.newsCard.ant-col-xs-24.ant-col-md-12 {
    padding-top: 30px;
}

.colPostAside.ant-col-xs-24.ant-col-md-6 {
    background: whitesmoke;
    padding: 40px 100px 40px 0px;
}

.colPostPage.ant-col-xs-24.ant-col-md-18 {
    background: whitesmoke;
    padding: 40px 0px;
}

.headerNews {
    text-align: center;
    margin: auto;
}

h3.newsTitle {
    padding-top: 30px;
}

.postImg {
    width: 100%;
    margin: auto;

}

.newBox {
    max-width: 850px;
    margin: auto;
    background: white;
    padding: 30px 60px;
    box-shadow: 3px 3px 3px 1px #00000012;
}

.newBox table,
.newBox table strong {
    font-size: 12px;
    ;
}


h3.lastNewsTit {

    border-bottom: solid;
    border-color: #C2C3C9;
    line-height: 70px;

}

.postDate {
    font-size: 10px;
}

.newsList {
    padding-top: 10px;
}

.gallery {
    -webkit-column-count: 4;
    -moz-column-count: 4;
    column-count: 4;
    -webkit-column-width: 25%;
    -moz-column-width: 25%;
    column-width: 25%;
}

.gallery .pics {
    -webkit-transition: all 350ms ease;
    transition: all 350ms ease;
}

.gallery .animation {
    -webkit-transform: scale(1);
    transform: scale(1);
}



.picsMason {
    width: 25%;
    padding: 8px 10px;
}

.picsMason .card {
    background: none;
}

.lazy-load-image-background.blur {
    width: 100%;
}

.card-body.gallery-card {
    padding: 10px;
}

.contenedorImagenReceta img {
    width: 100%;
    height: 400px;
    object-fit: cover;
}

.ant-row-flex.perfilesRow {
    justify-content: center;
}

img.imgPerfiles {
    width: 100%;
}

.imgBoxPerfiles {
    max-width: 400px;
    margin: auto;
    padding-bottom: 30px;
}

.contenedorImagenRecetaSmall img {
    width: 100%;
    object-fit: cover;
    font-family: 'object-fit: cover;';
}

.contenedorImagenRecetaBig img {
    width: 100%;
    height: 336px;
    object-fit: cover;
    font-family: 'object-fit: cover;';
}

.contenedorImagenBig img {
    width: 100%;
    object-fit: cover;
    font-family: 'object-fit: cover;';
}

.justify-content-md-center.galleryBox {
    background: #f1f1f1;
}

.galleryName {
    width: 100%;
}

.galleryName {
    padding: 30px;
    text-align: center;
    background: #C2C3C9;
    color: white;
    font-family: heavitas;
    font-size: 24px;
    margin-top: 10px;
    text-transform: uppercase;
}


@media (max-width:960px) {
    .picsMason {
        width: 50%;
    }
}

@media (max-width:590px) {
    .picsMason {
        width: 100%;
    }
}

span.lightBtn {
    padding-left: 5px;
}

a.rmGallery {
    display: contents;
}

img.profile {
    padding-bottom: unset;
    border-radius: 60px;
    max-width: 80px;


}


.ant-row.instaRow.Header {
    display: flex;
    align-items: center;
    font-size: 14px;
    padding-top: 10px;
}

.fullname {
    font-family: heavitas;
    font-size: 21px;
}


.instaBox {
    background: #C2C3C9;
    max-width: 200px;
    color: white;
    margin: auto;
    border-radius: 0px;
    padding: 2px;
    margin-top: 15px;
}

img.minInsta {
    max-width: 50px;
    padding-right: 5px;
    padding-left: 5px;
}

a.instaBtn {
    color: white;
    font-family: helvetica;
    font-size: 13px;
}

a.instaBtn:hover {
    color: white;
}

.shortBio {
    color: white;
    font-size: 13px;
}

.copy {
    color: white;
    font-size: 10px;
    text-align: center;
    padding-bottom: 18px;
}

.ant-row.instaRow.Header {
    padding-top: 30px;
}

.ideario {
    overflow: hidden;
    height: inherit;
}

.ideario img {
    height: 100%;
    max-height: 550px;
    object-fit: cover;
}

.shortBio {
    color: white;
    font-size: 13px;
    padding-top: 20px;
    border-top: solid;
    border-width: 0.5px;
}

.ant-row.btnRow {
    padding-bottom: 10px;
}

.darkCol.ant-col-xs-24.ant-col-md-12 {
    display: flex;
}

.darkBox {
    margin: auto;
    padding-left: 30px;
    padding-right: 30px;
    max-width: 600px;
    margin: auto;
}

.container.calendar {
    padding-top: 15px;
}

.calendarHeader {
    color: white;
    background-color: #8E171B;
    padding: 25px;
    font-size: 30px;
    font-family: heavitas;
    position: relative;
    box-shadow: 6px 3px 3px 1px #00000030;
}

span.rightALign {
    text-align: right;
    position: absolute;
    right: 25px;
}

.ant-row-flex.rowCalendar {
    padding: 20px;
    border-left: solid;
    border-left-width: 10px;
    border-left-color: #8E171B;
}

.day {
    font-size: 26px;
    line-height: 25px;
    font-family: heavitas;
    color: #bfbfbf;
}

.month {
    font-size: 15px;
    font-family: helvetica;
    text-transform: uppercase;
}

.colCalendar.ant-col-md-6 {
    text-align: center;
    padding-right: 40px;
}

.colDescription.ant-col-md-18 {
    margin: auto;
}

a.eventLink {
    color: black;
    font-family: helvetica;
    font-size: 17px;
}


.ant-row-flex.rowCalendar.color {
    background: #FAF3E5;
    border: unset;
    border-left: solid;
    border-left-width: 10px;
    border-left-color: #C2C3C9;
}

.eventos {
    box-shadow: 6px 3px 8px 0px #00000030;
}

i.anticon.anticon-clock-circle.miniClock {
    padding-right: 8px;
}

.eventDescription {
    padding-bottom: 5px;
}

.container.calendar {
    padding-bottom: 60px;
}

p.noEvents {
    text-align: center;
    padding: 60px;
    box-shadow: 8px 5px 4px #e4e4e4;
    font-size: 20px;
}

.hiddenEvents {
    display: none;
}

.container.calendar {
    min-height: calc(100vh - 346px);
}

.loading {
    text-align: center;
    padding: 60px;
}

#finder {
    background: white;
}

i.anticon.anticon-close.closeFinder {
    background: #C2C3C9;
    color: white;
    height: 60px;
    width: 60px;
    border-radius: 30px;
    text-align: center;
    display: flex;
    align-items: center;
    padding: 0 15px 0 18px;
}



input#find,
input#validate_other_find {
    border: unset;
    border-bottom: solid;
    border-width: 1px;
    border-radius: unset;
    min-width: 900px;
    font-size: 50px;
    padding-bottom: 20px;
    height: 80px;
    background: unset;
}

input#find:focus,
input#validate_other_find:focus {
    box-shadow: none;
}

.ant-modal-body {
    /* max-height: calc(100vh - 210px); */
    overflow-y: auto;
}

.ant-modal-content {
    padding-bottom: 48px;
}

.resultSearch {
    width: 100%;
}

.tipoderesultado h1 {
    color: #8E171B;
    font-size: 30px;
    font-family: heavitas;
}

.tituloResultado h1 {
    color: #676767;
}

.ant-row-flex.finderBody {
    padding: 60px 120px;
    background: #f1f1f196;
    height: calc(100vh - 160px);
    overflow-y: overlay;

}

span.ant-input-search.ant-input-affix-wrapper {
    width: unset !important;
}

i.anticon.anticon-search.ant-input-search-icon {
    font-size: 20px;
}

.ant-row-flex.rowMenuModal.finder {
    padding: 20px 100px;
}

.searchBox.modalClose {
    top: 122px;
}

a.rwd.nav-link {
    font-family: 'Kanit', sans-serif;
    font-size: 18px;
    color: #C2C3C9;
    font-weight: 600;
}

a.nav-link.py-0:hover {
    color: white;
}

nav.bm-item-list:focus {
    outline: none;
}

.bm-item:focus {
    outline: none;
}

a.nav-link.py-0 {
    color: #ffffffad;
    font-size: 15px;
    line-height: 30px;
    font-family: helvetica;
}

.galImg {
    width: 100%;
}

.galBox {
    margin: auto;
}

.galleryName2 {
    TEXT-ALIGN: CENTER;
    PADDING: 40PX;
    color: #C2C3C9;
    font-family: heavitas;
    font-size: 45px;
}

.ant-row-flex.galleryGridRow {
    padding: 0px 100px;
}



.image-container {
    width: 100%;
    float: left;
    border: 5px solid #fff;
    overflow: hidden;
    position: relative;
    text-align: center;
    box-shadow: 0px 0px 5px #aaa;
    cursor: default;
}

.image-container .mask,
.image-container .content {
    width: 100%;
    height: 100%;
    position: absolute;
    overflow: hidden;
    top: 0;
    left: 0;
}

.image-container img {
    display: block;
    position: relative;
}

.image-container a.info {
    display: inline-block;
    text-decoration: none;
    padding: 0;
    text-indent: -9999px;
    width: 20px;
    height: 20px;
}

.effect .mask {
    opacity: 0;
    overflow: visible;

    box-sizing: border-box;
    -webkit-transition: all 0.4s ease-in-out;
    transition: all 0.4s ease-in-out;
    display: flex;
}



.effect:hover .mask {
    opacity: 1;
    background-color: #0e0e0ea6;
    display: flex;
}

.effect:hover a.info {
    opacity: 1;
    -webkit-transition-delay: 0.3s;
            transition-delay: 0.3s;
}

.galTit {
    font-size: 40px;
    color: #C2C3C9;
    text-align: center;
    margin: auto;
    font-family: heavitas;
}

.wrapper.galeria {
    padding-top: 30px;
}

/* ----------------------------------------------
 * Generated by Animista on 2019-9-19 13:54:35
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info.
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation tracking-in-expand-fwd
 * ----------------------------------------
 */
@-webkit-keyframes tracking-in-expand-fwd {
    0% {
        letter-spacing: -0.5em;
        -webkit-transform: translateZ(-700px);
        transform: translateZ(-700px);
        opacity: 0;
    }

    40% {
        opacity: 0.6;
    }

    100% {
        -webkit-transform: translateZ(0);
        transform: translateZ(0);
        opacity: 1;
    }
}

@keyframes tracking-in-expand-fwd {
    0% {
        letter-spacing: -0.5em;
        -webkit-transform: translateZ(-700px);
        transform: translateZ(-700px);
        opacity: 0;
    }

    40% {
        opacity: 0.6;
    }

    100% {
        -webkit-transform: translateZ(0);
        transform: translateZ(0);
        opacity: 1;
    }
}

@-webkit-keyframes swing-in-top-fwd {
    0% {
        -webkit-transform: rotateX(-100deg);
        transform: rotateX(-100deg);
        -webkit-transform-origin: top;
        transform-origin: top;
        opacity: 0;
    }

    100% {
        -webkit-transform: rotateX(0deg);
        transform: rotateX(0deg);
        -webkit-transform-origin: top;
        transform-origin: top;
        opacity: 1;
    }
}

@keyframes swing-in-top-fwd {
    0% {
        -webkit-transform: rotateX(-100deg);
        transform: rotateX(-100deg);
        -webkit-transform-origin: top;
        transform-origin: top;
        opacity: 0;
    }

    100% {
        -webkit-transform: rotateX(0deg);
        transform: rotateX(0deg);
        -webkit-transform-origin: top;
        transform-origin: top;
        opacity: 1;
    }
}

@-webkit-keyframes slide-in-elliptic-top-fwd {
    0% {
        -webkit-transform: translateY(-600px) rotateX(-30deg) scale(0);
        transform: translateY(-600px) rotateX(-30deg) scale(0);
        -webkit-transform-origin: 50% 100%;
        transform-origin: 50% 100%;
        opacity: 0;
    }

    100% {
        -webkit-transform: translateY(0) rotateX(0) scale(1);
        transform: translateY(0) rotateX(0) scale(1);
        -webkit-transform-origin: 50% 1400px;
        transform-origin: 50% 1400px;
        opacity: 1;
    }
}

@keyframes slide-in-elliptic-top-fwd {
    0% {
        -webkit-transform: translateY(-600px) rotateX(-30deg) scale(0);
        transform: translateY(-600px) rotateX(-30deg) scale(0);
        -webkit-transform-origin: 50% 100%;
        transform-origin: 50% 100%;
        opacity: 0;
    }

    100% {
        -webkit-transform: translateY(0) rotateX(0) scale(1);
        transform: translateY(0) rotateX(0) scale(1);
        -webkit-transform-origin: 50% 1400px;
        transform-origin: 50% 1400px;
        opacity: 1;
    }
}


.tracking-in-expand-fwd {
    -webkit-animation: tracking-in-expand-fwd 0.8s cubic-bezier(0.215, 0.610, 0.355, 1.000) both;
    animation: tracking-in-expand-fwd 0.8s cubic-bezier(0.215, 0.610, 0.355, 1.000) both;
}

.slide-in-elliptic-top-fwd {
    -webkit-animation: slide-in-elliptic-top-fwd 0.7s cubic-bezier(0.250, 0.460, 0.450, 0.940) 0.8s both;
    animation: slide-in-elliptic-top-fwd 0.7s cubic-bezier(0.250, 0.460, 0.450, 0.940) 0.8s both;
}

.hvr-radial-out:hover {
    background: red;
}

.modalVideo .ant-modal-body {
    background: black;
    display: flex;
}

i.anticon.anticon-close.iconVideo {
    color: white;
    text-align: right;
    margin: inherit;
    position: absolute;
    right: 30px;
    top: 15px;
    z-index: 20;
}

.ant-col-xs-24.ant-col-md-14.vid {
    min-height: 628px;
    display: flex;
    align-items: center;
    padding-right: 60px;
}

.ant-row-flex.videoRow {
    padding-left: 100px;
    padding-right: 100px;
}

.galleryName.vid {
    margin-bottom: 30px;
}

.vidCol {
    padding-bottom: 20px;
    position: relative;
}

#myVideo {
    position: relative;
}

.vidTit {
    position: absolute;
    top: 0;
    background: #00000026;
    color: #ffffffbd;
    padding: 12px;
    z-index: 5;
    font-size: 11px;
    font-family: helvetica;
}

.copy.center {
    padding-top: 15px;
    border-top: solid;
    border-width: thin;
    width: 200px;
    border-color: #772832;
}

.ptop {
    padding-top: 30px;
}

.whiteBtn.square.llenar {
    padding: 8px 7px 8px 12px;
    font-size: 10px;
    height: 40px;
    width: 40px;
    line-height: 25px;
}

.miniBio {
    padding-top: 30px;
    padding-right: 100px;
}

.linkLogo {
    width: 100%;
    background: #8E171B;
}

.linkPreescolar:hover,
.linkTorremar:hover {
    top: 0;
}

.linkPreescolar {
    right: 275px;
    width: 110px;
    height: 125px;
    position: absolute;
    z-index: 2;
    top: -100px;
    -webkit-transition: top 0.5s;
    transition: top 0.5s;
    text-align: center;
}

img.imgLogoIn {
    max-width: 100px;
    margin: auto;
    text-align: center;
}

img.imgLogoOut {
    max-width: 130px;
    margin-top: -2px;
}

.linkPreescolar {
    right: 245px;
    width: 130px;
    height: 125px;
    position: absolute;
    z-index: 2;
    top: -100px;
    -webkit-transition: top 0.5s;
    transition: top 0.5s;
}

.linkTorremar {
    right: 100px;
    width: 130px;
    height: 125px;
    position: absolute;
    z-index: 2;
    top: -100px;
    -webkit-transition: top 0.5s;
    transition: top 0.5s;
    text-align: center;
    margin: auto;
}

img.profile {
    border-width: 5px;
    background: white;
    border-style: solid;
}

.ant-row-flex.menuNews {
    background: #F9FBFB;
}

.galleryGridRow.pb {
    padding-bottom: 40px;
}

.deltaFoot.ant-col-xs-24.ant-col-md-12 {
    padding-top: 10px;
    background: #8E171B;
}

.miniBio {
    padding-top: 15px;
    padding-right: 100px;
    color: white;
}

span.textContact {
    color: white;
    font-size: 13px;
}

.boldTit.footer {
    color: #C2C3C9;
}

.iconImg.socialFooter {
    max-width: 40px;
}

.menuIconBox {
    position: absolute;
    z-index: 20;
    top: 30px;
    left: 100px;
}

.calidadText {
    padding: 30px;
    border: solid;
    border-width: 2px;
    border-color: gainsboro;
    font-size: 20px;
}

.calidadBox {
    padding: 40px 100px;
}

.bigTitHeader.type2 {
    font-size: 45px;
    line-height: 55px;
}

.headerFormativo.header2.shortHeader {
    height: 350px;
}

.textBox.light.center.cop.shortHeader {
    height: 350px;
}

.bigTitHeader.short {
    font-size: 60px;
}

.wrapper-footer {
    background: #8E171B;
}

.footerTextCol.left.ant-col-md-12 {
    text-align: right;
    padding-right: 15px;
}

.footerTextCol.right.ant-col-md-12 {
    padding-left: 15px;
}

.ant-row.socialRow {
    text-align: center;
}

.socialIcons {
    display: inline-flex;
}

.headerText {
    background: #C2C3C9;
    padding: 10px;
}

.bigTitHeader.Historia {
    font-size: 40px;
}

img.imgMap {
    border-right-style: solid;
    border-color: #199bb1;
}

.mapBox {
    text-align: center;
    background: #199bb1;
}

.parrBox {
    font-size: 22px;
    padding-top: 15px;
    padding-bottom: 15px;
    text-align: justify;
}

span.boldMeg {
    font-weight: 600;
}

.ant-modal.modalPopUp {
    max-width: 600px;
    height: unset !important;
    margin: auto !important;
}

.modalPopUp .ant-modal-body {
    height: 500px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 20px;
}

a.linkPop {
    color: white;
}

.titPopBox.link {
    background: #c79213;
    padding: 12px;
    width: 100px;
    text-align: center;
    margin-top: 20px;
}

.titPopBox {
    text-align: center;
}

i.anticon.anticon-close.iconPopTemp {
    position: absolute;
    right: 20px;
    top: 20px;
    color: #969595;
}

.bigTitHeader.size1 {
    font-size: 50px;
    line-height: 50px;
}

.ant-row.instaRow {
    max-width: 800px;
}

.ant-row.instaRow.bioCopy {
    margin: auto;
}

.textCopece.ant-col-xs-24.ant-col-md-16 {
    font-size: 20px;
    margin: auto;
    text-align: center;
}

.coverNewImg {
    width: 100%;
    height: 360px;
    background-size: cover;
    background-position: top;
}

.boxImgNew {
    padding-bottom: 30px;

}

.boxImgNew.newsFeed {
    padding-top: unset;
    padding-bottom: 10px;
}

.bigTitHeader.tooLong {
    font-size: 40px;
}

.long-jump {
    display: none;
}

.subjectBox.clubes {
    margin: auto;
}

.imgClubPop {
    max-width: 300px;
    margin: auto;
}

.modalClub {
    max-width: 600px;
}


.modalClub .ant-modal-content {
    padding-bottom: unset;
}

.modalClub .ant-modal-body {
    overflow-y: scroll;
}

.ant-modal.modalClub {
    text-align: center;
}

.listaTit {
    padding-top: 20px;
    font-size: 20px;
    font-weight: bold;
}

img.imgClubPop {
    max-width: 200px;
    padding-top: 30px;
}

.lista.periodismo {
    padding-top: 20px;
    text-decoration: none;

}

.image-container.effect.videos {
    max-height: 280px;
    border: unset;

}

.galTit.video {
    font-size: 27px;
}

.ant-modal-confirm-content {
    margin: auto !important;
    padding: 30px;
}

.ant-modal-confirm-btns {
    display: none;
}

.ant-modal-confirm-info .ant-modal-confirm-body>.anticon {
    display: none;
}

.image-container.effect.gallery {
    height: 400px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.colGaleryImg.ant-col-xs-24.ant-col-md-12 {
    padding-bottom: 20px;
}


.imgHistoriaBox {
    margin: auto;
    text-align: center;
    max-width: 650px;
}

img.imgHistoria {
    width: 100%;
}

.espaceTopRow {
    padding-top: 20px;
}

.colActividadesTit.ant-col-xs-24.ant-col-md-24 {
    padding-left: 100px;
    padding-top: 25px;
}

p.shortPar.espaceTop.capellania {
    font-size: 20px;
}

.iconVideo.clubBtn {
    color: black;
}

.ant-card.newsCardBody.ant-card-bordered {
    height: 100%;
}


form.ant-form.ant-form-horizontal.transcriptForm {
    padding: 40px 60px;
    box-shadow: 4px 6px 4px #b7b7b7;
    border: solid;
    border-width: 1px;
    border-color: #eaeaea;
}

.formBox {
    padding: 30px 100px 60px;
}

.nameBox {
    display: inline-flex;
    width: 100%;
}

.ant-row.ant-form-item.itemName {
    width: 97%;
}

.ant-row.ant-form-item.itemAlumna {
    padding-left: 20px;
}

.ant-row.ant-form-item.itemAlumna {
    display: inherit;
}

.ant-checkbox-wrapper+.ant-checkbox-wrapper {
    margin-left: unset !important;
}

label.ant-checkbox-wrapper {
    padding-top: 10px;
}

input#nombre {
    margin-left: 15px;
}

#studentFields {
    display: none;
}

#exStudentFields {
    display: none;
}

input#curso {
    max-width: 300px;
}

input#paralelo {
    max-width: 300px;
}




.ant-radio-group.ant-radio-group-outline {
    display: grid;
    padding-top: 10px;
}

h2 {
    width: 100%;
    text-align: center;
    border-bottom: 1px solid #9a9a9a;
    padding-top: 15px;
    line-height: 0.1em;
    margin: 10px 0 20px;
    color: #afafaf;
    font-size: 22px;

}

h2 span {
    background: #fff;
    padding: 0 10px;
}

.requestTit {
    text-align: center;
    max-width: 100%;
    margin: auto;
    border-width: 2px;
    padding: 10px;
    font-weight: 700;
    background: #eaeaea;
}

.reqBox {
    border: solid;
    padding: 20px;
    margin-top: 20px;
    border-width: 3px;
}

div#copias {
    display: grid;
}

.ant-row.ant-form-item.rankingField {
    display: inline-flex;
    padding-top: 10px;
}

label {
    margin: unset;
}

.titForm {
    text-align: center;
    font-size: 25px;
    font-family: heavitas;
    color: #8E171B;
}

div#alumna {
    display: grid;
}

label.ant-radio-wrapper {
    padding-top: 10px;
}

.ant-row.ant-form-item.rankingField.paralelo {
    margin-top: -20px;
}

.ant-row.ant-form-item.certificado {
    padding: unset;
    margin: unset;
    padding-left: 15px;
}

.certificadoConducta {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    vertical-align: middle;
}

.certificado label.ant-checkbox-wrapper {
    padding: unset;
}


.certificado span.ant-checkbox-inner {
    width: 30px;
    height: 30px;
}

.ant-col.colDate.ant-col-xs-24.ant-col-md-24 {
    text-align: right;
}

.ant-col.colBtn.ant-col-xs-24.ant-col-md-24 {
    text-align: center;
    padding-top: 30px;
}

.nameBox span {
    padding-top: 10px;
}


.ant-row.ant-form-item.dateInput {
    display: inline-flex;
    padding-top: 25px;
}

.ant-row.ant-form-item.cursoTransc {
    padding-top: 25px;
}

#alumna label.ant-checkbox-wrapper {
    width: 600px;
}

label.ant-checkbox-wrapper {
    font-size: 18px !important;
}

input#correo {
    border: unset;
    border-bottom: solid;
    border-radius: unset;
    border-width: 2px;
    width: 350px;
    margin: auto;
}

.firma {
    text-align: center;
    padding-top: 60px;
}

.correo {
    margin: -10px;
    text-align: center;
    font-weight: bold;
    font-size: 20px;
}

.email.equipo {
    text-align: inherit;
}



.eventos.boletin {
    border-style: solid;
    border-width: 0.5px;
    border-color: #d5d5d5;
}

img.idPhoto {
    width: 100%;
}

.photoBox {
    max-width: 120px;
}

.ant-row.letterBox {
    padding: 30px 50px;
    background: #fff9ec;
    border-left: solid;
    border-width: 0.5px;
    border-color: #d5d5d5;
}

.ant-row.newsBox {
    padding: 30px 60px;
    border-left-style: solid;
    border-width: 0.5px;
    border-color: #d5d5d5;
}

.titBoletin.Left {
    font-family: heavitas;
}

.palabras {
    text-align: justify;
}

.slideHomeRwd {
    display: none;
}

.bigTitHeader.Historia {
    text-align: center;
}

i.anticon.anticon-close.iconVideo.clubBtn {
    color: black;
}

.modalClub .ant-modal-body {
    height: calc(80vh);
    background: white;
}
.calendarWrapperGoogle {
    text-align: center;
}
.calendarWrapperGoogle iframe {
    width: 90%;
    max-width: 1024px;
    margin: auto;
}
strong {

    font-size: 20px;

}

.ant-card.ant-card-bordered {
    padding-top: 15px;
}

.ant-card-bordered {
    padding-top: 10px;
    border: unset !important;
    border-bottom: 2px solid #e8e8e8 !important;
    background: unset !important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 90px #f7f7f7 inset !important;

}

p.shortPar.espaceTop.proEd {
    line-height: 24px;
}

.ant-row-flex.normalRow.copece {
    padding-top: 30px;
    padding-bottom: 20px;
}

.logosBox.copece {
    margin: auto;
    max-width: 500px;
    margin: auto;
}

.ant-row.rowNewH {
    margin-top: -70px;
    padding-bottom: 30px;
}

.history.hpadding.text {
    font-size: 20px;
}

.ant-col.peLeft.history.ant-col-xs-24.ant-col-md-12.foto {
    padding-left: unset;
}

.ant-col.hcol.ant-col-xs-24.ant-col-md-12 {
    padding-top: 60px;
    font-size: 20px;
    padding-right: 100px;
    text-align: justify;
}


span.lightBtn {
    font-size: 15px;
}


.peopleBox.dece {
    border: unset;
}


.ant-row.footerTextRow {
    padding-top: 40px;
}

.ant-row.instaRow.bioCopy {
    padding-bottom: 40px;
}

.workRow {}

.listaUtilesColumn {
    background: #f0f0f0;
    padding: 40px 20px;
}

.listaUtilesDownload a {
    color: #3a3a3a;
}
.listaUtilesDownload ul {
    list-style: none;
    padding-left: 0px;
}
.listaUtilesDownload ul li {
    margin-top: 10px;
}
.listaUtilesDownload ul li img {
    width: 20px;
    vertical-align: sub;
}
/*-------RWD-----------------*/


@media(max-width: 1500px) {
    .textBox.light.center.cop.proEd {
        padding: 50px;
    }

    .bigTitHeader.type2.proEd {
        padding-bottom: 15px;
    }

    .bigTitHeader.type2.proEd {
        font-size: 30px;
    }
}

@media(max-width: 1400px) {

    .textBox.light.center.cop.acad.ibBox {
        padding: 25px;
    }
}

@media only screen and (max-width: 1250px) {
    p.shortPar.espaceTop.proEd {
        font-size: 18px;
    }

    .textBox.light.center.cop.proEd {
        padding: 30px;
    }
}


@media only screen and (max-width: 1150px) {
    .long-jump {
        display: block;
    }
}


@media only screen and (max-width: 1370px) {
    .textBox.light.center.cop.shortHeader.copece {
        padding: 50px 50px;
    }

    .textBox.light.center.cop.shortHeader.forma {
        padding: 30px 50px;
    }

    .bigTitHeader.copece {
        font-size: 40px;
    }
}


@media(max-width:904px) {

    p.shortPar.espaceTop {
        font-size: 18px;
    }
}


@media(max-width:900px) {
    .textBox.light.center.cop.proEd {
        padding: 20px;
    }

    p.shortPar.espaceTop.proEd {
        font-size: 18px;
        line-height: 22px;
    }
}

@media(min-width:890px) {
    .iconContainer.videoHome {
        padding: 20px 100px 41px;
        display: flex;
        text-align: center;
        margin-top: -230px;
        background: #ffffff61;
        height: 230px;
    }

}


@media(max-width:1348px) {
    .bigTitHeader.acd {
        font-size: 35px;
    }

}


@media(max-width:1300px) {

    .bigTitHeader.acad.shortTit.ped {
        font-size: 30px;
    }
}

@media(min-width:890px) and (max-width:1300px) {



    .iconContainer.videoHome {
        height: 180px;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
    }
}

@media(max-width:1189px) {
    .textBox {
        color: white;
        padding: 50px 30px;
    }

    .bigTitHeader.ib {
        font-size: 45px;
        line-height: 45px;
    }

    .bigTitHeader.size1.padres {
        font-size: 30px;
    }


}



@media(max-width:1203px) {

    .textBox.light.center.cop.shortHeader.forma {
        height: 300px;
        padding: 30px 30px;
    }
}

@media(max-width:1180px) {
    .textBox.light.center.cop.acad {
        padding: 30px 30px;
    }
}

@media(max-width:1160px) {
    .textBox.light.center.cop.shortHeader.equipo {
        padding: 35px 30px;
    }
}

@media(max-width:1150px) {

    .fqmTitle {
        font-size: 90px;
    }

    .fqmTitle {
        font-size: 70px;
        line-height: 75px;
        text-align: center;
    }
}

@media(max-width:1115px) {

    p.shortPar.espaceTop.calidad {
        line-height: unset;
    }

    .textBox {
        color: white;
        padding: 50px 30px;
    }

    iframe.videoYoutube {
        justify-content: center;
        width: 750px;
        height: 400px;
        margin: auto;
    }
}

@media (max-width: 1060px) {

    .logosCopeceBox.copece {
        max-width: 360px;
    }

    .bigTitHeader {
        font-size: 50px;
    }

    .bigTitHeader.short {
        font-size: 40px;
    }

    p.shortPar.espaceTop.ib {
        font-size: 18px;
    }


}

@media(max-width:1150px) {

    .ant-row-flex.normalRow.copece {
        padding: 40px 40px;
    }
}

@media(max-width:1110px) {


    .bigTitHeader.acad.shortTit.pBottom {
        padding: unset;
    }
}


@media(max-width:1100px) {


    .bigTitHeader.acad.shortTit.extra {
        font-size: 30px;
    }

    .boldTextFqm {
        padding-left: 30px;
    }

    .ant-row-flex.rowCalidad {
        align-items: center;
    }

    .ant-col.hcol.ant-col-xs-24.ant-col-md-12 {
        padding-right: 30px;
    }

    .peRight.history.ant-col-xs-24.ant-col-md-12 {
        padding: 15px 35px;

    }

    .peLeft.history.ant-col-xs-24.ant-col-md-12 {
        padding: 10px 45px;
    }

    .historyBox {
        padding: 50px 35px;
        margin-top: -70px;
    }

    .textCopece.ant-col-xs-24.ant-col-md-16 {
        padding-left: 60px;
    }

}

@media(min-width:1100px) {
    .ant-row-flex.servicesRow.icons {
        padding-bottom: 70px;
    }
}

@media(max-width:1010px) {


    .big {
        padding-top: 20px;
    }

    .subjectBox.ant-col-xs-24.ant-col-md-8 {
        padding-left: 20px;
        padding-right: 20px;
    }

    .shortPar.espaceTop.acad {
        font-size: 18px;
        line-height: 22px;
    }

}

@media(max-width:994px) {

    .ant-row-flex.rowTextIp {
        padding: 5px 50px 60px 50px;
    }

    .ant-row-flex.rowSubjects {
        padding-left: 50px;
        padding-right: 50px;
    }

    p.shortPar.espaceTop.forma {
        line-height: 22px;
    }

}

@media(max-width:935px) {

    .linkTorremar {
        right: 30px;
    }

    .linkPreescolar {
        right: 190px;
    }

    .boldTextFqm {
        padding-left: 80px;
    }

    p.shortPar.colorWhite {
        font-size: 18px;
    }

    .historyBox {
        margin-top: unset;
    }

    .ant-row-flex.servicesRow.transp {
        padding: 5px 30px;
    }

    .ant-row-flex.servicesRow.transp.icons {
        padding-top: 25px;
        padding-bottom: 50px;
    }

    .leftText.history {
        font-size: 40px;
    }

}

@media(max-width:900px) {
    .textBox.light.center.cop.shortHeader.calidad {
        padding: 20px;
    }

    p.shortPar.espaceTop.calidad {
        font-size: 18px;
        padding: unset;
    }
}

@media(max-width:915px) {

    .ant-row-flex.menuBody {
        padding: 30px 30px;
    }

    .ant-row-flex.rowMenuModal {
        padding: 30px 30px;
    }

    .ant-row.footerMenu {
        padding-left: 40px;
        padding-right: 60px;
    }

    img.imgMap {
        width: 80%;
    }

    .ibBoxImg {
        max-width: 60px;
    }

}

@media(max-width:890px) {
    .ant-row.rowNewH {
        margin-top: unset;
        padding-bottom: 30px;
    }
}

@media(max-width:875px) {
    img.iconImg.menu {
        padding: 5px;
        max-width: 40px;
    }
}

@media(max-width:860px) {
    .fqmTitle {
        font-size: 60px;
    }

    h3.titleRow {
        font-size: 15px;
    }

}

@media(max-width:844px) {

    .textBox.light.center.cop.shortHeader.ib {
        height: 400px;
    }

    .colActividadesTit.ant-col-xs-24.ant-col-md-24 {
        padding-left: 30px;
    }

    .calendarHeader {
        font-size: 15px;
    }

    .colDescription.ant-col-xs-20.ant-col-md-18 {
        padding-left: 20px;
    }

    .textBox.light.center.cop.shortHeader {
        height: 300px;
        padding-top: 30px;
    }


    .footerTextCol.left.ant-col-md-12 {
        text-align: center;
        padding: unset;
    }

    .footerTextCol.right.ant-col-md-12 {
        text-align: center;
        padding: unset;
    }

    .instaBox {
        padding-right: 15px;
    }

    .copy.center {
        padding-bottom: 30px;
    }

    .colVid.ant-col-xs-24.ant-col-md-12 {
        padding-top: 20px;
    }
}

@media(max-width:836px) {
    .bigTitHeader.type2.proEd {
        font-size: 22px;
    }
}

@media(max-width:780px) {
    .ant-row-flex.menuBody {
        padding: 30px 50px;
    }

    .nav.flex-column.submenu {
        padding-left: 20px;
    }

    .nav-link.main.active.show:after {
        padding-left: 30px;
    }

    .nav-pills .nav-link.active,
    .nav-pills .show>.nav-link {

        font-size: 20px;
    }

    img.imgMap {
        max-width: 650px;
    }

    .mapBox {
        width: 100%;
    }
}

@media(max-width:890px) {


    #videoBg {
        position: absolute;
    }
}


@media(max-width:870px) {
    .copece .shortPar {
        font-size: 18px;
    }

    .copece .bigTitHeader {
        font-size: 30px;
    }

}



@media(max-width:767px) {
    .listaUtilesDownload ul {
        padding-left: 40px;
    }
    iframe.videoYoutube {
        justify-content: center;
        width: 500px;
    }


    .banner-caption {
        font-size: 12px;
        padding: 10px 30px;
    }


    .ant-row.row-news {
        display: block;
    }

    p.shortPar.copeceTxt {
        padding: 10px 20px;
    }

    .logosCopeceBox.copece {
        margin: auto;
        padding: 20px 0;
    }

    .linksColegios {
        display: none;
    }

    .ant-col.bgColorHeader.ant-col-xs-24.ant-col-md-12 {
        padding: 30px;
    }

    .ant-col.hcol.ant-col-xs-24.ant-col-md-12 {
        padding-left: 30px;
    }

    .textBox.light.center.cop.shortHeader.torneo {
        width: 100%;
        margin: auto;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100px;
        padding: unset !important;
    }

    .parrBox.counse.last {
        padding-bottom: 20px;
    }

    .parrBox.counse {
        padding: 40px 30px;
    }

    .parrBox.counse.right {
        padding-bottom: 30px;
    }

    .parrBox.counse.right {
        padding-top: 15px;
    }

    .parrBox.counse.left {
        padding-left: 30px;
    }

    .parrBox.counse {
        padding-bottom: unset;
    }

    .parrBox.counse.right {
        padding-right: 30px;
    }


    .textBox.light.center.cop.shortHeader.ib {
        height: 300px;
    }

    .shortPar.espaceTop.acad {
        font-size: 20px;
        line-height: 26px;
    }

    .textBox.light.center.cop.acad {
        height: 350px;

    }


    p.shortPar.espaceTop.forma {
        line-height: 26px;
    }

    ul.lista.periodismo {
        padding-left: unset;
    }


    .ant-row-flex.normalRow.copece {
        padding: 10px;
    }

    .ant-row-flex.copeceRow {
        padding: 10px 30px 30px;
    }

    .leftText.history.cop {
        text-align: center;
        padding-bottom: 20px;
    }

    .textCopece.ant-col-xs-24.ant-col-md-16 {
        padding-left: unset;
        text-align: justify;
    }

    .imageCol.ant-col-xs-24.ant-col-md-8.ant-col-lg-5 {
        padding-bottom: 20px;
    }

    .servicesRow {
        padding: 20px 30px;
    }

    .largeText {
        padding-top: 30px;
        text-align: justify;
    }

    .imageBox {
        text-align: center;
        margin: auto;
    }

    .pName.ant-col-xs-8.ant-col-md-8 {
        padding-right: 20px;
    }

    .dias.ant-col-xs-10.ant-col-md-10 {
        padding-right: 20px;
    }

    .actCol.ant-col-xs-24.ant-col-md-12 {
        padding-left: 30px;
    }

    .horas.ant-col-xs-6.ant-col-md-6 {
        padding-right: 20px;
    }

    .actCol.tit.ant-col-xs-24.ant-col-md-24 {
        padding-left: 30px;
    }

    .ant-row-flex.capellaniaTab {
        padding-left: 30px;
    }

    .searchBox {
        display: none;
    }

    .alignRightCol.pbottom.ant-col-xs-24.ant-col-md-12 {
        padding-right: unset;
    }

    .alignLeftCol.pbottom.ant-col-xs-24.ant-col-md-12 {
        padding-right: unset;
    }



    .fqmBar {
        background: #8c8b8b;
        height: 20px;
        margin: auto;
    }

    .espaceTop {
        padding-top: 20px;
    }

    .boldTextFqm {
        padding-top: 30px;
    }

    .textFqm {
        text-align: justify;
    }

    .colCalidad.ant-col-xs-24.ant-col-md-18 {
        padding-left: unset;
    }


    .subjectImgBox {
        max-width: 130px;
    }

    .iconContainer {
        padding: 20px 30px 40px;
    }

    .iconTit {
        max-width: 100px;
        margin: auto;
        padding-top: unset;
    }



    .space {
        height: 5px;
        margin-bottom: unset;
        margin-right: 25px;
    }

    .circleBtnCol {
        height: 30px;
        width: unset;
        padding: 8%;
    }

    .bgColor {
        background: #F9FBFB;
        height: 85%;
        width: 100%;
        position: absolute;
    }

    .newBodyText {
        padding-top: 20px;
    }


    img.coverImg {
        width: 100%;
    }

    a.readMoreBtn {
        padding-bottom: 20px;
    }


    div#v-news-tabContent {
        padding-left: unset;
        justify-content: center;
        flex-direction: row;
        display: contents;

    }

    .nav.nav-news {
        display: contents;
    }

    .navbar-brand {
        padding-right: 100px;
    }

    .ant-row-flex.ant-row-flex-center.rowMenu {
        justify-content: flex-end;
    }

    .contentTemplate {
        padding-left: 30px;
        padding-right: 30px;
    }

    .ant-row-flex.rowGalContent {
        padding-right: unset;
        padding-left: unset;
    }

    .frontBox {
        padding-right: 100px;
        padding-left: unset;
    }

    .backBox {
        max-width: 610px;
        padding-left: 100px;
    }

    div#galleryPhotos {
        padding-bottom: 20px;
    }

    .workRow {
        display: flex;
        flex-direction: column-reverse;
    }

    .galleryContent {
        padding-top: 30px;
        padding-bottom: 30px;
    }

    .ant-row-flex.ant-row-flex-center.formRow.second {
        flex-direction: column-reverse;
    }

    .alignRight {
        text-align: center;
    }

    .alignLeft {
        text-align: center;
    }

    .second {
        padding-top: 20px;
    }

    .peRight.ant-col-xs-24.ant-col-md-12 {
        padding-top: unset;
        min-height: unset;
        padding: 0px 30px 30px 30px;
    }

    .peLeft.ant-col-xs-24.ant-col-md-12 {
        background: #C2C3C9;
        color: white;
        padding: 30px 30px 0px 30px;

    }

    .academicoCol.ant-col-xs-24.ant-col-md-12 {
        padding-top: 40px;
    }

    .rightText {
        text-align: left;
    }

    .peLeft.history.ant-col-xs-24.ant-col-md-12 {
        background: #c0b69c;
    }

    .peLeft.history.ant-col-xs-24.ant-col-md-12 {
        padding: 25px 30px;
    }

    .peRight.history.ant-col-xs-24.ant-col-md-12 {
        padding-top: unset;
        padding-bottom: 30px;
    }

    .normalRow.history {
        padding: 30px;
    }

    .historyBox {
        padding: 25px;
        margin: auto;
    }

    .boldTextFqm {
        padding-left: unset;
    }

    .ant-col.colCalidad.ant-col-xs-24.ant-col-md-6 {
        text-align: center;
    }

    .colLine {
        border-right: unset;
    }

    .colComponent {
        padding: unset !important;
    }

    .alignRightCol.pbottom.ant-col-xs-24.ant-col-md-12 {
        text-align: center;
    }

    .alignLeftCol.ant-col-xs-24.ant-col-md-12 {
        text-align: center;
    }

    .ant-row-flex.directoraRow {
        flex-direction: column-reverse;
    }

    .alignRightCol.ant-col-xs-24.ant-col-md-12 {
        padding-right: unset;
        justify-content: center;
        align-items: center;
        display: flex;
        text-align: center;
    }

    .alignLeftCol.dir.ant-col-xs-24.ant-col-md-12 {
        text-align: center;
        justify-content: center;
        margin: auto;
        display: flex;
        padding: unset;
    }

    .deltaFoot.ant-col-xs-24.ant-col-md-12 {
        padding-top: 30px;
    }



    .headerInstaCol.ant-col-xs-18.ant-col-md-18 {
        text-align: left;
        padding-left: 30px;
    }

    .ant-row.instaRow {
        padding: 10px 30px;
        align-items: center;
        text-align: center;
    }

    .ant-row.instaRow.Header {
        padding-top: 30px;
    }

    .ant-row-flex.videoRow {
        padding-left: unset;
        padding-right: unset;
    }

    .ant-col-xs-24.ant-col-md-14.vid {
        min-height: 455px;
    }

    .darkCol.ant-col-xs-24.ant-col-md-12 {
        padding-bottom: 40px;
    }

    a.menuBtn {

        top: 80px;
    }

    .linkPreescolar {
        left: 0
    }

    .modalPopUp .ant-modal-content {
        background: white !important;
    }


    .linkTorremar {
        left: 20px;
    }

    .textBox.light.center.cop {
        height: 100%;
    }

    .ant-row-flex.rowGalContent {
        display: flex;
        flex-direction: column-reverse;
        position: relative;
    }

    .iconCol.ant-col-md-3 {
        position: absolute;
        bottom: 60px;
    }

    .ant-row-flex.rowGalContent {
        padding-left: 30px;
    }

    .formBox {
        padding: 30px 30px 60px;
    }

    div#galleryPhotos {
        padding-top: 50px;
        height: unset;
    }

    img.imageGalleryFront {
        padding-bottom: unset;
        width: 100%;
    }

    img.imageGalleryBack {
        width: 100%;
    }

    video.videoSlideHome {
        max-width: 600px;
    }

    .slideHomeRwd {
        display: block;
        background-image: url(/delta/static/media/glorieta.aa53f63d.jpg);
        background-position: left;
        background-size: cover;
        min-height: calc(80vh - 200px);

    }

    .slideHome {
        display: none;
    }

    span.miniTit {
        display: none;
    }

    .ant-col.colPostAside.ant-col-xs-24.ant-col-md-6 {
        padding-left: 30px;
        padding-top: unset;
    }

    .palabras {
        padding-top: 20px;
    }

    .hpadding {
        padding-top: unset;
        padding-bottom: 20px;
    }

    .ant-col.peLeft.history.ant-col-xs-24.ant-col-md-12 {
        background: unset;
        color: #8C8B8B;
        padding-bottom: 10px;
    }

    .ant-col.peRight.history.ant-col-xs-24.ant-col-md-12 {
        padding-top: 30px;
    }

    .ant-row.rowNewH {
        display: flex;
        flex-direction: column-reverse;
    }

    .ant-col.hcol.ant-col-xs-24.ant-col-md-12 {
        padding-top: unset;
    }

    .ant-col.eqCol.ant-col-xs-24.ant-col-md-12 {
        padding-left: 15px;
        text-align: left;
    }

    .dirBox {
        float: unset;
        margin: auto;
        padding: unset;
        max-width: 130px;
    }

    .ant-col.eqCol.ant-col-xs-24.ant-col-md-12 {
        text-align: center;
        padding: unset;
        padding-top: 15px;
    }

}

/*end bootstrap query*/
@media(max-width:650px) {
    .textBox.light.center.cop.acad {
        padding: 30px 30px;
    }
}

@media(max-width:600px) {

    .modalVidIframe .ant-modal-content {
        background: none;
    }

    .modalVidIframe .ant-modal-body {
        background: none;
    }

    iframe.videoYoutube {
        justify-content: center;
        max-width: 400px;
    }

    .bigTitHeader.type2 {
        font-size: 35px;
        line-height: 45px;
    }

    .fqmTitle {
        font-size: 80px;
        line-height: 80px;
    }

    .fqmBar {
        width: 60%;
    }

    .ant-row-flex.rowCalidad {
        padding: 10px 30px 60px 30px;
    }

    .darkRowComp {
        padding: 60px 30px;
    }

    .calidadBox {
        padding: 40px 30px;
    }

    .bm-burger-button {
        left: 35px;
    }

    span.miniTit {
        left: 70px;
    }

    .bigTitHeader {
        font-size: 45px;
    }

    .header2 {
        height: 250px;
    }

    .detailCalidad2Div {
        padding-left: 30px;
        padding-right: 30px;
    }

    .detailCalidad2 {
        width: 100%;
    }

    .detailCalidad {
        padding: 60px 30px 60px 30px;
    }

    .valueCol.light.ant-col-xs-24.ant-col-md-12 {
        padding: 30px 30px;
    }

    .valueCol.dark.ant-col-xs-24.ant-col-md-12 {
        padding: 30px 30px;
    }

    .justifyText.value {
        text-align: left;
        padding-bottom: 10px;
    }

    .ant-row.coreValuesRow {
        padding: 30px 30px;
    }

    .ant-row.footerMenu {
        display: none;
    }

    .ant-row-flex.feedRow {
        padding: 15px 30px 60px 30px;
    }

    img.homeIcon {
        max-width: 100px;
    }

}

@media(max-width:562px) {

    .bigTitHeader.acad.shortTit.pBottom.equipoPage {
        text-align: center;
    }

    .headerText.eequipo {
        padding: 40px 30px;
    }

    .imgPopBox {
        max-width: 150px;
    }

    .shortPar.espaceTop.capellania {
        padding-top: unset;
    }

    .modalPopUp .ant-modal-body {
        padding: 40px !important;
        overflow: unset;
    }

    p.shortPar.espaceTop.forma {
        padding-top: unset;
    }
}

@media(max-width:548px) {
    .textBox.light.center.cop.shortHeader.ib {
        height: 400px;
    }
}

@media(max-width:530px) {


    form.ant-form.ant-form-horizontal.transcriptForm {
        padding: 40px 30px;
    }

    input#correo {
        width: 100%;
        margin: auto;
    }

    .firma {
        text-align: center;
        padding-top: 20px;
    }


    .bigTitHeader.bar {
        font-size: 30px;
        margin: auto;
    }

    .headerMask {
        background: #C2C3C9 !important;
    }

    .bigIcon {
        max-width: 30px;
    }


    .ant-row-flex.rowCalidad {
        padding: 10px 30px 60px 30px;
    }

    .textAtencion {
        text-align: center;
        padding-top: 10px;
        padding-bottom: 40px;
    }

    p.shortPar {
        text-align: left;
    }

    a.menuBtn {
        left: 30px;
    }

    .navbar-brand {
        padding-right: 30px;
    }

    .slideContent {
        padding-left: 30px;
    }

    img.homeIcon {
        padding: 10px;
    }

    .newSlider {
        background: #F9FBFB;
    }

    .ant-row-flex.rowGalContent {
        padding-right: 30px;
        padding-left: 30px;
    }

    .bigTitHeader.acad.single {
        text-align: center;
    }

    img.iconImg {
        max-width: 50px;
    }

    .normalRow {
        padding-left: 30px;
        padding-right: 30px;
    }

    .workTextCol.ant-col-xs-24.ant-col-md-12 {
        padding-left: 30px;
    }

    .deltaFoot.ant-col-xs-24.ant-col-md-12 {
        padding-left: 30px;
    }

    .textBox.light.center.cop {
        height: 100%;
    }

    .textBox.light.center.cop {
        padding-top: 35px;
    }

    .bigTitHeader {
        text-align: left;
        margin: unset;
    }

    .textBox.light.center.cop {
        padding-top: 35px;
        padding-bottom: 35px;
    }

    .boldTextFqm {
        font-size: 24px;
        line-height: 30px;
    }

    p.shortPar.espaceTop.forma {
        line-height: 26px;
        font-size: 18px;
    }

    p.shortPar.espaceTop.coun {
        font-size: 18px;
        line-height: 26px;
    }
}



@media(max-width:550px) {

    .bigTitHeader.dece {
        font-size: 40px;
    }

    .menuBody {
        display: none !important;
    }

    .ant-row-flex.responsiveMenu {
        display: block;
    }

    .titleBd.ib {
        font-size: 20px;
    }

    .titleLt.ib {
        font-size: 16px;
    }

    .ant-row-flex.rowSubjects {
        padding-left: 30px;
        padding-right: 30px;
    }

    a.nav-link.py-0 {
        padding-left: 20px;
    }


    .bigTitHeader {
        font-size: 28px;
    }



    .linkLogo {
        width: 100px;
        background: #8E171B;
    }

    img.imgLogoOut {
        max-width: 110px;
        left: 0;
        position: absolute;
    }

    .parrBox {
        font-size: 22px;
    }


    img.imageGalleryFront {
        height: 250px;
    }

    img.imageGalleryBack {
        height: 250px;
    }

}

@media(min-width:551px) {
    .menuBody {
        display: flex;
    }

    .ant-row-flex.responsiveMenu {
        display: none;
    }

    .textServiceCol.ant-col-xs-24.ant-col-md-16.ant-col-lg-19 {
        padding-left: unset;
    }


}

@media(max-width:500px) {

    .headerBackground.big {
        height: 300px;
    }

    .headerBackground {
        height: 250px;
    }

    .textBox.light.center.cop.acad.padres {
        height: 350px;
    }

    .parrBox.counse.left {
        font-size: 18px;
    }

    .parrBox.counse.right {
        font-size: 18px;
    }

    .parrBox.counse.last {
        font-size: 18px;
    }

    .textBox.light.center.cop.acad.coun {
        height: 400px;
    }

    .textBox.light.center.cop.acad.ibBox {
        height: 400PX;
    }

    .textBox.light.center.cop.acad {
        HEIGHT: 500PX;
    }

    .textBox.light.center.cop.shortHeader.forma {
        height: 400px;
    }

    .textBox.light.center.cop.shortHeader.equipo {
        HEIGHT: 380PX;
    }

    .readMoreBtn.news.home {
        padding-bottom: unset;
    }

    .bigTitHeader.size1 {
        font-size: 20px;
    }

    .ant-row-flex.galleryGridRow.pb.video {
        padding-bottom: 40px;
    }

    .titBolet {
        display: none;
    }

    .calendarHeader.boletin {
        height: 70px;
    }

    .modalVideo .ant-modal-body {
        background: black;
        display: flex;
        max-height: 450px;
        margin: auto;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .ant-row-flex.rowGalContent {
        display: flex;
        flex-direction: column-reverse;
    }

    .modalVideo .ant-modal-content {
        margin: auto;
        position: absolute;
        top: 13.3%;
    }

    .ant-modal-confirm-content {
        padding: unset;
    }

    .ant-modal-content {
        background: unset;
    }

    .cargo {
        font-size: 19px;
    }


    .ant-modal.ant-modal-confirm.ant-modal-confirm-info {
        max-height: 300px;
    }

    .ant-row-flex.topRowEquipo {
        padding: 20px 60px 40px;
    }


    .iconTit {
        font-size: 10px;
    }

    .iconContainer {
        padding: 20px 10px 20px;
    }

    .iconTit {
        max-width: 80px;
        text-align: center;
        margin: auto;
    }

    .ant-col-xs-24.ant-col-md-14.vid {
        min-height: 294px;

    }

    .imageGalCol.ant-col-xs-24.ant-col-md-14 {
        min-height: 250px;
    }

    .valueTit.light.big {
        font-size: 21px;
        line-height: 20px;
    }

    .ant-row.coreValuesRow {
        padding-bottom: 60px;
    }

    .bigTitHeader.tracking-in-expand-fwd {
        font-size: 26px;
    }

    .drCol.ant-col-md-4 {
        display: none;
    }

    video.videoSlideHome {
        max-width: 410px;
    }



    .ant-row-flex.menuNews {
        padding-bottom: 40px;
    }

    .bigTitHeader.tooLong.extra {
        font-size: 20px;
    }

    .long-jump {
        display: none;
    }

    img.subjectImg.ib {
        padding: unset;
    }

    .ant-row-flex.galleryGridRow {
        padding: 0px 30px;
    }

    .ant-row-flex.capellaniaTab {
        padding-right: 30px;
    }

    .bm-burger-button {
        top: 25px;
        height: 20px;
        width: 25px;

    }

    .slideHomeRwd {
        background-position-x: -160px;
    }

    .textBox.light.center.cop.acad.deceBox {
        height: 350px;
    }

}

@media (max-width:454px) {
    video.videoSlideHome {
        width: 120%;
    }

    .textBox.light.center.cop.shortHeader {
        height: 450px;
    }
}

@media (max-width:430px) {
    .linksColegios {
        display: none;
    }



    a.menuBtn {
        left: 30px;
        top: 8%;
    }

    iframe.videoYoutube {
        justify-content: center;
        max-width: 350px;
        height: 350px;
    }

}


@media (max-width:400px) {

    .textBox.light.center.cop.acad.deceBox {
        height: 400px;
    }

    span.miniTit2 {

        right: 50px;

    }

    .bigTitHeader.acad.shortTit {
        font-size: 30px;
    }
}


@media (max-width:380px) {

    .textBox.light.center.cop.acad.ibBox {
        height: 450PX;
    }
}


/*------------------------------------------*/
/*CALIDAD*/

.slideCalidad {
    width: 100%;
    position: relative;
    background: url(/delta/static/media/banner-16.2e53c271.jpg);
    background-position: center;
    background-size: cover;
    min-height: calc(90vh - 115px);
    color: white;
    display: flex;
    vertical-align: middle;
    align-items: center;
    justify-content: center;
}

.phraseCalidad {
    font-family: heavitas;
    font-size: 75px;
    font-weight: 500;
    line-height: 25px;
    display: flex;
    vertical-align: middle;
    width: 100%;
    text-align: center;
    justify-content: center;
}

.detailCalidadDiv {
    display: flex;
    padding-left: 34%;
}

.detailCalidad img {
    width: 25px;
    height: 25px;
}

.img2 {
    float: right;
}

.img1 {
    position: relative;
    margin-top: -2%;
}

.detailCenterCalidad {
    color: white;
}

.detailCalidad3 {
    display: flex;
    width: 100%;
    padding-left: 5%;
    vertical-align: middle;
    align-items: center;
    justify-content: center;
}

.title {
    width: 100%;
    display: flex;
    padding: 5%;
    min-width: 100px;
}

.title p {
    font-family: heavitas;
    display: flex;
    font-size: 850%;
    font-weight: 600;
    width: 320px;
    border-style: solid;
    border-color: #D8D6D6;
    border-top: 0px;
    border-left: 0px;
    border-right: 0px;
    border-bottom-width: 25px;
}

@media screen and (max-width: 699px) {
    .content-detailCalidad3-hidden {
        padding-top: 50px !important;
        padding-bottom: 60px !important;
    }

    .content-detailCalidad3-hidden p {
        margin-bottom: 0px;
    }

    .title-hidden p {
        display: flex;
        font-family: heavitas;
        padding: 2%;
        font-size: 65px;
        font-weight: 600;
        border: 0px;
        vertical-align: middle;
        width: 100%;
        text-align: center;
        justify-content: center;
    }

    .title2 {
        font-size: 100% !important;
        color: #605C5C;
        font-weight: 300;
        padding: 2%;
    }

    .detailCalidad3 {
        display: none;
    }
}

@media only screen and (min-width: 700px) {
    .detailCalidad3-hidden {
        display: none;
    }

    .title-hidden {
        display: none;
    }

    .ant-card-body {
        min-height: 195px;
    }

}

.title2 {
    width: 100%;
    font-family: heavitas !important;
    font-size: 130%;
    color: #605C5C;
    font-weight: 300;
}

.content {
    width: 100%;
    font-size: 110%;
    color: #8C8B8B;
}

.componentText {
    display: flex;
    width: 100%;
    vertical-align: middle;
    align-items: center;
    justify-content: center;
    padding-top: 1%;
}

.componentSmallBar {
    display: flex;
    width: 100%;
    vertical-align: middle;
    align-items: center;
    justify-content: center;
}

.detailCalidad4 {
    background-color: #8E171B;
    display: flex;
    color: white;
    padding-left: 100px;
    padding-right: 100px;
    padding-bottom: 5%;
    padding-top: 4%;
}

.detailCalidad4Title {
    color: #8E171B;
    font-family: heavitas;
    font-size: 35px;
}

.titleRow {
    background-color: #8E171B;
    color: white;
    font-family: heavitas;
    font-size: 20px;
}

.contentRow {
    width: 85%;
    padding-left: 20px;
}

.calidad4Title {
    display: flex;
    width: 100%;
    height: 150px;
    background-color: #8E171B;
}

.calidad4Title p {
    display: flex;
    color: white;
    font-size: 70%;
}

.smallBarCalidad {
    display: flex;
    height: 4px;
    background: #8E171B;
    width: 27px;
}

.detailCalidad4Row {
    display: flex;
    vertical-align: middle;
    align-items: center;
    justify-content: center;
    background-color: #8E171B;
    color: white;
    align-items: center;
}

.detailCalidad4RowText {
    display: flex;
    vertical-align: middle;
    align-items: center;
    justify-content: center;
    padding-top: 2% !important;
    background-color: #8E171B;
    color: white;
    font-size: 70%;
}

.smallBarCalidad2 {
    display: flex;
    right: 40%;
    height: 1px;
    background: #84414a;
    width: 25%;
}

.col1 {
    padding-left: unset !important;
}


.col3 {
    padding-right: unset !important;
}

h3.titleRow {
    text-align: center;
}




/*FIN CSS CALIDAD*/
/*------------------------------------------*/

.whiteBtn.square.left {
    width: 50px;
    height: 50px;
    padding: unset;
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 11px !important;
    font-size: 17px;
}

.bm-menu {
    padding-top: 15px !important;
}

.instaBox {
    padding-bottom: 25px;
}

a.nav-link.py-0 {
    font-size: 14px;
    padding-left: unset;
}

nav.bm-item-list {
    padding-left: 5px !important;
}

li.nav-item.last {
    padding-bottom: 20px;
}

a.rwd.nav-link.last {
    padding-bottom: 20px;
}


img.imgPopTemp {
    width: 100%;
}

.imgPopBox {
    max-width: 300px;
}

.contentTemplate {
    padding-top: 50px;
    padding-bottom: 30px;
}

.newBodyText {
    color: black;
    font-size: 17px;
    padding-top: 10px;
}

.socialIcons {
    padding-top: 12px;
}

.bloqueMailEquipo {
    margin-bottom: 30px;
    text-align: center;
    min-height: 70px;
}

.tituloEquipo {
    color: black;
    font-weight: bold;
}

.cargoEquipo {
    font-size: 13px;
    color: black
}

.mailEquipo {
    color: #8e161b;
    font-size: 13px;
}

.quieroUnirmeBox {
    margin: auto;
}
.quieroUnirmeBox .square {
    padding: 7px;
}

.quieroUnirmeBox .lightBtn {
    font-size: 18px;
    margin-left: 10px;
}
/*!
 * Hover.css (http://ianlunn.github.io/Hover/)
 * Version: 2.3.2
 * Author: Ian Lunn @IanLunn
 * Author URL: http://ianlunn.co.uk/
 * Github: https://github.com/IanLunn/Hover

 * Hover.css Copyright Ian Lunn 2017. Generated with Sass.
 */
/* 2D TRANSITIONS */
/* Grow */
.hvr-grow {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-property: transform;
  -webkit-transition-property: -webkit-transform;
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
}
.hvr-grow:hover, .hvr-grow:focus, .hvr-grow:active {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

/* Shrink */
.hvr-shrink {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-property: transform;
  -webkit-transition-property: -webkit-transform;
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
}
.hvr-shrink:hover, .hvr-shrink:focus, .hvr-shrink:active {
  -webkit-transform: scale(0.9);
  transform: scale(0.9);
}

/* Pulse */
@-webkit-keyframes hvr-pulse {
  25% {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }
  75% {
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
  }
}
@keyframes hvr-pulse {
  25% {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }
  75% {
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
  }
}
.hvr-pulse {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
}
.hvr-pulse:hover, .hvr-pulse:focus, .hvr-pulse:active {
  -webkit-animation-name: hvr-pulse;
  animation-name: hvr-pulse;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
}

/* Pulse Grow */
@-webkit-keyframes hvr-pulse-grow {
  to {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }
}
@keyframes hvr-pulse-grow {
  to {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }
}
.hvr-pulse-grow {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
}
.hvr-pulse-grow:hover, .hvr-pulse-grow:focus, .hvr-pulse-grow:active {
  -webkit-animation-name: hvr-pulse-grow;
  animation-name: hvr-pulse-grow;
  -webkit-animation-duration: 0.3s;
  animation-duration: 0.3s;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-direction: alternate;
  animation-direction: alternate;
}

/* Pulse Shrink */
@-webkit-keyframes hvr-pulse-shrink {
  to {
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
  }
}
@keyframes hvr-pulse-shrink {
  to {
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
  }
}
.hvr-pulse-shrink {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
}
.hvr-pulse-shrink:hover, .hvr-pulse-shrink:focus, .hvr-pulse-shrink:active {
  -webkit-animation-name: hvr-pulse-shrink;
  animation-name: hvr-pulse-shrink;
  -webkit-animation-duration: 0.3s;
  animation-duration: 0.3s;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-direction: alternate;
  animation-direction: alternate;
}

/* Push */
@-webkit-keyframes hvr-push {
  50% {
    -webkit-transform: scale(0.8);
    transform: scale(0.8);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
@keyframes hvr-push {
  50% {
    -webkit-transform: scale(0.8);
    transform: scale(0.8);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
.hvr-push {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
}
.hvr-push:hover, .hvr-push:focus, .hvr-push:active {
  -webkit-animation-name: hvr-push;
  animation-name: hvr-push;
  -webkit-animation-duration: 0.3s;
  animation-duration: 0.3s;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  -webkit-animation-iteration-count: 1;
  animation-iteration-count: 1;
}

/* Pop */
@-webkit-keyframes hvr-pop {
  50% {
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
  }
}
@keyframes hvr-pop {
  50% {
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
  }
}
.hvr-pop {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
}
.hvr-pop:hover, .hvr-pop:focus, .hvr-pop:active {
  -webkit-animation-name: hvr-pop;
  animation-name: hvr-pop;
  -webkit-animation-duration: 0.3s;
  animation-duration: 0.3s;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  -webkit-animation-iteration-count: 1;
  animation-iteration-count: 1;
}

/* Bounce In */
.hvr-bounce-in {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  -webkit-transition-duration: 0.5s;
  transition-duration: 0.5s;
}
.hvr-bounce-in:hover, .hvr-bounce-in:focus, .hvr-bounce-in:active {
  -webkit-transform: scale(1.2);
  transform: scale(1.2);
  -webkit-transition-timing-function: cubic-bezier(0.47, 2.02, 0.31, -0.36);
  transition-timing-function: cubic-bezier(0.47, 2.02, 0.31, -0.36);
}

/* Bounce Out */
.hvr-bounce-out {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  -webkit-transition-duration: 0.5s;
  transition-duration: 0.5s;
}
.hvr-bounce-out:hover, .hvr-bounce-out:focus, .hvr-bounce-out:active {
  -webkit-transform: scale(0.8);
  transform: scale(0.8);
  -webkit-transition-timing-function: cubic-bezier(0.47, 2.02, 0.31, -0.36);
  transition-timing-function: cubic-bezier(0.47, 2.02, 0.31, -0.36);
}

/* Rotate */
.hvr-rotate {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-property: transform;
  -webkit-transition-property: -webkit-transform;
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
}
.hvr-rotate:hover, .hvr-rotate:focus, .hvr-rotate:active {
  -webkit-transform: rotate(4deg);
  transform: rotate(4deg);
}

/* Grow Rotate */
.hvr-grow-rotate {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-property: transform;
  -webkit-transition-property: -webkit-transform;
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
}
.hvr-grow-rotate:hover, .hvr-grow-rotate:focus, .hvr-grow-rotate:active {
  -webkit-transform: scale(1.1) rotate(4deg);
  transform: scale(1.1) rotate(4deg);
}

/* Float */
.hvr-float {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-property: transform;
  -webkit-transition-property: -webkit-transform;
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
}
.hvr-float:hover, .hvr-float:focus, .hvr-float:active {
  -webkit-transform: translateY(-8px);
  transform: translateY(-8px);
}

/* Sink */
.hvr-sink {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-property: transform;
  -webkit-transition-property: -webkit-transform;
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
}
.hvr-sink:hover, .hvr-sink:focus, .hvr-sink:active {
  -webkit-transform: translateY(8px);
  transform: translateY(8px);
}

/* Bob */
@-webkit-keyframes hvr-bob {
  0% {
    -webkit-transform: translateY(-8px);
    transform: translateY(-8px);
  }
  50% {
    -webkit-transform: translateY(-4px);
    transform: translateY(-4px);
  }
  100% {
    -webkit-transform: translateY(-8px);
    transform: translateY(-8px);
  }
}
@keyframes hvr-bob {
  0% {
    -webkit-transform: translateY(-8px);
    transform: translateY(-8px);
  }
  50% {
    -webkit-transform: translateY(-4px);
    transform: translateY(-4px);
  }
  100% {
    -webkit-transform: translateY(-8px);
    transform: translateY(-8px);
  }
}
@-webkit-keyframes hvr-bob-float {
  100% {
    -webkit-transform: translateY(-8px);
    transform: translateY(-8px);
  }
}
@keyframes hvr-bob-float {
  100% {
    -webkit-transform: translateY(-8px);
    transform: translateY(-8px);
  }
}
.hvr-bob {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
}
.hvr-bob:hover, .hvr-bob:focus, .hvr-bob:active {
  -webkit-animation-name: hvr-bob-float, hvr-bob;
  animation-name: hvr-bob-float, hvr-bob;
  -webkit-animation-duration: .3s, 1.5s;
  animation-duration: .3s, 1.5s;
  -webkit-animation-delay: 0s, .3s;
  animation-delay: 0s, .3s;
  -webkit-animation-timing-function: ease-out, ease-in-out;
  animation-timing-function: ease-out, ease-in-out;
  -webkit-animation-iteration-count: 1, infinite;
  animation-iteration-count: 1, infinite;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  -webkit-animation-direction: normal, alternate;
  animation-direction: normal, alternate;
}

/* Hang */
@-webkit-keyframes hvr-hang {
  0% {
    -webkit-transform: translateY(8px);
    transform: translateY(8px);
  }
  50% {
    -webkit-transform: translateY(4px);
    transform: translateY(4px);
  }
  100% {
    -webkit-transform: translateY(8px);
    transform: translateY(8px);
  }
}
@keyframes hvr-hang {
  0% {
    -webkit-transform: translateY(8px);
    transform: translateY(8px);
  }
  50% {
    -webkit-transform: translateY(4px);
    transform: translateY(4px);
  }
  100% {
    -webkit-transform: translateY(8px);
    transform: translateY(8px);
  }
}
@-webkit-keyframes hvr-hang-sink {
  100% {
    -webkit-transform: translateY(8px);
    transform: translateY(8px);
  }
}
@keyframes hvr-hang-sink {
  100% {
    -webkit-transform: translateY(8px);
    transform: translateY(8px);
  }
}
.hvr-hang {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
}
.hvr-hang:hover, .hvr-hang:focus, .hvr-hang:active {
  -webkit-animation-name: hvr-hang-sink, hvr-hang;
  animation-name: hvr-hang-sink, hvr-hang;
  -webkit-animation-duration: .3s, 1.5s;
  animation-duration: .3s, 1.5s;
  -webkit-animation-delay: 0s, .3s;
  animation-delay: 0s, .3s;
  -webkit-animation-timing-function: ease-out, ease-in-out;
  animation-timing-function: ease-out, ease-in-out;
  -webkit-animation-iteration-count: 1, infinite;
  animation-iteration-count: 1, infinite;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  -webkit-animation-direction: normal, alternate;
  animation-direction: normal, alternate;
}

/* Skew */
.hvr-skew {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-property: transform;
  -webkit-transition-property: -webkit-transform;
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
}
.hvr-skew:hover, .hvr-skew:focus, .hvr-skew:active {
  -webkit-transform: skew(-10deg);
  transform: skew(-10deg);
}

/* Skew Forward */
.hvr-skew-forward {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-property: transform;
  -webkit-transition-property: -webkit-transform;
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
  -webkit-transform-origin: 0 100%;
  transform-origin: 0 100%;
}
.hvr-skew-forward:hover, .hvr-skew-forward:focus, .hvr-skew-forward:active {
  -webkit-transform: skew(-10deg);
  transform: skew(-10deg);
}

/* Skew Backward */
.hvr-skew-backward {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-property: transform;
  -webkit-transition-property: -webkit-transform;
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
  -webkit-transform-origin: 0 100%;
  transform-origin: 0 100%;
}
.hvr-skew-backward:hover, .hvr-skew-backward:focus, .hvr-skew-backward:active {
  -webkit-transform: skew(10deg);
  transform: skew(10deg);
}

/* Wobble Vertical */
@-webkit-keyframes hvr-wobble-vertical {
  16.65% {
    -webkit-transform: translateY(8px);
    transform: translateY(8px);
  }
  33.3% {
    -webkit-transform: translateY(-6px);
    transform: translateY(-6px);
  }
  49.95% {
    -webkit-transform: translateY(4px);
    transform: translateY(4px);
  }
  66.6% {
    -webkit-transform: translateY(-2px);
    transform: translateY(-2px);
  }
  83.25% {
    -webkit-transform: translateY(1px);
    transform: translateY(1px);
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}
@keyframes hvr-wobble-vertical {
  16.65% {
    -webkit-transform: translateY(8px);
    transform: translateY(8px);
  }
  33.3% {
    -webkit-transform: translateY(-6px);
    transform: translateY(-6px);
  }
  49.95% {
    -webkit-transform: translateY(4px);
    transform: translateY(4px);
  }
  66.6% {
    -webkit-transform: translateY(-2px);
    transform: translateY(-2px);
  }
  83.25% {
    -webkit-transform: translateY(1px);
    transform: translateY(1px);
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}
.hvr-wobble-vertical {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
}
.hvr-wobble-vertical:hover, .hvr-wobble-vertical:focus, .hvr-wobble-vertical:active {
  -webkit-animation-name: hvr-wobble-vertical;
  animation-name: hvr-wobble-vertical;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-timing-function: ease-in-out;
  animation-timing-function: ease-in-out;
  -webkit-animation-iteration-count: 1;
  animation-iteration-count: 1;
}

/* Wobble Horizontal */
@-webkit-keyframes hvr-wobble-horizontal {
  16.65% {
    -webkit-transform: translateX(8px);
    transform: translateX(8px);
  }
  33.3% {
    -webkit-transform: translateX(-6px);
    transform: translateX(-6px);
  }
  49.95% {
    -webkit-transform: translateX(4px);
    transform: translateX(4px);
  }
  66.6% {
    -webkit-transform: translateX(-2px);
    transform: translateX(-2px);
  }
  83.25% {
    -webkit-transform: translateX(1px);
    transform: translateX(1px);
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}
@keyframes hvr-wobble-horizontal {
  16.65% {
    -webkit-transform: translateX(8px);
    transform: translateX(8px);
  }
  33.3% {
    -webkit-transform: translateX(-6px);
    transform: translateX(-6px);
  }
  49.95% {
    -webkit-transform: translateX(4px);
    transform: translateX(4px);
  }
  66.6% {
    -webkit-transform: translateX(-2px);
    transform: translateX(-2px);
  }
  83.25% {
    -webkit-transform: translateX(1px);
    transform: translateX(1px);
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}
.hvr-wobble-horizontal {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
}
.hvr-wobble-horizontal:hover, .hvr-wobble-horizontal:focus, .hvr-wobble-horizontal:active {
  -webkit-animation-name: hvr-wobble-horizontal;
  animation-name: hvr-wobble-horizontal;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-timing-function: ease-in-out;
  animation-timing-function: ease-in-out;
  -webkit-animation-iteration-count: 1;
  animation-iteration-count: 1;
}

/* Wobble To Bottom Right */
@-webkit-keyframes hvr-wobble-to-bottom-right {
  16.65% {
    -webkit-transform: translate(8px, 8px);
    transform: translate(8px, 8px);
  }
  33.3% {
    -webkit-transform: translate(-6px, -6px);
    transform: translate(-6px, -6px);
  }
  49.95% {
    -webkit-transform: translate(4px, 4px);
    transform: translate(4px, 4px);
  }
  66.6% {
    -webkit-transform: translate(-2px, -2px);
    transform: translate(-2px, -2px);
  }
  83.25% {
    -webkit-transform: translate(1px, 1px);
    transform: translate(1px, 1px);
  }
  100% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
  }
}
@keyframes hvr-wobble-to-bottom-right {
  16.65% {
    -webkit-transform: translate(8px, 8px);
    transform: translate(8px, 8px);
  }
  33.3% {
    -webkit-transform: translate(-6px, -6px);
    transform: translate(-6px, -6px);
  }
  49.95% {
    -webkit-transform: translate(4px, 4px);
    transform: translate(4px, 4px);
  }
  66.6% {
    -webkit-transform: translate(-2px, -2px);
    transform: translate(-2px, -2px);
  }
  83.25% {
    -webkit-transform: translate(1px, 1px);
    transform: translate(1px, 1px);
  }
  100% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
  }
}
.hvr-wobble-to-bottom-right {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
}
.hvr-wobble-to-bottom-right:hover, .hvr-wobble-to-bottom-right:focus, .hvr-wobble-to-bottom-right:active {
  -webkit-animation-name: hvr-wobble-to-bottom-right;
  animation-name: hvr-wobble-to-bottom-right;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-timing-function: ease-in-out;
  animation-timing-function: ease-in-out;
  -webkit-animation-iteration-count: 1;
  animation-iteration-count: 1;
}

/* Wobble To Top Right */
@-webkit-keyframes hvr-wobble-to-top-right {
  16.65% {
    -webkit-transform: translate(8px, -8px);
    transform: translate(8px, -8px);
  }
  33.3% {
    -webkit-transform: translate(-6px, 6px);
    transform: translate(-6px, 6px);
  }
  49.95% {
    -webkit-transform: translate(4px, -4px);
    transform: translate(4px, -4px);
  }
  66.6% {
    -webkit-transform: translate(-2px, 2px);
    transform: translate(-2px, 2px);
  }
  83.25% {
    -webkit-transform: translate(1px, -1px);
    transform: translate(1px, -1px);
  }
  100% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
  }
}
@keyframes hvr-wobble-to-top-right {
  16.65% {
    -webkit-transform: translate(8px, -8px);
    transform: translate(8px, -8px);
  }
  33.3% {
    -webkit-transform: translate(-6px, 6px);
    transform: translate(-6px, 6px);
  }
  49.95% {
    -webkit-transform: translate(4px, -4px);
    transform: translate(4px, -4px);
  }
  66.6% {
    -webkit-transform: translate(-2px, 2px);
    transform: translate(-2px, 2px);
  }
  83.25% {
    -webkit-transform: translate(1px, -1px);
    transform: translate(1px, -1px);
  }
  100% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
  }
}
.hvr-wobble-to-top-right {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
}
.hvr-wobble-to-top-right:hover, .hvr-wobble-to-top-right:focus, .hvr-wobble-to-top-right:active {
  -webkit-animation-name: hvr-wobble-to-top-right;
  animation-name: hvr-wobble-to-top-right;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-timing-function: ease-in-out;
  animation-timing-function: ease-in-out;
  -webkit-animation-iteration-count: 1;
  animation-iteration-count: 1;
}

/* Wobble Top */
@-webkit-keyframes hvr-wobble-top {
  16.65% {
    -webkit-transform: skew(-12deg);
    transform: skew(-12deg);
  }
  33.3% {
    -webkit-transform: skew(10deg);
    transform: skew(10deg);
  }
  49.95% {
    -webkit-transform: skew(-6deg);
    transform: skew(-6deg);
  }
  66.6% {
    -webkit-transform: skew(4deg);
    transform: skew(4deg);
  }
  83.25% {
    -webkit-transform: skew(-2deg);
    transform: skew(-2deg);
  }
  100% {
    -webkit-transform: skew(0);
    transform: skew(0);
  }
}
@keyframes hvr-wobble-top {
  16.65% {
    -webkit-transform: skew(-12deg);
    transform: skew(-12deg);
  }
  33.3% {
    -webkit-transform: skew(10deg);
    transform: skew(10deg);
  }
  49.95% {
    -webkit-transform: skew(-6deg);
    transform: skew(-6deg);
  }
  66.6% {
    -webkit-transform: skew(4deg);
    transform: skew(4deg);
  }
  83.25% {
    -webkit-transform: skew(-2deg);
    transform: skew(-2deg);
  }
  100% {
    -webkit-transform: skew(0);
    transform: skew(0);
  }
}
.hvr-wobble-top {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  -webkit-transform-origin: 0 100%;
  transform-origin: 0 100%;
}
.hvr-wobble-top:hover, .hvr-wobble-top:focus, .hvr-wobble-top:active {
  -webkit-animation-name: hvr-wobble-top;
  animation-name: hvr-wobble-top;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-timing-function: ease-in-out;
  animation-timing-function: ease-in-out;
  -webkit-animation-iteration-count: 1;
  animation-iteration-count: 1;
}

/* Wobble Bottom */
@-webkit-keyframes hvr-wobble-bottom {
  16.65% {
    -webkit-transform: skew(-12deg);
    transform: skew(-12deg);
  }
  33.3% {
    -webkit-transform: skew(10deg);
    transform: skew(10deg);
  }
  49.95% {
    -webkit-transform: skew(-6deg);
    transform: skew(-6deg);
  }
  66.6% {
    -webkit-transform: skew(4deg);
    transform: skew(4deg);
  }
  83.25% {
    -webkit-transform: skew(-2deg);
    transform: skew(-2deg);
  }
  100% {
    -webkit-transform: skew(0);
    transform: skew(0);
  }
}
@keyframes hvr-wobble-bottom {
  16.65% {
    -webkit-transform: skew(-12deg);
    transform: skew(-12deg);
  }
  33.3% {
    -webkit-transform: skew(10deg);
    transform: skew(10deg);
  }
  49.95% {
    -webkit-transform: skew(-6deg);
    transform: skew(-6deg);
  }
  66.6% {
    -webkit-transform: skew(4deg);
    transform: skew(4deg);
  }
  83.25% {
    -webkit-transform: skew(-2deg);
    transform: skew(-2deg);
  }
  100% {
    -webkit-transform: skew(0);
    transform: skew(0);
  }
}
.hvr-wobble-bottom {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  -webkit-transform-origin: 100% 0;
  transform-origin: 100% 0;
}
.hvr-wobble-bottom:hover, .hvr-wobble-bottom:focus, .hvr-wobble-bottom:active {
  -webkit-animation-name: hvr-wobble-bottom;
  animation-name: hvr-wobble-bottom;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-timing-function: ease-in-out;
  animation-timing-function: ease-in-out;
  -webkit-animation-iteration-count: 1;
  animation-iteration-count: 1;
}

/* Wobble Skew */
@-webkit-keyframes hvr-wobble-skew {
  16.65% {
    -webkit-transform: skew(-12deg);
    transform: skew(-12deg);
  }
  33.3% {
    -webkit-transform: skew(10deg);
    transform: skew(10deg);
  }
  49.95% {
    -webkit-transform: skew(-6deg);
    transform: skew(-6deg);
  }
  66.6% {
    -webkit-transform: skew(4deg);
    transform: skew(4deg);
  }
  83.25% {
    -webkit-transform: skew(-2deg);
    transform: skew(-2deg);
  }
  100% {
    -webkit-transform: skew(0);
    transform: skew(0);
  }
}
@keyframes hvr-wobble-skew {
  16.65% {
    -webkit-transform: skew(-12deg);
    transform: skew(-12deg);
  }
  33.3% {
    -webkit-transform: skew(10deg);
    transform: skew(10deg);
  }
  49.95% {
    -webkit-transform: skew(-6deg);
    transform: skew(-6deg);
  }
  66.6% {
    -webkit-transform: skew(4deg);
    transform: skew(4deg);
  }
  83.25% {
    -webkit-transform: skew(-2deg);
    transform: skew(-2deg);
  }
  100% {
    -webkit-transform: skew(0);
    transform: skew(0);
  }
}
.hvr-wobble-skew {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
}
.hvr-wobble-skew:hover, .hvr-wobble-skew:focus, .hvr-wobble-skew:active {
  -webkit-animation-name: hvr-wobble-skew;
  animation-name: hvr-wobble-skew;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-timing-function: ease-in-out;
  animation-timing-function: ease-in-out;
  -webkit-animation-iteration-count: 1;
  animation-iteration-count: 1;
}

/* Buzz */
@-webkit-keyframes hvr-buzz {
  50% {
    -webkit-transform: translateX(3px) rotate(2deg);
    transform: translateX(3px) rotate(2deg);
  }
  100% {
    -webkit-transform: translateX(-3px) rotate(-2deg);
    transform: translateX(-3px) rotate(-2deg);
  }
}
@keyframes hvr-buzz {
  50% {
    -webkit-transform: translateX(3px) rotate(2deg);
    transform: translateX(3px) rotate(2deg);
  }
  100% {
    -webkit-transform: translateX(-3px) rotate(-2deg);
    transform: translateX(-3px) rotate(-2deg);
  }
}
.hvr-buzz {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
}
.hvr-buzz:hover, .hvr-buzz:focus, .hvr-buzz:active {
  -webkit-animation-name: hvr-buzz;
  animation-name: hvr-buzz;
  -webkit-animation-duration: 0.15s;
  animation-duration: 0.15s;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
}

/* Buzz Out */
@-webkit-keyframes hvr-buzz-out {
  10% {
    -webkit-transform: translateX(3px) rotate(2deg);
    transform: translateX(3px) rotate(2deg);
  }
  20% {
    -webkit-transform: translateX(-3px) rotate(-2deg);
    transform: translateX(-3px) rotate(-2deg);
  }
  30% {
    -webkit-transform: translateX(3px) rotate(2deg);
    transform: translateX(3px) rotate(2deg);
  }
  40% {
    -webkit-transform: translateX(-3px) rotate(-2deg);
    transform: translateX(-3px) rotate(-2deg);
  }
  50% {
    -webkit-transform: translateX(2px) rotate(1deg);
    transform: translateX(2px) rotate(1deg);
  }
  60% {
    -webkit-transform: translateX(-2px) rotate(-1deg);
    transform: translateX(-2px) rotate(-1deg);
  }
  70% {
    -webkit-transform: translateX(2px) rotate(1deg);
    transform: translateX(2px) rotate(1deg);
  }
  80% {
    -webkit-transform: translateX(-2px) rotate(-1deg);
    transform: translateX(-2px) rotate(-1deg);
  }
  90% {
    -webkit-transform: translateX(1px) rotate(0);
    transform: translateX(1px) rotate(0);
  }
  100% {
    -webkit-transform: translateX(-1px) rotate(0);
    transform: translateX(-1px) rotate(0);
  }
}
@keyframes hvr-buzz-out {
  10% {
    -webkit-transform: translateX(3px) rotate(2deg);
    transform: translateX(3px) rotate(2deg);
  }
  20% {
    -webkit-transform: translateX(-3px) rotate(-2deg);
    transform: translateX(-3px) rotate(-2deg);
  }
  30% {
    -webkit-transform: translateX(3px) rotate(2deg);
    transform: translateX(3px) rotate(2deg);
  }
  40% {
    -webkit-transform: translateX(-3px) rotate(-2deg);
    transform: translateX(-3px) rotate(-2deg);
  }
  50% {
    -webkit-transform: translateX(2px) rotate(1deg);
    transform: translateX(2px) rotate(1deg);
  }
  60% {
    -webkit-transform: translateX(-2px) rotate(-1deg);
    transform: translateX(-2px) rotate(-1deg);
  }
  70% {
    -webkit-transform: translateX(2px) rotate(1deg);
    transform: translateX(2px) rotate(1deg);
  }
  80% {
    -webkit-transform: translateX(-2px) rotate(-1deg);
    transform: translateX(-2px) rotate(-1deg);
  }
  90% {
    -webkit-transform: translateX(1px) rotate(0);
    transform: translateX(1px) rotate(0);
  }
  100% {
    -webkit-transform: translateX(-1px) rotate(0);
    transform: translateX(-1px) rotate(0);
  }
}
.hvr-buzz-out {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
}
.hvr-buzz-out:hover, .hvr-buzz-out:focus, .hvr-buzz-out:active {
  -webkit-animation-name: hvr-buzz-out;
  animation-name: hvr-buzz-out;
  -webkit-animation-duration: 0.75s;
  animation-duration: 0.75s;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  -webkit-animation-iteration-count: 1;
  animation-iteration-count: 1;
}

/* Forward */
.hvr-forward {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-property: transform;
  -webkit-transition-property: -webkit-transform;
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
}
.hvr-forward:hover, .hvr-forward:focus, .hvr-forward:active {
  -webkit-transform: translateX(8px);
  transform: translateX(8px);
}

/* Backward */
.hvr-backward {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-property: transform;
  -webkit-transition-property: -webkit-transform;
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
}
.hvr-backward:hover, .hvr-backward:focus, .hvr-backward:active {
  -webkit-transform: translateX(-8px);
  transform: translateX(-8px);
}

/* BACKGROUND TRANSITIONS */
/* Fade */
.hvr-fade {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  overflow: hidden;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-property: color, background-color;
  transition-property: color, background-color;
}
.hvr-fade:hover, .hvr-fade:focus, .hvr-fade:active {
  background-color: #2098D1;
  color: white;
}

/* Back Pulse */
@-webkit-keyframes hvr-back-pulse {
  50% {
    background-color: rgba(32, 152, 209, 0.75);
  }
}
@keyframes hvr-back-pulse {
  50% {
    background-color: rgba(32, 152, 209, 0.75);
  }
}
.hvr-back-pulse {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  overflow: hidden;
  -webkit-transition-duration: 0.5s;
  transition-duration: 0.5s;
  -webkit-transition-property: color, background-color;
  transition-property: color, background-color;
}
.hvr-back-pulse:hover, .hvr-back-pulse:focus, .hvr-back-pulse:active {
  -webkit-animation-name: hvr-back-pulse;
  animation-name: hvr-back-pulse;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-delay: 0.5s;
  animation-delay: 0.5s;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  background-color: #2098D1;
  background-color: #2098d1;
  color: white;
}

/* Sweep To Right */
.hvr-sweep-to-right {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  position: relative;
  -webkit-transition-property: color;
  transition-property: color;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
}
.hvr-sweep-to-right:before {
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #2098D1;
  -webkit-transform: scaleX(0);
  transform: scaleX(0);
  -webkit-transform-origin: 0 50%;
  transform-origin: 0 50%;
  -webkit-transition-property: transform;
  -webkit-transition-property: -webkit-transform;
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
}
.hvr-sweep-to-right:hover, .hvr-sweep-to-right:focus, .hvr-sweep-to-right:active {
  color: white;
}
.hvr-sweep-to-right:hover:before, .hvr-sweep-to-right:focus:before, .hvr-sweep-to-right:active:before {
  -webkit-transform: scaleX(1);
  transform: scaleX(1);
}

/* Sweep To Left */
.hvr-sweep-to-left {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  position: relative;
  -webkit-transition-property: color;
  transition-property: color;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
}
.hvr-sweep-to-left:before {
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #2098D1;
  -webkit-transform: scaleX(0);
  transform: scaleX(0);
  -webkit-transform-origin: 100% 50%;
  transform-origin: 100% 50%;
  -webkit-transition-property: transform;
  -webkit-transition-property: -webkit-transform;
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
}
.hvr-sweep-to-left:hover, .hvr-sweep-to-left:focus, .hvr-sweep-to-left:active {
  color: white;
}
.hvr-sweep-to-left:hover:before, .hvr-sweep-to-left:focus:before, .hvr-sweep-to-left:active:before {
  -webkit-transform: scaleX(1);
  transform: scaleX(1);
}

/* Sweep To Bottom */
.hvr-sweep-to-bottom {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  position: relative;
  -webkit-transition-property: color;
  transition-property: color;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
}
.hvr-sweep-to-bottom:before {
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #2098D1;
  -webkit-transform: scaleY(0);
  transform: scaleY(0);
  -webkit-transform-origin: 50% 0;
  transform-origin: 50% 0;
  -webkit-transition-property: transform;
  -webkit-transition-property: -webkit-transform;
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
}
.hvr-sweep-to-bottom:hover, .hvr-sweep-to-bottom:focus, .hvr-sweep-to-bottom:active {
  color: white;
}
.hvr-sweep-to-bottom:hover:before, .hvr-sweep-to-bottom:focus:before, .hvr-sweep-to-bottom:active:before {
  -webkit-transform: scaleY(1);
  transform: scaleY(1);
}

/* Sweep To Top */
.hvr-sweep-to-top {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  position: relative;
  -webkit-transition-property: color;
  transition-property: color;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
}
.hvr-sweep-to-top:before {
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #2098D1;
  -webkit-transform: scaleY(0);
  transform: scaleY(0);
  -webkit-transform-origin: 50% 100%;
  transform-origin: 50% 100%;
  -webkit-transition-property: transform;
  -webkit-transition-property: -webkit-transform;
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
}
.hvr-sweep-to-top:hover, .hvr-sweep-to-top:focus, .hvr-sweep-to-top:active {
  color: white;
}
.hvr-sweep-to-top:hover:before, .hvr-sweep-to-top:focus:before, .hvr-sweep-to-top:active:before {
  -webkit-transform: scaleY(1);
  transform: scaleY(1);
}

/* Bounce To Right */
.hvr-bounce-to-right {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  position: relative;
  -webkit-transition-property: color;
  transition-property: color;
  -webkit-transition-duration: 0.5s;
  transition-duration: 0.5s;
}
.hvr-bounce-to-right:before {
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #2098D1;
  -webkit-transform: scaleX(0);
  transform: scaleX(0);
  -webkit-transform-origin: 0 50%;
  transform-origin: 0 50%;
  -webkit-transition-property: transform;
  -webkit-transition-property: -webkit-transform;
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
  -webkit-transition-duration: 0.5s;
  transition-duration: 0.5s;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
}
.hvr-bounce-to-right:hover, .hvr-bounce-to-right:focus, .hvr-bounce-to-right:active {
  color: white;
}
.hvr-bounce-to-right:hover:before, .hvr-bounce-to-right:focus:before, .hvr-bounce-to-right:active:before {
  -webkit-transform: scaleX(1);
  transform: scaleX(1);
  -webkit-transition-timing-function: cubic-bezier(0.52, 1.64, 0.37, 0.66);
  transition-timing-function: cubic-bezier(0.52, 1.64, 0.37, 0.66);
}

/* Bounce To Left */
.hvr-bounce-to-left {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  position: relative;
  -webkit-transition-property: color;
  transition-property: color;
  -webkit-transition-duration: 0.5s;
  transition-duration: 0.5s;
}
.hvr-bounce-to-left:before {
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #2098D1;
  -webkit-transform: scaleX(0);
  transform: scaleX(0);
  -webkit-transform-origin: 100% 50%;
  transform-origin: 100% 50%;
  -webkit-transition-property: transform;
  -webkit-transition-property: -webkit-transform;
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
  -webkit-transition-duration: 0.5s;
  transition-duration: 0.5s;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
}
.hvr-bounce-to-left:hover, .hvr-bounce-to-left:focus, .hvr-bounce-to-left:active {
  color: white;
}
.hvr-bounce-to-left:hover:before, .hvr-bounce-to-left:focus:before, .hvr-bounce-to-left:active:before {
  -webkit-transform: scaleX(1);
  transform: scaleX(1);
  -webkit-transition-timing-function: cubic-bezier(0.52, 1.64, 0.37, 0.66);
  transition-timing-function: cubic-bezier(0.52, 1.64, 0.37, 0.66);
}

/* Bounce To Bottom */
.hvr-bounce-to-bottom {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  position: relative;
  -webkit-transition-property: color;
  transition-property: color;
  -webkit-transition-duration: 0.5s;
  transition-duration: 0.5s;
}
.hvr-bounce-to-bottom:before {
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #2098D1;
  -webkit-transform: scaleY(0);
  transform: scaleY(0);
  -webkit-transform-origin: 50% 0;
  transform-origin: 50% 0;
  -webkit-transition-property: transform;
  -webkit-transition-property: -webkit-transform;
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
  -webkit-transition-duration: 0.5s;
  transition-duration: 0.5s;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
}
.hvr-bounce-to-bottom:hover, .hvr-bounce-to-bottom:focus, .hvr-bounce-to-bottom:active {
  color: white;
}
.hvr-bounce-to-bottom:hover:before, .hvr-bounce-to-bottom:focus:before, .hvr-bounce-to-bottom:active:before {
  -webkit-transform: scaleY(1);
  transform: scaleY(1);
  -webkit-transition-timing-function: cubic-bezier(0.52, 1.64, 0.37, 0.66);
  transition-timing-function: cubic-bezier(0.52, 1.64, 0.37, 0.66);
}

/* Bounce To Top */
.hvr-bounce-to-top {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  position: relative;
  -webkit-transition-property: color;
  transition-property: color;
  -webkit-transition-duration: 0.5s;
  transition-duration: 0.5s;
}
.hvr-bounce-to-top:before {
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #2098D1;
  -webkit-transform: scaleY(0);
  transform: scaleY(0);
  -webkit-transform-origin: 50% 100%;
  transform-origin: 50% 100%;
  -webkit-transition-property: transform;
  -webkit-transition-property: -webkit-transform;
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
  -webkit-transition-duration: 0.5s;
  transition-duration: 0.5s;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
}
.hvr-bounce-to-top:hover, .hvr-bounce-to-top:focus, .hvr-bounce-to-top:active {
  color: white;
}
.hvr-bounce-to-top:hover:before, .hvr-bounce-to-top:focus:before, .hvr-bounce-to-top:active:before {
  -webkit-transform: scaleY(1);
  transform: scaleY(1);
  -webkit-transition-timing-function: cubic-bezier(0.52, 1.64, 0.37, 0.66);
  transition-timing-function: cubic-bezier(0.52, 1.64, 0.37, 0.66);
}

/* Radial Out */
.hvr-radial-out {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  position: relative;
  overflow: hidden;
  -webkit-transition-property: color;
  transition-property: color;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
}
.hvr-radial-out:before {
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #690a08;
  border-radius: 100%;
  -webkit-transform: scale(0);
  transform: scale(0);
  -webkit-transition-property: transform;
  -webkit-transition-property: -webkit-transform;
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
}
.hvr-radial-out:hover, .hvr-radial-out:focus, .hvr-radial-out:active {
  color: white;
}
.hvr-radial-out:hover:before, .hvr-radial-out:focus:before, .hvr-radial-out:active:before {
  -webkit-transform: scale(2);
  transform: scale(2);
}

/* Radial In */
.hvr-radial-in {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  position: relative;
  overflow: hidden;
  background: #2098D1;
  -webkit-transition-property: color;
  transition-property: color;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
}
.hvr-radial-in:before {
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #e1e1e1;
  border-radius: 100%;
  -webkit-transform: scale(2);
  transform: scale(2);
  -webkit-transition-property: transform;
  -webkit-transition-property: -webkit-transform;
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
}
.hvr-radial-in:hover, .hvr-radial-in:focus, .hvr-radial-in:active {
  color: white;
}
.hvr-radial-in:hover:before, .hvr-radial-in:focus:before, .hvr-radial-in:active:before {
  -webkit-transform: scale(0);
  transform: scale(0);
}

/* Rectangle In */
.hvr-rectangle-in {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  position: relative;
  background: #2098D1;
  -webkit-transition-property: color;
  transition-property: color;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
}
.hvr-rectangle-in:before {
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #e1e1e1;
  -webkit-transform: scale(1);
  transform: scale(1);
  -webkit-transition-property: transform;
  -webkit-transition-property: -webkit-transform;
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
}
.hvr-rectangle-in:hover, .hvr-rectangle-in:focus, .hvr-rectangle-in:active {
  color: white;
}
.hvr-rectangle-in:hover:before, .hvr-rectangle-in:focus:before, .hvr-rectangle-in:active:before {
  -webkit-transform: scale(0);
  transform: scale(0);
}

/* Rectangle Out */
.hvr-rectangle-out {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  position: relative;
  background: #e1e1e1;
  -webkit-transition-property: color;
  transition-property: color;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
}
.hvr-rectangle-out:before {
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #2098D1;
  -webkit-transform: scale(0);
  transform: scale(0);
  -webkit-transition-property: transform;
  -webkit-transition-property: -webkit-transform;
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
}
.hvr-rectangle-out:hover, .hvr-rectangle-out:focus, .hvr-rectangle-out:active {
  color: white;
}
.hvr-rectangle-out:hover:before, .hvr-rectangle-out:focus:before, .hvr-rectangle-out:active:before {
  -webkit-transform: scale(1);
  transform: scale(1);
}

/* Shutter In Horizontal */
.hvr-shutter-in-horizontal {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  position: relative;
  background: #2098D1;
  -webkit-transition-property: color;
  transition-property: color;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
}
.hvr-shutter-in-horizontal:before {
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: #e1e1e1;
  -webkit-transform: scaleX(1);
  transform: scaleX(1);
  -webkit-transform-origin: 50%;
  transform-origin: 50%;
  -webkit-transition-property: transform;
  -webkit-transition-property: -webkit-transform;
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
}
.hvr-shutter-in-horizontal:hover, .hvr-shutter-in-horizontal:focus, .hvr-shutter-in-horizontal:active {
  color: white;
}
.hvr-shutter-in-horizontal:hover:before, .hvr-shutter-in-horizontal:focus:before, .hvr-shutter-in-horizontal:active:before {
  -webkit-transform: scaleX(0);
  transform: scaleX(0);
}

/* Shutter Out Horizontal */
.hvr-shutter-out-horizontal {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  position: relative;
  background: #e1e1e1;
  -webkit-transition-property: color;
  transition-property: color;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
}
.hvr-shutter-out-horizontal:before {
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: #2098D1;
  -webkit-transform: scaleX(0);
  transform: scaleX(0);
  -webkit-transform-origin: 50%;
  transform-origin: 50%;
  -webkit-transition-property: transform;
  -webkit-transition-property: -webkit-transform;
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
}
.hvr-shutter-out-horizontal:hover, .hvr-shutter-out-horizontal:focus, .hvr-shutter-out-horizontal:active {
  color: white;
}
.hvr-shutter-out-horizontal:hover:before, .hvr-shutter-out-horizontal:focus:before, .hvr-shutter-out-horizontal:active:before {
  -webkit-transform: scaleX(1);
  transform: scaleX(1);
}

/* Shutter In Vertical */
.hvr-shutter-in-vertical {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  position: relative;
  background: #2098D1;
  -webkit-transition-property: color;
  transition-property: color;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
}
.hvr-shutter-in-vertical:before {
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: #e1e1e1;
  -webkit-transform: scaleY(1);
  transform: scaleY(1);
  -webkit-transform-origin: 50%;
  transform-origin: 50%;
  -webkit-transition-property: transform;
  -webkit-transition-property: -webkit-transform;
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
}
.hvr-shutter-in-vertical:hover, .hvr-shutter-in-vertical:focus, .hvr-shutter-in-vertical:active {
  color: white;
}
.hvr-shutter-in-vertical:hover:before, .hvr-shutter-in-vertical:focus:before, .hvr-shutter-in-vertical:active:before {
  -webkit-transform: scaleY(0);
  transform: scaleY(0);
}

/* Shutter Out Vertical */
.hvr-shutter-out-vertical {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  position: relative;
  background: #e1e1e1;
  -webkit-transition-property: color;
  transition-property: color;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
}
.hvr-shutter-out-vertical:before {
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: #2098D1;
  -webkit-transform: scaleY(0);
  transform: scaleY(0);
  -webkit-transform-origin: 50%;
  transform-origin: 50%;
  -webkit-transition-property: transform;
  -webkit-transition-property: -webkit-transform;
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
}
.hvr-shutter-out-vertical:hover, .hvr-shutter-out-vertical:focus, .hvr-shutter-out-vertical:active {
  color: white;
}
.hvr-shutter-out-vertical:hover:before, .hvr-shutter-out-vertical:focus:before, .hvr-shutter-out-vertical:active:before {
  -webkit-transform: scaleY(1);
  transform: scaleY(1);
}

/* BORDER TRANSITIONS */
/* Border Fade */
.hvr-border-fade {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-property: box-shadow;
  transition-property: box-shadow;
  box-shadow: inset 0 0 0 4px #e1e1e1, 0 0 1px rgba(0, 0, 0, 0);
  /* Hack to improve aliasing on mobile/tablet devices */
}
.hvr-border-fade:hover, .hvr-border-fade:focus, .hvr-border-fade:active {
  box-shadow: inset 0 0 0 4px #2098D1, 0 0 1px rgba(0, 0, 0, 0);
  /* Hack to improve aliasing on mobile/tablet devices */
}

/* Hollow */
.hvr-hollow {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-property: background;
  transition-property: background;
  box-shadow: inset 0 0 0 4px #e1e1e1, 0 0 1px rgba(0, 0, 0, 0);
  /* Hack to improve aliasing on mobile/tablet devices */
}
.hvr-hollow:hover, .hvr-hollow:focus, .hvr-hollow:active {
  background: none;
}

/* Trim */
.hvr-trim {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  position: relative;
}
.hvr-trim:before {
  content: '';
  position: absolute;
  border: white solid 4px;
  top: 4px;
  left: 4px;
  right: 4px;
  bottom: 4px;
  opacity: 0;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-property: opacity;
  transition-property: opacity;
}
.hvr-trim:hover:before, .hvr-trim:focus:before, .hvr-trim:active:before {
  opacity: 1;
}

/* Ripple Out */
@-webkit-keyframes hvr-ripple-out {
  100% {
    top: -12px;
    right: -12px;
    bottom: -12px;
    left: -12px;
    opacity: 0;
  }
}
@keyframes hvr-ripple-out {
  100% {
    top: -12px;
    right: -12px;
    bottom: -12px;
    left: -12px;
    opacity: 0;
  }
}
.hvr-ripple-out {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  position: relative;
}
.hvr-ripple-out:before {
  content: '';
  position: absolute;
  border: #e1e1e1 solid 6px;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
}
.hvr-ripple-out:hover:before, .hvr-ripple-out:focus:before, .hvr-ripple-out:active:before {
  -webkit-animation-name: hvr-ripple-out;
  animation-name: hvr-ripple-out;
}

/* Ripple In */
@-webkit-keyframes hvr-ripple-in {
  100% {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    opacity: 1;
  }
}
@keyframes hvr-ripple-in {
  100% {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    opacity: 1;
  }
}
.hvr-ripple-in {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  position: relative;
}
.hvr-ripple-in:before {
  content: '';
  position: absolute;
  border: #e1e1e1 solid 4px;
  top: -12px;
  right: -12px;
  bottom: -12px;
  left: -12px;
  opacity: 0;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
}
.hvr-ripple-in:hover:before, .hvr-ripple-in:focus:before, .hvr-ripple-in:active:before {
  -webkit-animation-name: hvr-ripple-in;
  animation-name: hvr-ripple-in;
}

/* Outline Out */
.hvr-outline-out {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  position: relative;
}
.hvr-outline-out:before {
  content: '';
  position: absolute;
  border: #e1e1e1 solid 4px;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-property: top, right, bottom, left;
  transition-property: top, right, bottom, left;
}
.hvr-outline-out:hover:before, .hvr-outline-out:focus:before, .hvr-outline-out:active:before {
  top: -8px;
  right: -8px;
  bottom: -8px;
  left: -8px;
}

/* Outline In */
.hvr-outline-in {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  position: relative;
}
.hvr-outline-in:before {
  pointer-events: none;
  content: '';
  position: absolute;
  border: #e1e1e1 solid 4px;
  top: -16px;
  right: -16px;
  bottom: -16px;
  left: -16px;
  opacity: 0;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-property: top, right, bottom, left;
  transition-property: top, right, bottom, left;
}
.hvr-outline-in:hover:before, .hvr-outline-in:focus:before, .hvr-outline-in:active:before {
  top: -8px;
  right: -8px;
  bottom: -8px;
  left: -8px;
  opacity: 1;
}

/* Round Corners */
.hvr-round-corners {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-property: border-radius;
  transition-property: border-radius;
}
.hvr-round-corners:hover, .hvr-round-corners:focus, .hvr-round-corners:active {
  border-radius: 1em;
}

/* Underline From Left */
.hvr-underline-from-left {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  position: relative;
  overflow: hidden;
}
.hvr-underline-from-left:before {
  content: "";
  position: absolute;
  z-index: -1;
  left: 0;
  right: 100%;
  bottom: 0;
  background: #2098D1;
  height: 4px;
  -webkit-transition-property: right;
  transition-property: right;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
}
.hvr-underline-from-left:hover:before, .hvr-underline-from-left:focus:before, .hvr-underline-from-left:active:before {
  right: 0;
}

/* Underline From Center */
.hvr-underline-from-center {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  position: relative;
  overflow: hidden;
}
.hvr-underline-from-center:before {
  content: "";
  position: absolute;
  z-index: -1;
  left: 51%;
  right: 51%;
  bottom: 0;
  background: #2098D1;
  height: 4px;
  -webkit-transition-property: left, right;
  transition-property: left, right;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
}
.hvr-underline-from-center:hover:before, .hvr-underline-from-center:focus:before, .hvr-underline-from-center:active:before {
  left: 0;
  right: 0;
}

/* Underline From Right */
.hvr-underline-from-right {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  position: relative;
  overflow: hidden;
}
.hvr-underline-from-right:before {
  content: "";
  position: absolute;
  z-index: -1;
  left: 100%;
  right: 0;
  bottom: 0;
  background: #2098D1;
  height: 4px;
  -webkit-transition-property: left;
  transition-property: left;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
}
.hvr-underline-from-right:hover:before, .hvr-underline-from-right:focus:before, .hvr-underline-from-right:active:before {
  left: 0;
}

/* Overline From Left */
.hvr-overline-from-left {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  position: relative;
  overflow: hidden;
}
.hvr-overline-from-left:before {
  content: "";
  position: absolute;
  z-index: -1;
  left: 0;
  right: 100%;
  top: 0;
  background: #2098D1;
  height: 4px;
  -webkit-transition-property: right;
  transition-property: right;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
}
.hvr-overline-from-left:hover:before, .hvr-overline-from-left:focus:before, .hvr-overline-from-left:active:before {
  right: 0;
}

/* Overline From Center */
.hvr-overline-from-center {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  position: relative;
  overflow: hidden;
}
.hvr-overline-from-center:before {
  content: "";
  position: absolute;
  z-index: -1;
  left: 51%;
  right: 51%;
  top: 0;
  background: #2098D1;
  height: 4px;
  -webkit-transition-property: left, right;
  transition-property: left, right;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
}
.hvr-overline-from-center:hover:before, .hvr-overline-from-center:focus:before, .hvr-overline-from-center:active:before {
  left: 0;
  right: 0;
}

/* Overline From Right */
.hvr-overline-from-right {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  position: relative;
  overflow: hidden;
}
.hvr-overline-from-right:before {
  content: "";
  position: absolute;
  z-index: -1;
  left: 100%;
  right: 0;
  top: 0;
  background: #2098D1;
  height: 4px;
  -webkit-transition-property: left;
  transition-property: left;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
}
.hvr-overline-from-right:hover:before, .hvr-overline-from-right:focus:before, .hvr-overline-from-right:active:before {
  left: 0;
}

/* Reveal */
.hvr-reveal {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  position: relative;
  overflow: hidden;
}
.hvr-reveal:before {
  content: "";
  position: absolute;
  z-index: -1;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  border-color: #2098D1;
  border-style: solid;
  border-width: 0;
  -webkit-transition-property: border-width;
  transition-property: border-width;
  -webkit-transition-duration: 0.1s;
  transition-duration: 0.1s;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
}
.hvr-reveal:hover:before, .hvr-reveal:focus:before, .hvr-reveal:active:before {
  -webkit-transform: translateY(0);
  transform: translateY(0);
  border-width: 4px;
}

/* Underline Reveal */
.hvr-underline-reveal {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  position: relative;
  overflow: hidden;
}
.hvr-underline-reveal:before {
  content: "";
  position: absolute;
  z-index: -1;
  left: 0;
  right: 0;
  bottom: 0;
  background: #2098D1;
  height: 4px;
  -webkit-transform: translateY(4px);
  transform: translateY(4px);
  -webkit-transition-property: transform;
  -webkit-transition-property: -webkit-transform;
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
}
.hvr-underline-reveal:hover:before, .hvr-underline-reveal:focus:before, .hvr-underline-reveal:active:before {
  -webkit-transform: translateY(0);
  transform: translateY(0);
}

/* Overline Reveal */
.hvr-overline-reveal {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  position: relative;
  overflow: hidden;
}
.hvr-overline-reveal:before {
  content: "";
  position: absolute;
  z-index: -1;
  left: 0;
  right: 0;
  top: 0;
  background: #2098D1;
  height: 4px;
  -webkit-transform: translateY(-4px);
  transform: translateY(-4px);
  -webkit-transition-property: transform;
  -webkit-transition-property: -webkit-transform;
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
}
.hvr-overline-reveal:hover:before, .hvr-overline-reveal:focus:before, .hvr-overline-reveal:active:before {
  -webkit-transform: translateY(0);
  transform: translateY(0);
}

/* SHADOW/GLOW TRANSITIONS */
/* Glow */
.hvr-glow {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-property: box-shadow;
  transition-property: box-shadow;
}
.hvr-glow:hover, .hvr-glow:focus, .hvr-glow:active {
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
}

/* Shadow */
.hvr-shadow {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-property: box-shadow;
  transition-property: box-shadow;
}
.hvr-shadow:hover, .hvr-shadow:focus, .hvr-shadow:active {
  box-shadow: 0 10px 10px -10px rgba(0, 0, 0, 0.5);
}

/* Grow Shadow */
.hvr-grow-shadow {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-property: box-shadow, transform;
  -webkit-transition-property: box-shadow, -webkit-transform;
  transition-property: box-shadow, -webkit-transform;
  transition-property: box-shadow, transform;
  transition-property: box-shadow, transform, -webkit-transform;
}
.hvr-grow-shadow:hover, .hvr-grow-shadow:focus, .hvr-grow-shadow:active {
  box-shadow: 0 10px 10px -10px rgba(0, 0, 0, 0.5);
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

/* Box Shadow Outset */
.hvr-box-shadow-outset {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-property: box-shadow;
  transition-property: box-shadow;
}
.hvr-box-shadow-outset:hover, .hvr-box-shadow-outset:focus, .hvr-box-shadow-outset:active {
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.6);
}

/* Box Shadow Inset */
.hvr-box-shadow-inset {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-property: box-shadow;
  transition-property: box-shadow;
  box-shadow: inset 0 0 0 rgba(0, 0, 0, 0.6), 0 0 1px rgba(0, 0, 0, 0);
  /* Hack to improve aliasing on mobile/tablet devices */
}
.hvr-box-shadow-inset:hover, .hvr-box-shadow-inset:focus, .hvr-box-shadow-inset:active {
  box-shadow: inset 2px 2px 2px rgba(0, 0, 0, 0.6), 0 0 1px rgba(0, 0, 0, 0);
  /* Hack to improve aliasing on mobile/tablet devices */
}

/* Float Shadow */
.hvr-float-shadow {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  position: relative;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-property: transform;
  -webkit-transition-property: -webkit-transform;
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
}
.hvr-float-shadow:before {
  pointer-events: none;
  position: absolute;
  z-index: -1;
  content: '';
  top: 100%;
  left: 5%;
  height: 10px;
  width: 90%;
  opacity: 0;
  background: radial-gradient(ellipse at center, rgba(0, 0, 0, 0.35) 0%, rgba(0, 0, 0, 0) 80%);
  /* W3C */
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-property: transform, opacity;
  -webkit-transition-property: opacity, -webkit-transform;
  transition-property: opacity, -webkit-transform;
  transition-property: transform, opacity;
  transition-property: transform, opacity, -webkit-transform;
}
.hvr-float-shadow:hover, .hvr-float-shadow:focus, .hvr-float-shadow:active {
  -webkit-transform: translateY(-5px);
  transform: translateY(-5px);
  /* move the element up by 5px */
}
.hvr-float-shadow:hover:before, .hvr-float-shadow:focus:before, .hvr-float-shadow:active:before {
  opacity: 1;
  -webkit-transform: translateY(5px);
  transform: translateY(5px);
  /* move the element down by 5px (it will stay in place because it's attached to the element that also moves up 5px) */
}

/* Shadow Radial */
.hvr-shadow-radial {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  position: relative;
}
.hvr-shadow-radial:before, .hvr-shadow-radial:after {
  pointer-events: none;
  position: absolute;
  content: '';
  left: 0;
  width: 100%;
  box-sizing: border-box;
  background-repeat: no-repeat;
  height: 5px;
  opacity: 0;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-property: opacity;
  transition-property: opacity;
}
.hvr-shadow-radial:before {
  bottom: 100%;
  background: radial-gradient(ellipse at 50% 150%, rgba(0, 0, 0, 0.6) 0%, rgba(0, 0, 0, 0) 80%);
}
.hvr-shadow-radial:after {
  top: 100%;
  background: radial-gradient(ellipse at 50% -50%, rgba(0, 0, 0, 0.6) 0%, rgba(0, 0, 0, 0) 80%);
}
.hvr-shadow-radial:hover:before, .hvr-shadow-radial:focus:before, .hvr-shadow-radial:active:before, .hvr-shadow-radial:hover:after, .hvr-shadow-radial:focus:after, .hvr-shadow-radial:active:after {
  opacity: 1;
}

/* SPEECH BUBBLES */
/* Bubble Top */
.hvr-bubble-top {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  position: relative;
}
.hvr-bubble-top:before {
  pointer-events: none;
  position: absolute;
  z-index: -1;
  content: '';
  border-style: solid;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-property: transform;
  -webkit-transition-property: -webkit-transform;
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
  left: calc(50% - 10px);
  top: 0;
  border-width: 0 10px 10px 10px;
  border-color: transparent transparent #e1e1e1 transparent;
}
.hvr-bubble-top:hover:before, .hvr-bubble-top:focus:before, .hvr-bubble-top:active:before {
  -webkit-transform: translateY(-10px);
  transform: translateY(-10px);
}

/* Bubble Right */
.hvr-bubble-right {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  position: relative;
}
.hvr-bubble-right:before {
  pointer-events: none;
  position: absolute;
  z-index: -1;
  content: '';
  border-style: solid;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-property: transform;
  -webkit-transition-property: -webkit-transform;
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
  top: calc(50% - 10px);
  right: 0;
  border-width: 10px 0 10px 10px;
  border-color: transparent transparent transparent #e1e1e1;
}
.hvr-bubble-right:hover:before, .hvr-bubble-right:focus:before, .hvr-bubble-right:active:before {
  -webkit-transform: translateX(10px);
  transform: translateX(10px);
}

/* Bubble Bottom */
.hvr-bubble-bottom {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  position: relative;
}
.hvr-bubble-bottom:before {
  pointer-events: none;
  position: absolute;
  z-index: -1;
  content: '';
  border-style: solid;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-property: transform;
  -webkit-transition-property: -webkit-transform;
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
  left: calc(50% - 10px);
  bottom: 0;
  border-width: 10px 10px 0 10px;
  border-color: #e1e1e1 transparent transparent transparent;
}
.hvr-bubble-bottom:hover:before, .hvr-bubble-bottom:focus:before, .hvr-bubble-bottom:active:before {
  -webkit-transform: translateY(10px);
  transform: translateY(10px);
}

/* Bubble Left */
.hvr-bubble-left {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  position: relative;
}
.hvr-bubble-left:before {
  pointer-events: none;
  position: absolute;
  z-index: -1;
  content: '';
  border-style: solid;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-property: transform;
  -webkit-transition-property: -webkit-transform;
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
  top: calc(50% - 10px);
  left: 0;
  border-width: 10px 10px 10px 0;
  border-color: transparent #e1e1e1 transparent transparent;
}
.hvr-bubble-left:hover:before, .hvr-bubble-left:focus:before, .hvr-bubble-left:active:before {
  -webkit-transform: translateX(-10px);
  transform: translateX(-10px);
}

/* Bubble Float Top */
.hvr-bubble-float-top {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  position: relative;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-property: transform;
  -webkit-transition-property: -webkit-transform;
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
}
.hvr-bubble-float-top:before {
  position: absolute;
  z-index: -1;
  content: '';
  left: calc(50% - 10px);
  top: 0;
  border-style: solid;
  border-width: 0 10px 10px 10px;
  border-color: transparent transparent #e1e1e1 transparent;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-property: transform;
  -webkit-transition-property: -webkit-transform;
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
}
.hvr-bubble-float-top:hover, .hvr-bubble-float-top:focus, .hvr-bubble-float-top:active {
  -webkit-transform: translateY(10px);
  transform: translateY(10px);
}
.hvr-bubble-float-top:hover:before, .hvr-bubble-float-top:focus:before, .hvr-bubble-float-top:active:before {
  -webkit-transform: translateY(-10px);
  transform: translateY(-10px);
}

/* Bubble Float Right */
.hvr-bubble-float-right {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  position: relative;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-property: transform;
  -webkit-transition-property: -webkit-transform;
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
}
.hvr-bubble-float-right:before {
  position: absolute;
  z-index: -1;
  top: calc(50% - 10px);
  right: 0;
  content: '';
  border-style: solid;
  border-width: 10px 0 10px 10px;
  border-color: transparent transparent transparent #e1e1e1;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-property: transform;
  -webkit-transition-property: -webkit-transform;
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
}
.hvr-bubble-float-right:hover, .hvr-bubble-float-right:focus, .hvr-bubble-float-right:active {
  -webkit-transform: translateX(-10px);
  transform: translateX(-10px);
}
.hvr-bubble-float-right:hover:before, .hvr-bubble-float-right:focus:before, .hvr-bubble-float-right:active:before {
  -webkit-transform: translateX(10px);
  transform: translateX(10px);
}

/* Bubble Float Bottom */
.hvr-bubble-float-bottom {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  position: relative;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-property: transform;
  -webkit-transition-property: -webkit-transform;
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
}
.hvr-bubble-float-bottom:before {
  position: absolute;
  z-index: -1;
  content: '';
  left: calc(50% - 10px);
  bottom: 0;
  border-style: solid;
  border-width: 10px 10px 0 10px;
  border-color: #e1e1e1 transparent transparent transparent;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-property: transform;
  -webkit-transition-property: -webkit-transform;
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
}
.hvr-bubble-float-bottom:hover, .hvr-bubble-float-bottom:focus, .hvr-bubble-float-bottom:active {
  -webkit-transform: translateY(-10px);
  transform: translateY(-10px);
}
.hvr-bubble-float-bottom:hover:before, .hvr-bubble-float-bottom:focus:before, .hvr-bubble-float-bottom:active:before {
  -webkit-transform: translateY(10px);
  transform: translateY(10px);
}

/* Bubble Float Left */
.hvr-bubble-float-left {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  position: relative;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-property: transform;
  -webkit-transition-property: -webkit-transform;
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
}
.hvr-bubble-float-left:before {
  position: absolute;
  z-index: -1;
  content: '';
  top: calc(50% - 10px);
  left: 0;
  border-style: solid;
  border-width: 10px 10px 10px 0;
  border-color: transparent #e1e1e1 transparent transparent;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-property: transform;
  -webkit-transition-property: -webkit-transform;
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
}
.hvr-bubble-float-left:hover, .hvr-bubble-float-left:focus, .hvr-bubble-float-left:active {
  -webkit-transform: translateX(10px);
  transform: translateX(10px);
}
.hvr-bubble-float-left:hover:before, .hvr-bubble-float-left:focus:before, .hvr-bubble-float-left:active:before {
  -webkit-transform: translateX(-10px);
  transform: translateX(-10px);
}

/* ICONS */
/* Icon Back */
.hvr-icon-back {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  -webkit-transition-duration: 0.1s;
  transition-duration: 0.1s;
}
.hvr-icon-back .hvr-icon {
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-transition-duration: 0.1s;
  transition-duration: 0.1s;
  -webkit-transition-property: transform;
  -webkit-transition-property: -webkit-transform;
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
}
.hvr-icon-back:hover .hvr-icon, .hvr-icon-back:focus .hvr-icon, .hvr-icon-back:active .hvr-icon {
  -webkit-transform: translateX(-4px);
  transform: translateX(-4px);
}

/* Icon Forward */
.hvr-icon-forward {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  -webkit-transition-duration: 0.1s;
  transition-duration: 0.1s;
}
.hvr-icon-forward .hvr-icon {
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-transition-duration: 0.1s;
  transition-duration: 0.1s;
  -webkit-transition-property: transform;
  -webkit-transition-property: -webkit-transform;
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
}
.hvr-icon-forward:hover .hvr-icon, .hvr-icon-forward:focus .hvr-icon, .hvr-icon-forward:active .hvr-icon {
  -webkit-transform: translateX(4px);
  transform: translateX(4px);
}

/* Icon Down */
@-webkit-keyframes hvr-icon-down {
  0%,
	50%,
	100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  25%,
	75% {
    -webkit-transform: translateY(6px);
    transform: translateY(6px);
  }
}
@keyframes hvr-icon-down {
  0%,
	50%,
	100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  25%,
	75% {
    -webkit-transform: translateY(6px);
    transform: translateY(6px);
  }
}
/* Icon Down */
.hvr-icon-down {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
}
.hvr-icon-down .hvr-icon {
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
}
.hvr-icon-down:hover .hvr-icon, .hvr-icon-down:focus .hvr-icon, .hvr-icon-down:active .hvr-icon {
  -webkit-animation-name: hvr-icon-down;
  animation-name: hvr-icon-down;
  -webkit-animation-duration: 0.75s;
  animation-duration: 0.75s;
  -webkit-animation-timing-function: ease-out;
  animation-timing-function: ease-out;
}

/* Icon Up */
@-webkit-keyframes hvr-icon-up {
  0%,
	50%,
	100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  25%,
	75% {
    -webkit-transform: translateY(-6px);
    transform: translateY(-6px);
  }
}
@keyframes hvr-icon-up {
  0%,
	50%,
	100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  25%,
	75% {
    -webkit-transform: translateY(-6px);
    transform: translateY(-6px);
  }
}
/* Icon Up */
.hvr-icon-up {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
}
.hvr-icon-up .hvr-icon {
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
}
.hvr-icon-up:hover .hvr-icon, .hvr-icon-up:focus .hvr-icon, .hvr-icon-up:active .hvr-icon {
  -webkit-animation-name: hvr-icon-up;
  animation-name: hvr-icon-up;
  -webkit-animation-duration: 0.75s;
  animation-duration: 0.75s;
  -webkit-animation-timing-function: ease-out;
  animation-timing-function: ease-out;
}

/* Icon Spin */
.hvr-icon-spin {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
}
.hvr-icon-spin .hvr-icon {
  -webkit-transition-duration: 1s;
  transition-duration: 1s;
  -webkit-transition-property: transform;
  -webkit-transition-property: -webkit-transform;
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
  -webkit-transition-timing-function: ease-in-out;
  transition-timing-function: ease-in-out;
}
.hvr-icon-spin:hover .hvr-icon, .hvr-icon-spin:focus .hvr-icon, .hvr-icon-spin:active .hvr-icon {
  -webkit-transform: rotate(360deg);
  transform: rotate(360deg);
}

/* Icon Drop */
@-webkit-keyframes hvr-icon-drop {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0;
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%);
  }
  51%,
	100% {
    opacity: 1;
  }
}
@keyframes hvr-icon-drop {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0;
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%);
  }
  51%,
	100% {
    opacity: 1;
  }
}
/* Icon Drop */
.hvr-icon-drop {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
}
.hvr-icon-drop .hvr-icon {
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
}
.hvr-icon-drop:hover .hvr-icon, .hvr-icon-drop:focus .hvr-icon, .hvr-icon-drop:active .hvr-icon {
  opacity: 0;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-animation-name: hvr-icon-drop;
  animation-name: hvr-icon-drop;
  -webkit-animation-duration: 0.5s;
  animation-duration: 0.5s;
  -webkit-animation-delay: 0.3s;
  animation-delay: 0.3s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  -webkit-animation-timing-function: ease-in-out;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: cubic-bezier(0.52, 1.64, 0.37, 0.66);
  animation-timing-function: cubic-bezier(0.52, 1.64, 0.37, 0.66);
}

/* Icon Fade */
.hvr-icon-fade {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
}
.hvr-icon-fade .hvr-icon {
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-transition-duration: 0.5s;
  transition-duration: 0.5s;
  -webkit-transition-property: color;
  transition-property: color;
}
.hvr-icon-fade:hover .hvr-icon, .hvr-icon-fade:focus .hvr-icon, .hvr-icon-fade:active .hvr-icon {
  color: #0F9E5E;
}

/* Icon Float Away */
@-webkit-keyframes hvr-icon-float-away {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    -webkit-transform: translateY(-1em);
    transform: translateY(-1em);
  }
}
@keyframes hvr-icon-float-away {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    -webkit-transform: translateY(-1em);
    transform: translateY(-1em);
  }
}
/* Icon Float Away */
.hvr-icon-float-away {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
}
.hvr-icon-float-away .hvr-icon {
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation-duration: 0.5s;
  animation-duration: 0.5s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}
.hvr-icon-float-away:hover .hvr-icon, .hvr-icon-float-away:focus .hvr-icon, .hvr-icon-float-away:active .hvr-icon {
  -webkit-animation-name: hvr-icon-float-away;
  animation-name: hvr-icon-float-away;
  -webkit-animation-timing-function: ease-out;
  animation-timing-function: ease-out;
}

/* Icon Sink Away */
@-webkit-keyframes hvr-icon-sink-away {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    -webkit-transform: translateY(1em);
    transform: translateY(1em);
  }
}
@keyframes hvr-icon-sink-away {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    -webkit-transform: translateY(1em);
    transform: translateY(1em);
  }
}
/* Icon Sink Away */
.hvr-icon-sink-away {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
}
.hvr-icon-sink-away .hvr-icon {
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation-duration: 0.5s;
  animation-duration: 0.5s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}
.hvr-icon-sink-away:hover .hvr-icon, .hvr-icon-sink-away:focus .hvr-icon, .hvr-icon-sink-away:active .hvr-icon {
  -webkit-animation-name: hvr-icon-sink-away;
  animation-name: hvr-icon-sink-away;
  -webkit-animation-timing-function: ease-out;
  animation-timing-function: ease-out;
}

/* Icon Grow */
.hvr-icon-grow {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
}
.hvr-icon-grow .hvr-icon {
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-property: transform;
  -webkit-transition-property: -webkit-transform;
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
}
.hvr-icon-grow:hover .hvr-icon, .hvr-icon-grow:focus .hvr-icon, .hvr-icon-grow:active .hvr-icon {
  -webkit-transform: scale(1.3) translateZ(0);
  transform: scale(1.3) translateZ(0);
}

/* Icon Shrink */
.hvr-icon-shrink {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
}
.hvr-icon-shrink .hvr-icon {
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-property: transform;
  -webkit-transition-property: -webkit-transform;
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
}
.hvr-icon-shrink:hover .hvr-icon, .hvr-icon-shrink:focus .hvr-icon, .hvr-icon-shrink:active .hvr-icon {
  -webkit-transform: scale(0.8);
  transform: scale(0.8);
}

/* Icon Pulse */
@-webkit-keyframes hvr-icon-pulse {
  25% {
    -webkit-transform: scale(1.3);
    transform: scale(1.3);
  }
  75% {
    -webkit-transform: scale(0.8);
    transform: scale(0.8);
  }
}
@keyframes hvr-icon-pulse {
  25% {
    -webkit-transform: scale(1.3);
    transform: scale(1.3);
  }
  75% {
    -webkit-transform: scale(0.8);
    transform: scale(0.8);
  }
}
.hvr-icon-pulse {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
}
.hvr-icon-pulse .hvr-icon {
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
}
.hvr-icon-pulse:hover .hvr-icon, .hvr-icon-pulse:focus .hvr-icon, .hvr-icon-pulse:active .hvr-icon {
  -webkit-animation-name: hvr-icon-pulse;
  animation-name: hvr-icon-pulse;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
}

/* Icon Pulse Grow */
@-webkit-keyframes hvr-icon-pulse-grow {
  to {
    -webkit-transform: scale(1.3);
    transform: scale(1.3);
  }
}
@keyframes hvr-icon-pulse-grow {
  to {
    -webkit-transform: scale(1.3);
    transform: scale(1.3);
  }
}
.hvr-icon-pulse-grow {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
}
.hvr-icon-pulse-grow .hvr-icon {
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
}
.hvr-icon-pulse-grow:hover .hvr-icon, .hvr-icon-pulse-grow:focus .hvr-icon, .hvr-icon-pulse-grow:active .hvr-icon {
  -webkit-animation-name: hvr-icon-pulse-grow;
  animation-name: hvr-icon-pulse-grow;
  -webkit-animation-duration: 0.3s;
  animation-duration: 0.3s;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-direction: alternate;
  animation-direction: alternate;
}

/* Icon Pulse Shrink */
@-webkit-keyframes hvr-icon-pulse-shrink {
  to {
    -webkit-transform: scale(0.8);
    transform: scale(0.8);
  }
}
@keyframes hvr-icon-pulse-shrink {
  to {
    -webkit-transform: scale(0.8);
    transform: scale(0.8);
  }
}
.hvr-icon-pulse-shrink {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
}
.hvr-icon-pulse-shrink .hvr-icon {
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
}
.hvr-icon-pulse-shrink:hover .hvr-icon, .hvr-icon-pulse-shrink:focus .hvr-icon, .hvr-icon-pulse-shrink:active .hvr-icon {
  -webkit-animation-name: hvr-icon-pulse-shrink;
  animation-name: hvr-icon-pulse-shrink;
  -webkit-animation-duration: 0.3s;
  animation-duration: 0.3s;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-direction: alternate;
  animation-direction: alternate;
}

/* Icon Push */
@-webkit-keyframes hvr-icon-push {
  50% {
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
  }
}
@keyframes hvr-icon-push {
  50% {
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
  }
}
.hvr-icon-push {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
}
.hvr-icon-push .hvr-icon {
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-property: transform;
  -webkit-transition-property: -webkit-transform;
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
}
.hvr-icon-push:hover .hvr-icon, .hvr-icon-push:focus .hvr-icon, .hvr-icon-push:active .hvr-icon {
  -webkit-animation-name: hvr-icon-push;
  animation-name: hvr-icon-push;
  -webkit-animation-duration: 0.3s;
  animation-duration: 0.3s;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  -webkit-animation-iteration-count: 1;
  animation-iteration-count: 1;
}

/* Icon Pop */
@-webkit-keyframes hvr-icon-pop {
  50% {
    -webkit-transform: scale(1.5);
    transform: scale(1.5);
  }
}
@keyframes hvr-icon-pop {
  50% {
    -webkit-transform: scale(1.5);
    transform: scale(1.5);
  }
}
.hvr-icon-pop {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
}
.hvr-icon-pop .hvr-icon {
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-property: transform;
  -webkit-transition-property: -webkit-transform;
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
}
.hvr-icon-pop:hover .hvr-icon, .hvr-icon-pop:focus .hvr-icon, .hvr-icon-pop:active .hvr-icon {
  -webkit-animation-name: hvr-icon-pop;
  animation-name: hvr-icon-pop;
  -webkit-animation-duration: 0.3s;
  animation-duration: 0.3s;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  -webkit-animation-iteration-count: 1;
  animation-iteration-count: 1;
}

/* Icon Bounce */
.hvr-icon-bounce {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
}
.hvr-icon-bounce .hvr-icon {
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-property: transform;
  -webkit-transition-property: -webkit-transform;
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
}
.hvr-icon-bounce:hover .hvr-icon, .hvr-icon-bounce:focus .hvr-icon, .hvr-icon-bounce:active .hvr-icon {
  -webkit-transform: scale(1.5);
  transform: scale(1.5);
  -webkit-transition-timing-function: cubic-bezier(0.47, 2.02, 0.31, -0.36);
  transition-timing-function: cubic-bezier(0.47, 2.02, 0.31, -0.36);
}

/* Icon Rotate */
.hvr-icon-rotate {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
}
.hvr-icon-rotate .hvr-icon {
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-property: transform;
  -webkit-transition-property: -webkit-transform;
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
}
.hvr-icon-rotate:hover .hvr-icon, .hvr-icon-rotate:focus .hvr-icon, .hvr-icon-rotate:active .hvr-icon {
  -webkit-transform: rotate(20deg);
  transform: rotate(20deg);
}

/* Icon Grow Rotate */
.hvr-icon-grow-rotate {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
}
.hvr-icon-grow-rotate .hvr-icon {
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-property: transform;
  -webkit-transition-property: -webkit-transform;
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
}
.hvr-icon-grow-rotate:hover .hvr-icon, .hvr-icon-grow-rotate:focus .hvr-icon, .hvr-icon-grow-rotate:active .hvr-icon {
  -webkit-transform: scale(1.5) rotate(12deg);
  transform: scale(1.5) rotate(12deg);
}

/* Icon Float */
.hvr-icon-float {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
}
.hvr-icon-float .hvr-icon {
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-property: transform;
  -webkit-transition-property: -webkit-transform;
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
}
.hvr-icon-float:hover .hvr-icon, .hvr-icon-float:focus .hvr-icon, .hvr-icon-float:active .hvr-icon {
  -webkit-transform: translateY(-4px);
  transform: translateY(-4px);
}

/* Icon Sink */
.hvr-icon-sink {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
}
.hvr-icon-sink .hvr-icon {
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-property: transform;
  -webkit-transition-property: -webkit-transform;
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
}
.hvr-icon-sink:hover .hvr-icon, .hvr-icon-sink:focus .hvr-icon, .hvr-icon-sink:active .hvr-icon {
  -webkit-transform: translateY(4px);
  transform: translateY(4px);
}

/* Icon Bob */
@-webkit-keyframes hvr-icon-bob {
  0% {
    -webkit-transform: translateY(-6px);
    transform: translateY(-6px);
  }
  50% {
    -webkit-transform: translateY(-2px);
    transform: translateY(-2px);
  }
  100% {
    -webkit-transform: translateY(-6px);
    transform: translateY(-6px);
  }
}
@keyframes hvr-icon-bob {
  0% {
    -webkit-transform: translateY(-6px);
    transform: translateY(-6px);
  }
  50% {
    -webkit-transform: translateY(-2px);
    transform: translateY(-2px);
  }
  100% {
    -webkit-transform: translateY(-6px);
    transform: translateY(-6px);
  }
}
@-webkit-keyframes hvr-icon-bob-float {
  100% {
    -webkit-transform: translateY(-6px);
    transform: translateY(-6px);
  }
}
@keyframes hvr-icon-bob-float {
  100% {
    -webkit-transform: translateY(-6px);
    transform: translateY(-6px);
  }
}
.hvr-icon-bob {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
}
.hvr-icon-bob .hvr-icon {
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
}
.hvr-icon-bob:hover .hvr-icon, .hvr-icon-bob:focus .hvr-icon, .hvr-icon-bob:active .hvr-icon {
  -webkit-animation-name: hvr-icon-bob-float, hvr-icon-bob;
  animation-name: hvr-icon-bob-float, hvr-icon-bob;
  -webkit-animation-duration: .3s, 1.5s;
  animation-duration: .3s, 1.5s;
  -webkit-animation-delay: 0s, .3s;
  animation-delay: 0s, .3s;
  -webkit-animation-timing-function: ease-out, ease-in-out;
  animation-timing-function: ease-out, ease-in-out;
  -webkit-animation-iteration-count: 1, infinite;
  animation-iteration-count: 1, infinite;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  -webkit-animation-direction: normal, alternate;
  animation-direction: normal, alternate;
}

/* Icon Hang */
@-webkit-keyframes hvr-icon-hang {
  0% {
    -webkit-transform: translateY(6px);
    transform: translateY(6px);
  }
  50% {
    -webkit-transform: translateY(2px);
    transform: translateY(2px);
  }
  100% {
    -webkit-transform: translateY(6px);
    transform: translateY(6px);
  }
}
@keyframes hvr-icon-hang {
  0% {
    -webkit-transform: translateY(6px);
    transform: translateY(6px);
  }
  50% {
    -webkit-transform: translateY(2px);
    transform: translateY(2px);
  }
  100% {
    -webkit-transform: translateY(6px);
    transform: translateY(6px);
  }
}
@-webkit-keyframes hvr-icon-hang-sink {
  100% {
    -webkit-transform: translateY(6px);
    transform: translateY(6px);
  }
}
@keyframes hvr-icon-hang-sink {
  100% {
    -webkit-transform: translateY(6px);
    transform: translateY(6px);
  }
}
.hvr-icon-hang {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
}
.hvr-icon-hang .hvr-icon {
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
}
.hvr-icon-hang:hover .hvr-icon, .hvr-icon-hang:focus .hvr-icon, .hvr-icon-hang:active .hvr-icon {
  -webkit-animation-name: hvr-icon-hang-sink, hvr-icon-hang;
  animation-name: hvr-icon-hang-sink, hvr-icon-hang;
  -webkit-animation-duration: .3s, 1.5s;
  animation-duration: .3s, 1.5s;
  -webkit-animation-delay: 0s, .3s;
  animation-delay: 0s, .3s;
  -webkit-animation-timing-function: ease-out, ease-in-out;
  animation-timing-function: ease-out, ease-in-out;
  -webkit-animation-iteration-count: 1, infinite;
  animation-iteration-count: 1, infinite;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  -webkit-animation-direction: normal, alternate;
  animation-direction: normal, alternate;
}

/* Icon Wobble Horizontal */
@-webkit-keyframes hvr-icon-wobble-horizontal {
  16.65% {
    -webkit-transform: translateX(6px);
    transform: translateX(6px);
  }
  33.3% {
    -webkit-transform: translateX(-5px);
    transform: translateX(-5px);
  }
  49.95% {
    -webkit-transform: translateX(4px);
    transform: translateX(4px);
  }
  66.6% {
    -webkit-transform: translateX(-2px);
    transform: translateX(-2px);
  }
  83.25% {
    -webkit-transform: translateX(1px);
    transform: translateX(1px);
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}
@keyframes hvr-icon-wobble-horizontal {
  16.65% {
    -webkit-transform: translateX(6px);
    transform: translateX(6px);
  }
  33.3% {
    -webkit-transform: translateX(-5px);
    transform: translateX(-5px);
  }
  49.95% {
    -webkit-transform: translateX(4px);
    transform: translateX(4px);
  }
  66.6% {
    -webkit-transform: translateX(-2px);
    transform: translateX(-2px);
  }
  83.25% {
    -webkit-transform: translateX(1px);
    transform: translateX(1px);
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}
.hvr-icon-wobble-horizontal {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
}
.hvr-icon-wobble-horizontal .hvr-icon {
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
}
.hvr-icon-wobble-horizontal:hover .hvr-icon, .hvr-icon-wobble-horizontal:focus .hvr-icon, .hvr-icon-wobble-horizontal:active .hvr-icon {
  -webkit-animation-name: hvr-icon-wobble-horizontal;
  animation-name: hvr-icon-wobble-horizontal;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-timing-function: ease-in-out;
  animation-timing-function: ease-in-out;
  -webkit-animation-iteration-count: 1;
  animation-iteration-count: 1;
}

/* Icon Wobble Vertical */
@-webkit-keyframes hvr-icon-wobble-vertical {
  16.65% {
    -webkit-transform: translateY(6px);
    transform: translateY(6px);
  }
  33.3% {
    -webkit-transform: translateY(-5px);
    transform: translateY(-5px);
  }
  49.95% {
    -webkit-transform: translateY(4px);
    transform: translateY(4px);
  }
  66.6% {
    -webkit-transform: translateY(-2px);
    transform: translateY(-2px);
  }
  83.25% {
    -webkit-transform: translateY(1px);
    transform: translateY(1px);
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}
@keyframes hvr-icon-wobble-vertical {
  16.65% {
    -webkit-transform: translateY(6px);
    transform: translateY(6px);
  }
  33.3% {
    -webkit-transform: translateY(-5px);
    transform: translateY(-5px);
  }
  49.95% {
    -webkit-transform: translateY(4px);
    transform: translateY(4px);
  }
  66.6% {
    -webkit-transform: translateY(-2px);
    transform: translateY(-2px);
  }
  83.25% {
    -webkit-transform: translateY(1px);
    transform: translateY(1px);
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}
.hvr-icon-wobble-vertical {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
}
.hvr-icon-wobble-vertical .hvr-icon {
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
}
.hvr-icon-wobble-vertical:hover .hvr-icon, .hvr-icon-wobble-vertical:focus .hvr-icon, .hvr-icon-wobble-vertical:active .hvr-icon {
  -webkit-animation-name: hvr-icon-wobble-vertical;
  animation-name: hvr-icon-wobble-vertical;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-timing-function: ease-in-out;
  animation-timing-function: ease-in-out;
  -webkit-animation-iteration-count: 1;
  animation-iteration-count: 1;
}

/* Icon Buzz */
@-webkit-keyframes hvr-icon-buzz {
  50% {
    -webkit-transform: translateX(3px) rotate(2deg);
    transform: translateX(3px) rotate(2deg);
  }
  100% {
    -webkit-transform: translateX(-3px) rotate(-2deg);
    transform: translateX(-3px) rotate(-2deg);
  }
}
@keyframes hvr-icon-buzz {
  50% {
    -webkit-transform: translateX(3px) rotate(2deg);
    transform: translateX(3px) rotate(2deg);
  }
  100% {
    -webkit-transform: translateX(-3px) rotate(-2deg);
    transform: translateX(-3px) rotate(-2deg);
  }
}
.hvr-icon-buzz {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
}
.hvr-icon-buzz .hvr-icon {
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
}
.hvr-icon-buzz:hover .hvr-icon, .hvr-icon-buzz:focus .hvr-icon, .hvr-icon-buzz:active .hvr-icon {
  -webkit-animation-name: hvr-icon-buzz;
  animation-name: hvr-icon-buzz;
  -webkit-animation-duration: 0.15s;
  animation-duration: 0.15s;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
}

/* Icon Buzz Out */
@-webkit-keyframes hvr-icon-buzz-out {
  10% {
    -webkit-transform: translateX(3px) rotate(2deg);
    transform: translateX(3px) rotate(2deg);
  }
  20% {
    -webkit-transform: translateX(-3px) rotate(-2deg);
    transform: translateX(-3px) rotate(-2deg);
  }
  30% {
    -webkit-transform: translateX(3px) rotate(2deg);
    transform: translateX(3px) rotate(2deg);
  }
  40% {
    -webkit-transform: translateX(-3px) rotate(-2deg);
    transform: translateX(-3px) rotate(-2deg);
  }
  50% {
    -webkit-transform: translateX(2px) rotate(1deg);
    transform: translateX(2px) rotate(1deg);
  }
  60% {
    -webkit-transform: translateX(-2px) rotate(-1deg);
    transform: translateX(-2px) rotate(-1deg);
  }
  70% {
    -webkit-transform: translateX(2px) rotate(1deg);
    transform: translateX(2px) rotate(1deg);
  }
  80% {
    -webkit-transform: translateX(-2px) rotate(-1deg);
    transform: translateX(-2px) rotate(-1deg);
  }
  90% {
    -webkit-transform: translateX(1px) rotate(0);
    transform: translateX(1px) rotate(0);
  }
  100% {
    -webkit-transform: translateX(-1px) rotate(0);
    transform: translateX(-1px) rotate(0);
  }
}
@keyframes hvr-icon-buzz-out {
  10% {
    -webkit-transform: translateX(3px) rotate(2deg);
    transform: translateX(3px) rotate(2deg);
  }
  20% {
    -webkit-transform: translateX(-3px) rotate(-2deg);
    transform: translateX(-3px) rotate(-2deg);
  }
  30% {
    -webkit-transform: translateX(3px) rotate(2deg);
    transform: translateX(3px) rotate(2deg);
  }
  40% {
    -webkit-transform: translateX(-3px) rotate(-2deg);
    transform: translateX(-3px) rotate(-2deg);
  }
  50% {
    -webkit-transform: translateX(2px) rotate(1deg);
    transform: translateX(2px) rotate(1deg);
  }
  60% {
    -webkit-transform: translateX(-2px) rotate(-1deg);
    transform: translateX(-2px) rotate(-1deg);
  }
  70% {
    -webkit-transform: translateX(2px) rotate(1deg);
    transform: translateX(2px) rotate(1deg);
  }
  80% {
    -webkit-transform: translateX(-2px) rotate(-1deg);
    transform: translateX(-2px) rotate(-1deg);
  }
  90% {
    -webkit-transform: translateX(1px) rotate(0);
    transform: translateX(1px) rotate(0);
  }
  100% {
    -webkit-transform: translateX(-1px) rotate(0);
    transform: translateX(-1px) rotate(0);
  }
}
.hvr-icon-buzz-out {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
}
.hvr-icon-buzz-out .hvr-icon {
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
}
.hvr-icon-buzz-out:hover .hvr-icon, .hvr-icon-buzz-out:focus .hvr-icon, .hvr-icon-buzz-out:active .hvr-icon {
  -webkit-animation-name: hvr-icon-buzz-out;
  animation-name: hvr-icon-buzz-out;
  -webkit-animation-duration: 0.75s;
  animation-duration: 0.75s;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  -webkit-animation-iteration-count: 1;
  animation-iteration-count: 1;
}

/* CURLS */
/* Curl Top Left */
.hvr-curl-top-left {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  position: relative;
}
.hvr-curl-top-left:before {
  pointer-events: none;
  position: absolute;
  content: '';
  height: 0;
  width: 0;
  top: 0;
  left: 0;
  background: white;
  /* IE9 */
  background: linear-gradient(135deg, white 45%, #aaa 50%, #ccc 56%, white 80%);
  filter: progid:DXImageTransform.Microsoft.gradient(GradientType=0,startColorstr='#ffffff', endColorstr='#000000');
  /*For IE7-8-9*/
  z-index: 1000;
  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.4);
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-property: width, height;
  transition-property: width, height;
}
.hvr-curl-top-left:hover:before, .hvr-curl-top-left:focus:before, .hvr-curl-top-left:active:before {
  width: 25px;
  height: 25px;
}

/* Curl Top Right */
.hvr-curl-top-right {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  position: relative;
}
.hvr-curl-top-right:before {
  pointer-events: none;
  position: absolute;
  content: '';
  height: 0;
  width: 0;
  top: 0;
  right: 0;
  background: white;
  /* IE9 */
  background: linear-gradient(225deg, white 45%, #aaa 50%, #ccc 56%, white 80%);
  box-shadow: -1px 1px 1px rgba(0, 0, 0, 0.4);
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-property: width, height;
  transition-property: width, height;
}
.hvr-curl-top-right:hover:before, .hvr-curl-top-right:focus:before, .hvr-curl-top-right:active:before {
  width: 25px;
  height: 25px;
}

/* Curl Bottom Right */
.hvr-curl-bottom-right {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  position: relative;
}
.hvr-curl-bottom-right:before {
  pointer-events: none;
  position: absolute;
  content: '';
  height: 0;
  width: 0;
  bottom: 0;
  right: 0;
  background: white;
  /* IE9 */
  background: linear-gradient(315deg, white 45%, #aaa 50%, #ccc 56%, white 80%);
  box-shadow: -1px -1px 1px rgba(0, 0, 0, 0.4);
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-property: width, height;
  transition-property: width, height;
}
.hvr-curl-bottom-right:hover:before, .hvr-curl-bottom-right:focus:before, .hvr-curl-bottom-right:active:before {
  width: 25px;
  height: 25px;
}

/* Curl Bottom Left */
.hvr-curl-bottom-left {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  position: relative;
}
.hvr-curl-bottom-left:before {
  pointer-events: none;
  position: absolute;
  content: '';
  height: 0;
  width: 0;
  bottom: 0;
  left: 0;
  background: white;
  /* IE9 */
  background: linear-gradient(45deg, white 45%, #aaa 50%, #ccc 56%, white 80%);
  box-shadow: 1px -1px 1px rgba(0, 0, 0, 0.4);
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-property: width, height;
  transition-property: width, height;
}
.hvr-curl-bottom-left:hover:before, .hvr-curl-bottom-left:focus:before, .hvr-curl-bottom-left:active:before {
  width: 25px;
  height: 25px;
}

/*# sourceMappingURL=hover.css.map */

